<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-delivery'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете тази доставка от системата</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="deleteDelivery(modal.obj.delivery_id)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'session-timeout'">
        <span class="alert-text">Вашията сесия е изтекла, моля влезте отново</span>
        <div class="btn-holder">
            <div class="btn positive" style="width:100%;">Приемам</div>
        </div>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'loading-deliveries'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Зареждане на доставки...</span>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'loading-data'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Зареждане на данни от файл...</span>
    </div>

    <div class="modal-lg" *ngIf="modal.cntx == 'importing-delivery'">
        <div style="width:100%;height:100%;float:left;" *ngIf="modal.obj.step == 1">
            <div class="modal-ttl">Импорт на доставка - Стъпка 1 / 2</div>
            <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
                <span class="label">Наименование <span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
                <input type="text" placeholder="Въведете наименование на доставка" [(ngModel)]="modal.obj.name" />
            </div>
            <div class="col-xl-4 col-lg-4 input-row" style="float:left;">
                <div id="delivery-date-picker" class="date-picker bot"  (clickOutside)="closePicker($event)" [ngClass]="{ 'active':modal.obj.delivery_date_picker }">
                    <div class="datepicker-header">
                        <div class="datepicker-left-tool hoverable" (click)="prevMonth()"><i class='icon arrow-left'></i></div>
                        <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(modal.obj.current_delivery_date)) }} {{ returnYear(modal.obj.current_delivery_date) }}</span>
                        <div class="datepicker-right-tool hoverable" (click)="nextMonth()"><i class='icon arrow-right'></i></div>
                    </div>
                    <div class="datepicker-content">
                        <div class="datepicker-title-row">
                            <span>Пн</span>
                            <span>Вт</span>
                            <span>Ср</span>
                            <span>Чт</span>
                            <span>Пт</span>
                            <span>Сб</span>
                            <span>Нд</span>
                        </div>
                        <div class="datepicker-row" id="date_delivery_body">
                            <span *ngFor="let dt of modal.obj.current_delivery_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(modal.obj.current_delivery_date) && dt.month == returnMonth(modal.obj.date_add) - 1 }" (click)="selectDate(dt)">{{ dt.date }}</span>
                        </div>
                    </div>
                </div>
                <span class="label">Дата на доставка</span>
                <input type="text" placeholder="Въведете дата на доствака" readonly value="{{ this.returnDateFormated(modal.obj.date_add) }}" />
                <div class="after-action" (click)="openPicker()" style="top: 50px;right: 35px;"><i class="icon calendar"></i></div>
            </div>
            <div class="col-xl-4 col-lg-4 input-row" style="float:left;">
                <span class="label">Номер на контейнер</span>
                <input type="text" placeholder="Въведете номер на контейнер" [(ngModel)]="modal.obj.container_number" />
            </div>
            <div class="col-xl-4 col-lg-4 input-row" style="float:left;">
                <span class="label">Номер на документ</span>
                <input type="text" placeholder="Въведете номер на контейнер" [(ngModel)]="modal.obj.document_number" />
            </div>
            <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
                <span class="label">Коментар</span>
                <textarea  [(ngModel)]="modal.obj.comment" style="margin-left: 20px;width: calc(100% - 48px); height: 105px; resize: none;"></textarea>
            </div>
            <div class="btn-line" style="position:absolute;bottom:20px;">
                <div class="btn back" (click)="closeModal()">Отказ</div>
                <div class="btn positive" [ngClass]="{ 'disabled':modal.obj.name.length == 0 }" (click)="modal.obj.step = 2">Напред</div>
            </div>
        </div>
        <div style="width:100%;height:100%;float:left;" *ngIf="modal.obj.step == 2">
            <div class="modal-ttl">Импорт на доставка - Стъпка 2 / 2</div>
            <table cellpadding="0" cellspacing="0" class="import-table">
                <thead>
                    <tr>
                        <th width="5%">#</th>
                        <th width="15%">Марка</th>
                        <th width="20%">Вътрешно тяло - Модел</th>
                        <th width="20%">Външно тяло - Модел</th>
                        <th width="20%">Вътрешно тяло - Сер.номер</th>
                        <th width="20%">Външно тяло - Сер.номер</th>
                    </tr>
                </thead>
            </table>
            <div class="scrollable">
                <table cellpadding="0" cellspacing="0" class="import-table">
                    <tbody>
                        <tr [ngClass]="{ 'hidden':row['Марка'] === undefined }" *ngFor="let row of modal.obj.data">
                            <td width="5%">{{ row['Брой'] }}</td>
                            <td width="15%">{{ row['Марка'] }}</td>
                            <td width="20%">{{ row['Модел Вътрешно'] }}</td>
                            <td width="20%">{{ row['Модел Външно'] }}</td>
                            <td width="20%">{{ row['Сер ном вътрешно'] }}</td>
                            <td width="20%">{{ row['Сер ном външно'] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="btn-line" style="position:absolute;bottom:20px;">
                <div class="btn back" (click)="modal.obj.step = 1">Назад</div>
                <div class="btn positive" (click)="addImportedDelivery()">Импортиране</div>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <span class="page-title">
        <span class="name">Доставки</span>
        <div class="search-title-box" *ngIf="!searched">
            <select [(ngModel)]="search_by">
                <option value="timebrand">Период/Марка</option>
                <option value="serial">Сериен номер</option>
            </select> 
            <select [(ngModel)]="timeline" (change)="switchedFilter()" *ngIf="search_by == 'timebrand'">
                <option value="0">От началото</option>
                <option value="3">Последните 3 месеца</option>
                <option value="6">Последните 6 месеца</option>
                <option value="12">Последната 1 година</option>
            </select>
            <select [(ngModel)]="brand" (change)="switchedFilter()" *ngIf="search_by == 'timebrand'">
                <option value="all">Всички марки</option>
                <option *ngFor="let brand of availableBrands" value="{{ brand.name }}" [value]="brand.name">{{ brand.name }}</option>
            </select> 
            <input type="text" [(ngModel)]="serial" style="height:30px;width:240px;" *ngIf="search_by == 'serial'" />
            <div (click)="switchedFilter()" class="round-btn" *ngIf="search_by == 'serial'"><i class="icon search-white"></i></div>
        </div> 

        <div class="label-searched" (click)="clearSearched()" *ngIf="searched">
            <strong class="type" *ngIf="search_by == 'serial'">Сериен номер:</strong>
            <span>{{ serial }}</span>
            <i class="icon delete-label"></i>
        </div>
        <!-- <select [(ngModel)]="timeline" (change)="switchedFilter()">
            <option value="0">От началото</option>
            <option value="3">Последните 3 месеца</option>
            <option value="6">Последните 6 месеца</option>
            <option value="12">Последната 1 година</option>
        </select> 
        <select [(ngModel)]="brand" (change)="switchedFilter()">
            <option value="all">Всички марки</option>
            <option *ngFor="let brand of availableBrands" value="{{ brand.name }}" [value]="brand.name">{{ brand.name }}</option>
        </select>  -->

        <input type="file" id="document-upload" style="display:none;" accept=".xlsx" (change)="onFileChange($event)" />
        <span routerLink="/add-delivery" class="right-btn" *ngIf="system.returnRole() == 1 || system.returnIsAdmin()">Нова доставка</span>
        <span (click)="openFileManagement($event)" style="margin-right:10px;" class="right-btn import" *ngIf="system.returnRole() == 1 || system.returnIsAdmin()">Импорт на доставка</span>
        <a class="right-btn" style="text-decoration:none;margin-right:10px;" href="public_documents/template-delivery.xlsx" download="{{ system.returnPublicDocumentName() }}" *ngIf="system.returnRole() == 1 || system.returnIsAdmin()">Шаблон за доставка</a>
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-2 col-lg-2 text-center">Номер на контейнер</div>
            <div class="col-xl-3 col-lg-3 text-center" *ngIf="!system.returnIsAdmin()">Наименование</div>
            <div class="col-xl-2 col-lg-2 text-center" *ngIf="system.returnIsAdmin()">Наименование</div>
            <div class="col-xl-2 col-lg-2 text-center">Дата на доставка</div>
            <div class="col-xl-2 col-lg-2 text-center" *ngIf="!system.returnIsAdmin()">Брой артикули</div>
            <div class="col-xl-1 col-lg-1 text-center" *ngIf="system.returnIsAdmin()">Брой артикули</div>
            <div class="col-xl-2 col-lg-2 text-center" *ngIf="system.returnIsAdmin()">Склад</div>
            <div class="col-xl-2 col-lg-2 text-center">Коментар</div>
            <div class="col-xl-1 col-lg-1 text-center">Действия</div>
        </div>
        <div  class="table-wrapper-row" *ngIf="deliveries.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма доставки с избраните параметри</div>
        </div>
        <div class="table-wrapper-row selectable" *ngFor="let delivery of deliveries; let i = index">
            <div class="col-xl-2 col-lg-2 text-center"><div [ngClass]="{ 'expanded':delivery.expanded }" class="extendable" id="extend-btn1" (click)="switchDeliveryExpanded(delivery)"><i class="icon arrow-down"></i></div>{{ delivery.container_id }}</div>
            <div class="col-xl-3 col-lg-3 text-center" *ngIf="!system.returnIsAdmin()">{{ delivery.name }}</div>
            <div class="col-xl-2 col-lg-2 text-center" *ngIf="system.returnIsAdmin()">{{ delivery.name }}</div>
            <div class="col-xl-2 col-lg-2 text-center">{{ delivery.delivered_at }}</div>
            <div class="col-xl-2 col-lg-2 text-center" *ngIf="!system.returnIsAdmin()">{{ delivery.product_count }}</div>
            <div class="col-xl-1 col-lg-1 text-center" *ngIf="system.returnIsAdmin()">{{ delivery.product_count }}</div>
            <div class="col-xl-2 col-lg-2 text-center" *ngIf="system.returnIsAdmin()">{{ delivery.storehouse_name }}</div>
            <div class="col-xl-2 col-lg-2 text-center text-overflow">{{ delivery.comment }}</div>    
            <div class="col-xl-1 col-lg-1 text-center action-no-wrap">
                <div class="action-btn" *ngIf="system.returnRole() != 3 || system.returnIsAdmin()" routerLink="/edit-delivery/{{ delivery.uid }}"><i class="icon edit"></i> <span class="tooltip">Промяна</span></div>
                <div class="action-btn negative" (click)="promptDelete(delivery.uid)" *ngIf="system.returnIsAdmin()"><i class="icon remove-white"></i> <span class="tooltip">Изтриване</span></div>
            </div>              
            <div class="expandable" [ngClass]="{ 'expanded':delivery.expanded }">
                <div class="table-wrapper-row table-wrapper-small-header">
                    <div class="col-xl-1 col-lg-1 text-center" style="color: #fbfbfb;">-</div>
                    <div class="col-xl-1 col-lg-1 text-center">Марка</div>
                    <div class="col-xl-2 col-lg-1 text-center">Модел (Вътрешно тяло)</div>
                    <div class="col-xl-2 col-lg-1 text-center">Модел (Външно тяло)</div>
                    <div class="col-xl-3 col-lg-1 text-center">Сериен №(Вътрешно тяло)</div>
                    <div class="col-xl-3 col-lg-1 text-center">Сериен №(Външно тяло)</div>
                </div>
                <div class="table-wrapper-row smaller selectable" *ngFor="let item of delivery.items" >
                    <div class="col-xl-1 col-lg-1 text-center line-elem"><div class="elem-holder"></div></div>
                    <div class="col-xl-1 col-lg-1 text-center">{{ item.brand }}</div>
                    <div class="col-xl-2 col-lg-1 text-center">{{ item.model_internal }}</div>
                    <div class="col-xl-2 col-lg-1 text-center">{{ item.model_external }}</div>
                    <div class="col-xl-3 col-lg-1 text-center">{{ item.serial_internal }}</div>
                    <div class="col-xl-3 col-lg-1 text-center">{{ item.serial_external }}</div>
                </div>
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>

            <!-- <span (click)="switchPage(i + 1)" *ngFor="let page of total_pages_array;let i= index" [ngClass]="{ 'active':i + 1 == currentPage }">{{ i + 1 }}</span> -->
        </div>
    </div>
</div>