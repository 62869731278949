<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'exporting-sales'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Експортиране на данни...</span>
    </div>
</div>

<div class="content">
    <span class="page-title">
        <span class="name">Доставки</span>
        <!-- <select [(ngModel)]="timeline" (change)="switchedFilter()">
            <option value="0">От началото</option>
            <option value="3">Последните 3 месеца</option>
            <option value="6">Последните 6 месеца</option>
            <option value="12">Последната 1 година</option>
        </select>  -->
        <!-- <select [(ngModel)]="brand" (change)="switchedFilter()">
            <option value="all">Всички марки</option>
            <option *ngFor="let brand of availableBrands" value="{{ brand.name }}" [value]="brand.name">{{ brand.name }}</option>
        </select>  -->
        <!-- <div class="label-searched" (click)="clearSearched()" *ngIf="searched">
            <strong class="type" *ngIf="search_type == 'client_name'">Име на клиент:</strong>
            <strong class="type" *ngIf="search_type == 'seller_name'">Име на клиент:</strong>
            <strong class="type" *ngIf="search_type == 'order_number'">Номер на поръчка:</strong>
            <strong class="type" *ngIf="search_type == 'serial_number'">Сериен номер:</strong>
            <strong class="type" *ngIf="search_type == 'model'">Модел:</strong>
            <span>{{ search }}</span>
            <i class="icon delete-label"></i>
        </div>
        <div class="search-title-box" *ngIf="!searched">
            <select [(ngModel)]="search_type">
                <option value="client_name">Име на клиент</option>
                <option value="seller_name" *ngIf="system.returnRole() == 1 || system.returnRole() == 3">Име на продавач</option>
                <option value="order_number">Номер на поръчка</option>
                <option value="serial_number">Сериен номер</option>
                <option value="model">Модел</option>
            </select>
            <input type="text" (keydown.enter)="searchSales()" style="height:30px;width:240px;" [(ngModel)]="search" />
            <div class="round-btn" (click)="searchSales()"><i class="icon search-white"></i></div>
        </div> 
        <span class="info-window" *ngIf="search_type == 'model'">
            <i class="icon warning"></i>
            <span>Търсенето по "модел" с частично въведен модел, може да отнеме драстично повече време</span>
        </span> -->
        <!-- <span class="right-btn import" style="margin-left:10px;float:left;">Импорт на продажби</span> -->
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-1 col-lg-1 text-center">#</div>
            <div class="col-xl-2 col-lg-2 text-center text-overflow" [ngClass]="{ 'col-xl-2':system.returnName() == 'Склад Пловдив', 'col-xl-1':system.returnName() != 'Склад Пловдив' }">Дата на продажба</div>
            <div class="col-xl-2 col-lg-2 text-center">Номер на поръчка</div>
            <div class="col-xl-2 col-lg-2 text-center">Продадено от</div>
            <div class="col-xl-3 col-lg-3 text-center">Продадено на</div>
            <div class="col-xl-1 col-lg-1 text-center">Aртикули</div>
            <div class="col-xl-1 col-lg-1 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row" *ngIf="sales.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма добавени продажби</div>
        </div>
        <div class="table-wrapper-row selectable" *ngFor="let sale of sales; let i = index">
            <div class="col-xl-1 col-lg-1 text-center"><div (click)="switchSaleExpanded(sale)" [ngClass]="{ 'expanded':sale.expanded }" class="extendable"><i class="icon arrow-down"></i></div>{{ i + 1 }}</div>
            <div class="col-xl-2 col-lg-2 text-center text-overflow" [ngClass]="{ 'col-xl-2':system.returnName() == 'Склад Пловдив', 'col-xl-1':system.returnName() != 'Склад Пловдив' }">{{ sale.date_of_sale }}</div>
            <div class="col-xl-2 col-lg-2 text-center">{{ sale.order_number }}</div>
            <div class="col-xl-2 col-lg-1 text-center">{{ sale.coming_from }}</div>
            <div class="col-xl-3 col-lg-1 text-center">{{ sale.going_to }} 
                <!-- <span class="sale-hint transfer" *ngIf="sale.sold_to_type == '1'">Прехвърляне</span>
                <span class="sale-hint" *ngIf="sale.sold_to_type != '1'">Продажба</span> -->
            </div>
            <div class="col-xl-1 col-lg-1 text-center">{{ sale.product_count }} <i class="icon warning unfinished" *ngIf="sale.product_count == 0" title="Незавършена продажба"></i></div>       
            <div class="col-xl-1 col-lg-1 text-center action-no-wrap">
                <div class="action-btn" (click)="exportSale(sale)"><i class="icon sheet"></i><span class="tooltip">Експорт</span></div>
            </div>    
            <div class="expandable" [ngClass]="{ 'expanded':sale.expanded }">
                <div class="table-wrapper-row table-wrapper-small-header ">
                    <div class="col-xl-1 col-lg-1 text-center" style="color: #fbfbfb;">-</div>
                    <!-- <div class="col-xl-3 col-lg-1 text-center">Марка</div> -->
                    <div class="col-xl-3 col-lg-3 text-center">Модел (Вътрешно тяло)</div>
                    <div class="col-xl-3 col-lg-3 text-center">Модел (Външно тяло)</div>
                    <div class="col-xl-3 col-lg-3 text-center">Сериен №(Вътрешно тяло)</div>
                    <div class="col-xl-2 col-lg-2 text-center">Сериен №(Външно тяло)</div>
                </div>
                <div class="table-wrapper-row smaller selectable" *ngFor="let item of sale.items" [ngClass]="{ 'highlight-found':searched && search_type == 'serial_number' && item.serial_internal == search || searched && search_type == 'serial_number' && item.serial_external == search }">
                    <div class="col-xl-1 col-lg-1 text-center line-elem"><div class="elem-holder"></div></div>
                    <!-- <div class="col-xl-3 col-lg-1 text-center">{{ item.brand }}</div> -->
                    <div class="col-xl-3 col-lg-3 text-center">{{ item.model_internal }}</div>
                    <div class="col-xl-3 col-lg-3 text-center">{{ item.model_external }}</div>
                    <div class="col-xl-3 col-lg-3 text-center">{{ item.serial_internal }}
                        <div class="small-action-btn" style="left:0px;" *ngIf="system.returnRole() == 1 && item.serial_internal != '' || system.returnIsAdmin() && item.serial_internal != ''" (click)="openModal('return-product', { 'serial':item.serial_internal, 'sale_id':sale.uid, 'name':sale.going_to, 'uid':item.uid_internal })"><i class="icon return"></i></div>
                    </div>
                    <div class="col-xl-2 col-lg-2 text-center">{{ item.serial_external }}
                        <div class="small-action-btn" *ngIf="system.returnRole() == 1 && item.serial_external != '' || system.returnIsAdmin() && item.serial_external != ''" (click)="openModal('return-product', { 'serial':item.serial_external, 'sale_id':sale.uid, 'name':sale.going_to, 'uid':item.uid_external })"><i class="icon return"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 2" (click)="switchPage(1)">1</span>
            <span class="non-hover" *ngIf="currentPage > 3">...</span>
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>

            <!-- <span (click)="switchPage(i + 1)" *ngFor="let page of total_pages_array;let i= index" [ngClass]="{ 'active':i + 1 == currentPage }">{{ i + 1 }}</span> -->
        </div>
    </div>
</div>