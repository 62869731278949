<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-document'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете този документ от системата</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="deleteDocument(modal.obj.document_id)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>
</div>

<div class="content">
    <span class="page-title">
        <span class="name" [ngClass]="{ 'underlink':chosenBrand != '' }" (click)="clearBrand()">Документация</span>
        <span class="name-splitter" *ngIf="chosenBrand != ''">-></span>
        <span class="subname" *ngIf="chosenBrand != ''">{{ chosenBrand }}</span>
        <span routerLink="/add-document" class="right-btn" *ngIf="localSystem.returnIsAdmin()">Нов документ</span>
    </span>
    <div class="hint-card" *ngIf="chosenBrand == ''">
        Ако не намирате търсеният от Вас файл/информация, моля свържете се с нашия сервиз: ‎‎0888 457 688 или <a href="mailto:aircon.service@tempexbg.com">aircon.service@tempexbg.com</a>
    </div>
    <div *ngIf="chosenBrand == ''">
        <div class="selectable-card" *ngFor="let brand of brands" (click)="selectBrand(brand.name, brand.uid)">{{ brand.name }}</div>
    </div>


    <div class="table-wrapper" *ngIf="chosenBrand != ''">
        <div class="table-wrapper-header-row">
            <div class="col-xl-6 col-lg-6 text-center" *ngIf="localSystem.returnIsAdmin()">Наименование</div>
            <div class="col-xl-7 col-lg-7 text-center" *ngIf="!localSystem.returnIsAdmin()">Наименование</div>
            <div class="col-xl-3 col-lg-3 text-center">Марка</div>
            <div class="col-xl-2 col-lg-2 text-center">Размер на файл</div>
            <div class="col-xl-1 col-lg-1 text-center" *ngIf="localSystem.returnIsAdmin()">Действия</div>
        </div>
        <div class="table-wrapper-row selectable" *ngIf="documents.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма резултати за тази марка</div>          
        </div>
        <div class="table-wrapper-row selectable" *ngFor="let document of documents; let i = index">
            <div class="col-xl-6 col-lg-6" *ngIf="localSystem.returnIsAdmin()"><span (click)="openLink(document)" class="download-btn"> <i class="icon download"></i> Сваляне</span> {{ document.name }}</div>
            <div class="col-xl-7 col-lg-7" *ngIf="!localSystem.returnIsAdmin()"><span (click)="openLink(document)" class="download-btn"> <i class="icon download"></i> Сваляне</span> {{ document.name }}</div>
            <div class="col-xl-3 col-lg-3 text-center">{{ chosenBrand }}</div>
            <div class="col-xl-2 col-lg-2 text-center">{{ returnRoundSize(document.size) }}KB</div>
            <div class="col-xl-1 col-lg-1 text-center" *ngIf="localSystem.returnIsAdmin()">
                <div class="action-btn" routerLink="/edit-document/{{ document.uid }}"><i class="icon edit"></i></div>
                <div class="action-btn negative" (click)="promptDelete(document.uid)"><i class="icon remove-white"></i></div>
                <!-- <div class="action-btn negative" (click)="remove"><i class="icon remove-white"></i></div> -->
            </div>              
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>
        </div>
    </div>
</div>