<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-brand'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете тази марка от системата</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="deleteBrand(modal.obj.uid)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>
</div>
<div class="content">
    <span class="page-title">
        <span class="name">Марки</span>
        <span routerLink="/add-brand" class="right-btn">Нова марка</span>
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-1 col-lg-1 text-center">#</div>
            <div class="col-xl-5 col-lg-5 text-center">Наименование</div>
            <div class="col-xl-6 col-lg-6 text-center">Гаранционен срок</div>
        </div>
        <div class="table-wrapper-row" *ngFor="let brand of brands; let i = index">
            <div class="col-xl-1 col-lg-1 text-center">{{ i + 1 }}</div>
            <div class="col-xl-5 col-lg-5 text-center">{{ brand.name }}</div>
            <div class="col-xl-5 col-lg-5 text-center">{{ brand.warranty_period }} месеца</div>
            <div class="col-xl-1 col-lg-1 text-center">
                <div class="action-btn" routerLink="/edit-brand/{{ brand.uid }}"><i class="icon edit"></i> <span class="tooltip">Промяна</span></div>
                <div class="action-btn negative" (click)="openModal('remove-brand', brand)"><i class="icon remove-white"></i> <span class="tooltip">Изтриване</span></div>
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>

            <!-- <span (click)="switchPage(i + 1)" *ngFor="let page of total_pages_array;let i= index" [ngClass]="{ 'active':i + 1 == currentPage }">{{ i + 1 }}</span> -->
        </div>
    </div>
</div>