import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  user_id:any;
  editUser:any = { username:"", email:"", type:"", password:"" };

  @HostListener('window:beforeunload')
  onBeforeUnload() {
      return confirm("Сигурни ли сте, че искате да излезете в процес на добавяне ?");
  }

  constructor(private http:HttpClient,private router:Router, private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.user_id = this.activatedRoute.snapshot.params.uid;
    this.getUser();
  }


  getUser() {
    this.http.get("api/service.php?action=get_accounts&uid=" + this.user_id).subscribe((data) => {
      this.editUser.username = data['accounts'][0]['username'];
      this.editUser.email = data['accounts'][0]['email'];
      this.editUser.type = data['accounts'][0]['role'];
      if(data['accounts'][0]['admin'] == "1") { this.editUser.type = "3"; }
    });
  }

  EditAccount(){
    this.http.get("api/service.php?action=edit_account&username=" + this.editUser.username + "&email=" + this.editUser.email + "&role="+  this.editUser.type + "&password=" + this.editUser.password + "&uid=" + this.user_id).subscribe((data) => {
      this.router.navigate(['users']);
    });
  }

  generateRandomPassword(size){
    this.editUser.password = "";
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (var i=0;i<size;i++) {
      var c = Math.floor(Math.random()*chars.length + 1);
      this.editUser.password += chars.charAt(c)
    }
  }


  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  // EditBrand() {
  //   this.http.get("api/service.php?action=edit_brand&name=" + this.editBrand.name + "&duration=" + this.editBrand.duration + "&uid=" + this.brand_id).subscribe((data) => {
  //     this.router.navigate(['brands']);
  //   });
  // }
}
