import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalSystemService } from '../local-system.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {
  documents:any = [ ]
  chosenBrand:any = "";
  chosenBrandId = 0;
  brands:any = [];

  currentPage:any = 1;
  total_pages = 0;
  total_pages_array = [];

  modal:any = { open:false, cntx:"", obj:{} }

  constructor(private http:HttpClient, public localSystem:LocalSystemService) { }

  ngOnInit(): void {
    this.getBrands();
  }

  getBrands() {
    this.http.get("api/service.php?action=get_documentation_brands").subscribe((data) => {
      if(data['logged']) {
        this.brands = data['brands'];  
      } else {
        this.localSystem.Logout();
      }
    });
  }

  selectBrand(brand, id) {
    this.chosenBrand = brand;
    this.chosenBrandId = id;
    this.getDocuments();
  }



  getDocuments() {
    this.http.get("api/service.php?action=get_documents&brand_id=" + this.chosenBrandId + "&page=" + this.currentPage).subscribe((data) => {
      this.documents = data['documents'];
      this.total_pages = data['total_pages'];
    });
  }

  switchPage(page) {
    this.currentPage = page;
    this.getDocuments();
  }

  clearBrand() { this.chosenBrand = ""; this.chosenBrandId = 0; }

  openLink(file) {
    window.open('https://warranty.tempex.bg/documentation/' + file.link, '_blank').focus();
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj}; } 
  closeModal() { this.modal = { open:false, cntx:"", obj:{} }; }


  promptDelete(document_id) { this.openModal('remove-document', { 'document_id':document_id }); }
  deleteDocument(document_id) {
    this.http.get("api/service.php?action=remove_document&document_id=" + document_id).subscribe((data) => {
      this.closeModal();
      this.getDocuments();
    });
  }

  returnRoundSize(size){
    return Math.ceil(size / 1024);
  }

}
