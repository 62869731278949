import { Component, OnInit, NgZone } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient } from '@angular/common/http';
import { LocalSystemService } from '../local-system.service';
 

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  helps:any = [];

  adding:any = false;
  addHelp:any = { ttl:"", layout:"", expanded:false, seen_storehouse:0, seen_dealer:0 };

  editing:any = false;
  editHelp:any = { uid:-999 };

   
editorConfig: AngularEditorConfig = {
  editable: true,
    spellcheck: true,
    height: '100px',
    placeholder: 'Enter text here...',
};

  constructor(private http:HttpClient, private ngZone:NgZone, public system:LocalSystemService) { }

  ngOnInit(): void {
    this.getHelp();
  }

  AddHelp() {
    let postObject = { "layout":this.encodeHTMLEntities(this.addHelp.layout), title:this.addHelp.ttl };
    this.http.post("api/service.php?action=add_help&seen_dealer=" + this.addHelp.seen_dealer + "&seen_storehouse=" + this.addHelp.seen_storehouse, postObject).subscribe((data) => {
      this.addHelp = { ttl:"", layout:"", expanded:false, seen_storehouse:0, seen_dealer:0 };
      this.adding = false;
      this.getHelp();
    });
  }

  getHelp() {
    this.http.get("api/service.php?action=get_help").subscribe((data) => {
      this.helps = data['help'];
      for(let i = 0; i < this.helps.length; i++) {
        this.helps[i].layout = this.decodeHTMLEntities(this.helps[i].layout);
      }
      this.ngZone.run(()=>{});
    });
  }

  loadEditHelp(help) {
    this.editHelp = JSON.parse(JSON.stringify(help));
    this.editing = true;
  }

  RemoveHelp(uid) {
    var r = confirm("Сигурни ли сте, че искате да премахнете този елемент ?");
    if (r == true) {
      this.http.get("api/service.php?action=remove_help&uid=" + uid).subscribe((data) => {
        this.getHelp();
        this.ngZone.run(()=>{});
      });
    }
  }

  EditHelp() {
    let postObject = { "layout":this.encodeHTMLEntities(this.editHelp.layout), title:this.editHelp.ttl, seen_dealer:this.editHelp.seen_dealer, seen_storehouse:this.editHelp.seen_storehouse };
    this.http.post("api/service.php?action=edit_help&uid=" + this.editHelp.uid + "&seen_dealer=" + this.editHelp.seen_dealer + "&seen_storehouse=" + this.editHelp.seen_storehouse, postObject).subscribe((data) => {
      this.editing = false;
      this.editHelp = { uid:-999 };
      this.getHelp();
    });
  }

  cancelAddHelp() {
    this.adding = false;
    this.addHelp = { ttl:"", layout:"", expanded:false };
  }

  cancelEditHelp() {
    this.editing = false;
    this.editHelp = { uid:-999 };
  }

  switchSeenDealer(help) {
    if(help.seen_dealer == 1) { help.seen_dealer = 0; }
    else { help.seen_dealer = 1; }
  }

  switchSeenStorehouse(help) {
    if(help.seen_storehouse == 1) { help.seen_storehouse = 0; }
    else { help.seen_storehouse = 1; }
  }

  decodeHTMLEntities(text) {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (var i = 0, max = entities.length; i < max; ++i) 
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return text;
  }

  encodeHTMLEntities(text) {
    var entities = [
        ['&','amp'],
        ['\'','apos'],
        ['\'','#x27'],
        ['/','#x2F'],
        ['\'','#39'],
        ['/','#47'],
        ['<','lt'],
        ['>','gt'],
        [' ','nbsp'],
        ['"','quot']
    ];

    for (var i = 0, max = entities.length; i < max; ++i) 
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return text;
  }

}
