import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalSystemService } from '../local-system.service';

declare var $;
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  accounts:any = [];
  currentPage:any = 1;
  total_pages = 0;
  total_pages_array = [];


  search_type:any = "email";
  search:any = "";
  searched:any = false;

  availableDealers:any = [];
  availableStorehouses:any = [];
  refreshValueInterval:any;

  modal:any = {};

  constructor(private system:LocalSystemService, private router:Router, private http:HttpClient) {
    if(!this.system.returnIsAdmin()) { this.router.navigate(['/warranties']); }
  }

  ngOnInit(): void {
    this.getAccounts();
    this.getAvailableStorehouses();
    this.getAvailableDealers();
  }

  getAccounts() {
    let url = "api/service.php?action=get_accounts&page=" + this.currentPage;
    if(this.searched) { url += "&search_type=" + this.search_type + "&search=" + this.search }
    this.http.get(url).subscribe((data) => {
      if(data['logged']) {
        this.accounts = data['accounts'];
        this.total_pages = data['total_pages'];
        this.total_pages_array = [];
        for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
      } else {
        this.system.Logout();
      }
    });
  }

  getAvailableStorehouses() {
    this.http.get("api/service.php?action=get_available_storehouses").subscribe((data) => {
      this.availableStorehouses = data['storehouses'];
    });
  }

  getAvailableDealers() {
    this.http.get("api/service.php?action=get_available_dealers").subscribe((data) => {
      this.availableDealers = data['dealers'];
    });
  }

  searchAccounts() {
    this.currentPage = 1;
    this.searched = true;
    this.getAccounts();
  }

  clearSearched() {
    this.currentPage = 1;
    this.search = "";
    this.searched = false;
    this.getAccounts();
  }

  changedSearchType() {
    if(this.search_type == 'username') { this.search = ''; }
    else if(this.search_type == 'role') { this.search = '1'; }
  }

  switchPage(page) {
    this.currentPage = page;
    this.getAccounts();
  }

  switchAccountType() {
    if(this.modal.obj.role != '1') {
      this.modal.obj.storehouse_id = '-1';
      setTimeout(()=>{
        $('#dealer_id').select2();
      }, 250)
    } else if(this.modal.obj.role != '2') {
      this.modal.obj.dealer_id = '-1';
      setTimeout(()=>{
        $('#storehouse_id').select2();
      }, 250)
    }
  }

  openModal(cntx, obj) { 
    clearInterval(this.refreshValueInterval);
    this.refreshValueInterval = setInterval(()=>{
      if($("#storehouse_id") != undefined && this.modal.obj.role == '1') { this.modal.obj.storehouse_id = $("#storehouse_id").val();console.log(this.modal.obj.storehouse_id); }
      if($("#dealer_id") != undefined && this.modal.obj.role == '2') { this.modal.obj.dealer_id = $("#dealer_id").val(); console.log(this.modal.obj.dealer_id); }
    }, 200);
    if(cntx == 'edit-user') {
      this.modal = { open:true, 'cntx':cntx, 'obj':JSON.parse(JSON.stringify(obj)) };
      this.modal.obj.original_password = this.modal.obj.password;
      if(this.modal.obj.admin == '1') { this.modal.obj.admin = true; } else { this.modal.obj.admin = false; } 
    } else if(cntx == 'add-user') {
      this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
      setTimeout(()=>{
        $('#storehouse_id').select2();
      }, 250)
    } else {
      this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
    }
  }
  closeModal() { this.modal.open = false; }

  promptDelete(uid) { this.openModal('remove-account', { 'uid':uid }); }
  deleteUser(uid,) {
    let params = { 'uid':uid };
    this.openModal('removing-user', {});
    this.http.post("api/service.php?action=remove_account", params).subscribe((data) => {
      this.getAccounts();
      setTimeout(()=>{
        this.closeModal();
      }, 500);
    });
  }

  AddUser() {
    this.http.post("api/service.php?action=check_email", { 'email':this.modal.obj.email }).subscribe((data_email) => {
      if(data_email['allowed']) {
        let url = "api/service.php?action=add_account";
        let params = { "username":this.modal.obj.username, "password":this.modal.obj.password, "email":this.modal.obj.email, "type":this.modal.obj.role,
        "dealer_id":this.modal.obj.dealer_id, "storehouse_id":this.modal.obj.storehouse_id, "admin":this.modal.obj.admin }
        this.openModal('adding-user', {});
        this.http.post(url, params).subscribe((data) => {
          this.getAccounts();
          setTimeout(()=>{
            this.closeModal();
          }, 500);
        });
      } else {
        alert("Вече има потребител с този е-майл");
      }
    });

  }

 
  EditUser(){
    let params = { "username":this.modal.obj.username, "uid":this.modal.obj.uid, "email":this.modal.obj.email, "role":this.modal.obj.role, "storehouse_id":this.modal.obj.storehouse_id, "dealer_id":this.modal.obj.dealer_id, "password":this.modal.obj.password, "original_password":this.modal.obj.original_password, "admin":this.modal.obj.admin }
    this.openModal('editing-user', {});
    this.http.post("api/service.php?action=edit_account", params).subscribe((data) => {
      this.getAccounts();
      setTimeout(()=>{
        this.closeModal();
      }, 500);
    });
  }

  generateRandomPassword(size){
    this.modal.obj.password = "";
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (var i=0;i<size;i++) {
      var c = Math.floor(Math.random()*chars.length + 1);
      this.modal.obj.password += chars.charAt(c)
    }
  }

}
