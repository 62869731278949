
<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'loading'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Зареждане на доставка...</span>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'editting-delivery'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Променяне на доставка...</span>
    </div>

    <div class="warning-alert" *ngIf="modal.cntx == 'duplicate-serial'">
        <div class="illustration duplicate-warning"></div>
        <span class="warning-alert-ttl">Дупликиран артикул</span>
        <span class="warning-alert-description">
            Сериения номер "<strong>{{modal.obj.serial}}</strong>", който се опитвате да въведете вече съществува в тази доставка
        </span>
        <div class="btn-confirm" (click)="closeModal()">Напред</div>
    </div>
</div>

<div class="content no-subheader">
    <span class="page-title">Промяна доставка 
        <span *ngIf="loaded" (click)="EditDelivery(1)" [ngClass]="{ 'disabled':editDelivery.name.length == 0 }" class="right-btn">Запис и изход</span>
        <span class="right-btn" (click)="EditDelivery(2)"  style="margin-right:10px;" >Запис</span>
        <span routerLink="/deliveries" class="right-btn negative">Отказ</span>
    </span>
    <div class="tab-holder" *ngIf="loaded">
        <div class="tab-headers">
            <div class="tab-header" (click)="switchTab('general')" [ngClass]="{ 'active':tab == 'general' }">Основна информация</div>
            <div class="tab-header" (click)="switchTab('products')"  [ngClass]="{ 'active':tab == 'products' }">Артикули <span class="count-badge">{{ returnTotalProducts() }}</span></div>
        </div>
        <div class="tab-content">
            <div class="tab" *ngIf="tab == 'general'">
                <div class="category-title">Основна информация</div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Наименование <span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="editDelivery.name" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Номер на контейнер</span>
                    <input type="text" placeholder="Въведете номер"  [(ngModel)]="editDelivery.container_id" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Номер на документ</span>
                    <input type="text" placeholder="Въведете номер"  [(ngModel)]="editDelivery.document_number" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <div id="delivery-date-picker" class="date-picker bot"  (clickOutside)="closePicker($event)" [ngClass]="{ 'active':delivery_date_picker }">
                        <div class="datepicker-header">
                            <div class="datepicker-left-tool hoverable" (click)="prevMonth()"><i class='icon arrow-left'></i></div>
                            <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_delivery_date)) }} {{ returnYear(current_delivery_date) }}</span>
                            <div class="datepicker-right-tool hoverable" (click)="nextMonth()"><i class='icon arrow-right'></i></div>
                        </div>
                        <div class="datepicker-content">
                            <div class="datepicker-title-row">
                                <span>Пн</span>
                                <span>Вт</span>
                                <span>Ср</span>
                                <span>Чт</span>
                                <span>Пт</span>
                                <span>Сб</span>
                                <span>Нд</span>
                            </div>
                            <div class="datepicker-row" id="date_delivery_body">
                                <span *ngFor="let dt of current_delivery_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_delivery_date) && dt.month == returnMonth(editDelivery.delivered_at) - 1 }" (click)="selectDate(dt)">{{ dt.date }}</span>
                            </div>
                        </div>
                    </div>
                    <span class="label">Дата на доставка</span>
                    <input type="text" placeholder="Въведете наименование" readonly value="{{ this.returnDateFormated(editDelivery.delivered_at) }}" />
                    <div class="after-action" (click)="openPicker()"><i class="icon calendar"></i></div>
                </div>
                <div class="col-xl-12 col-lg-12 input-row">
                    <span class="label">Коментар</span>
                    <textarea  [(ngModel)]="editDelivery.comment"></textarea>
                </div>
            </div>
            <div class="tab" *ngIf="tab == 'products'">
                <div class="category-title" style="margin-bottom:0px;">Артикули 
                    <span class="small-hdr-btn" (click)="promptDuplicate()" [ngClass]="{ 'disabled':!returnAnySelected() }">Дубликиране</span>
                    <span class="small-hdr-btn" style="margin-right:10px;" (click)="createSplitter()" *ngIf="products.length > 0">Създаване на разделител</span>
                    <span class="small-hdr-btn" style="margin-right:10px;" (click)="clearFields()" *ngIf="holdStartIdx != holdEndIdx && holdEndIdx != 0 ">Нулиране на избрани колони</span>
                    <span class="small-hdr-btn" style="margin-right:10px;" (click)="deleteRows()" *ngIf="holdStartIdx != holdEndIdx && holdEndIdx != 0 ">Изтриване на избрани редове</span>
                </div>
                <div class="header-row">
                    <div class="col-xl-3 input-row" style="margin-left:66px;"><span class="hint">Марка</span></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Модел (Вътрешно тяло)</span> <i class="icon icon-before-label indoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Модел (Външно тяло)</span> <i class="icon icon-before-label outdoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Сериен номер (Вътрешно тяло)</span> <i class="icon icon-before-label indoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Сериен номер (Външно тяло)</span> <i class="icon icon-before-label outdoor-unit"></i></div>
                </div>
                <virtual-scroller class="inner-table product-catalog" id="product-table" #scroll [items]="products">
                    <div class="inner-row" *ngFor="let product of scroll.viewPortItems;let i = index;trackBy: trackByFn" [ngClass]="{ 'hide':product.original && product.removed, 'splitter':product.splitter }">
                        <label class='container in-row' *ngIf="!product.splitter" id='container-row0' [ngClass]="{ 'disabled':returnAnySelected() && !product.selected }"><input type='checkbox' id='selected{{i}}' [(ngModel)]="product.selected" /> <span class='checkmark' ></span> </label>
                        <div class="col-xl-3 input-row small" *ngIf="!product.splitter"> <select [(ngModel)]="product.brand" (change)="checkForNewRow(i, '')"><option value="{{brand.name}}" *ngFor="let brand of availableBrands">{{ brand.name }}</option></select></div>
                        <div class="col-xl-2 input-row small" *ngIf="!product.splitter" [ngClass]="{ 'disabled':holdStarted && holdField != 'model_internal' }">
                            <span style="float: left; width: 35px; margin-left: 20px; height: 30px; line-height: 30px; font-size: 16px;">{{ product.num }}.</span>
                            <input type="text" [ngClass]="{ 'highlight':i >= holdStartIdx && i <= holdEndIdx && holdEndIdx != 0 && holdField == 'model_internal'  }"  style="width: calc(100% - 71px); margin-left: 0px;"  [(ngModel)]="product.model_internal" (keyup)="checkForNewRow(product.uuid, 'model_internal')" (click)="selectModel(i, 'model_internal', product)" (focusout)="deselectItemInternal(i)"  (keyup.shift)="releaseHold($event, i, 'model_internal')" (keydown.shift)="startHold($event, i, 'model_internal')" placeholder="Модел (Вътрешно тяло)" />
                            <div class="dropdown-list smaller" *ngIf="product.selectingModelInternal && product.model_internal.length > 3">
                                <span *ngFor="let model of modelInternalSuggestion" (click)="selectInternalModel(i, model)">{{ model.name }}</span>
                            </div>
                        </div>
                        <div class="col-xl-2 input-row small" *ngIf="!product.splitter" [ngClass]="{ 'disabled':holdStarted && holdField != 'model_external' }"> 
                            <input type="text" [ngClass]="{ 'highlight':i >= holdStartIdx && i <= holdEndIdx && holdEndIdx != 0 && holdField == 'model_external'  }" [(ngModel)]="product.model_external" (keyup)="checkForNewRow(product.uuid, 'model_external')" (click)="selectModel(i, 'model_external', product)" (focusout)="deselectItemExternal(i)"  (keyup.shift)="releaseHold($event, i, 'model_external')" (keydown.shift)="startHold($event, i, 'model_external')"  placeholder="Модел (Външно тяло)" /> 
                            <div class="dropdown-list smaller" *ngIf="product.selectingModelExternal && product.model_external.length > 3">
                                <span *ngFor="let model of modelExternalSuggestion" (click)="selectExternalModel(i, model)">{{ model.name }}</span>
                            </div>
                        </div>
                        <div class="col-xl-2 input-row small" *ngIf="!product.splitter"> <input type="text" id="serial_internal{{product.num}}" [ngClass]="{ 'highlight':i >= holdStartIdx && i <= holdEndIdx && holdEndIdx != 0 && holdField == 'serial_internal'  }" [(ngModel)]="product.serial_internal" (keyup)="checkForNewRow(product.uuid,'')" placeholder="Сериен № (Вътрешно тяло)" (keyup.enter)="onKey($event, 'serial_internal', product.num)" (keyup.Tab)="onKey($event, 'serial_internal', product.num)" (click)="checkMarking(i, 'serial_internal')" (keyup.shift)="releaseHold($event, i, 'serial_internal')" (keydown.shift)="startHold($event, i, 'serial_internal')" > </div>
                        <div class="col-xl-2 input-row small" *ngIf="!product.splitter"> <input type="text"  id="serial_external{{product.num}}" [ngClass]="{ 'highlight':i >= holdStartIdx && i <= holdEndIdx && holdEndIdx != 0 && holdField == 'serial_external'  }" [(ngModel)]="product.serial_external" (keyup)="checkForNewRow(product.uuid, '')" placeholder="Сериен № (Външно тяло)" (keyup.enter)="onKey($event, 'serial_external', product.num)" (keyup.Tab)="onKey($event, 'serial_external', product.num)" (click)="checkMarking(i, 'serial_external')" (keyup.shift)="releaseHold($event, i, 'serial_external')" (keydown.shift)="startHold($event, i, 'serial_external')" /> </div>
                        <div style='float:left;' *ngIf="!product.splitter"> <div class='action-remove' *ngIf="products.length > 1" (click)="removeItem(i, product.uuid)"><i class='icon cross'></i></div> </div>
        
                        <span class="split-label" *ngIf="product.splitter">
                            <div style='float:right;margin-right: 75px;'> <div class='action-remove' *ngIf="products.length > 1" (click)="removeItem(i, product.uuid)"><i class='icon cross'></i></div> </div>
                        </span>
                    </div>
                </virtual-scroller>
            </div>
        </div>
    </div>
</div>