<div class="content">
    <span class="page-title">Добавяне на дилър <span class="right-btn" (click)="AddDealer()">Запис</span></span>
    <div class="table-wrapper">
        <div class="category-title">Основна информация</div>
        <div class="col-xl-4 col-lg-4 input-row">
            <span class="label">Име на дилър</span>
            <input type="text" placeholder="Въведете наименование" [(ngModel)]="addDealer.name" />
        </div>
        <div class="col-xl-4 col-lg-4 input-row">
            <span class="label">Град</span>
            <input type="text" placeholder="Въведете наименование"  [(ngModel)]="addDealer.city"  />
        </div>
        <div class="col-xl-4 col-lg-4 input-row">
            <span class="label">Адрес на регистрация</span>
            <input type="text" placeholder="Въведете наименование"  [(ngModel)]="addDealer.address" />
        </div>
        <div class="category-title">Офиси</div>
        <div class="inner-table" id="offices-table">
            <div class="inner-row" *ngFor="let office of offices; let i = index">
                <div class="col-xl-4 input-row"> <input type="text" (keyup)="checkForNewRow(i)" [(ngModel)]="office.city" placeholder="Град" /> </div>
                <div class="col-xl-4 input-row"> <input type="text" (keyup)="checkForNewRow(i)" [(ngModel)]="office.address" placeholder="Адрес" /> </div>
                <div class="col-xl-3 input-row"> <input type="text" (keyup)="checkForNewRow(i)" [(ngModel)]="office.phone" placeholder="Телефон" /> </div>
            </div>
        </div>
    </div>
</div>