<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="modal-sm" *ngIf="modal.cntx == 'add-office'">
        <div class="modal-ttl">Добавяне на офис</div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Адрес нa офис</span>
            <input type="text" placeholder="Въведете адрес на офис" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Телефон на офис</span>
            <input type="text" placeholder="Въведете телефон на офис" [(ngModel)]="modal.obj.phone" />
        </div>
        <div class="btn-line">
            <div class="btn back" (click)="closeModal()">Отказ</div>
            <div class="btn positive" [ngClass]="{ 'disabled':modal.obj.address.length == 0 || modal.obj.phone.length == 0 }" (click)="addOffice()">Добавяне</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'adding-warranty'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Добавяне на гаранционна карта...</span>
    </div>

</div>

<div class="content no-subheader">
    <span class="page-title">
        <span class="title" style="float:left;">Нова гаранционна карта</span> 

        <a class="right-btn" [ngClass]="{ 'disabled':!returnIsValid() }" (click)="AddWarranty()">Запис</a>
        <span routerLink="/warranties" class="right-btn negative">Отказ</span>

        <div class="info-message">
            <span style="float:left;width:100%;">При регистрация на климатици <Strong>Fuji Electric</Strong>, моля попълвайте серийния номер като изпишете модела на тялото пред него, тъй като различни модели климатици могат да имат еднакви серийни номера</span>
            <strong style="float:left;text-transform: uppercase;">Пример:</strong>
            <ul>
                <li>Ако номерът на вътрешно тяло <strong>RSG12LMCA</strong> е <strong>Е000123</strong>, то трябва да въведете <strong>RSG12LMCAE000123</strong></li>
                <li>Ако номерът на външно тяло <strong>ROG12LMCA</strong> е <strong>Е000456</strong>, то трябва да въведете <strong>ROG12LMCAE000456</strong></li>
            </ul>
        </div>
    </span>

    <div class="tab-holder">
        <div class="tab-headers">
            <div class="tab-header" (click)="switchTab('general')" [ngClass]="{ 'active':tab == 'general' }">Основна информация</div>
            <div class="tab-header" (click)="switchTab('warranty_text')" *ngIf="system.returnIsAdmin() || system.returnRole() == 3" [ngClass]="{ 'active':tab == 'warranty_text' }">Гаранционни условия</div>
            <div class="tab-header" (click)="switchTab('products')"  [ngClass]="{ 'active':tab == 'products' }">Артикули<span class="count-badge">{{ returnTotalProducts() }}</span></div>
            <div class="tab-header" (click)="switchTab('warranty_time')" *ngIf="returnTotalProducts() > 0 && system.returnRole() == 3 || returnTotalProducts() > 0 && system.returnRole() == 1"  [ngClass]="{ 'active':tab == 'warranty_time' }">Гаранционни сроци</div>
        </div>
        <div class="tab-content">
            <div class="tab" *ngIf="tab == 'general'">
                <div class="col-xl-6 col-lg-6" style="float:left;">
                    <div class="category-title">Информация за клиент</div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Име на клиент <span style="color:red;" alt="Трябва да бъде въведено име (Минимум 4 символа)">*</span></span>
                        <input type="text" name="client_name" ngDefaultControl [ngClass]="{ 'error-field':addWarranty.client_name.length < 4 }" placeholder="Въведете име на клиент" (focus)="selectingClient = true" (focusout)="deselectClient()" [(ngModel)]="addWarranty.client_name" (keyup)="searchClient()" />
                        <div class="dropdown-list" *ngIf="selectingClient && addWarranty.client_name.length > 3">
                            <span *ngFor="let client of clientsSearches;let i = index" (click)="selectClient(client)">{{ client.name }}</span>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Имейл </span>
                        <input type="text" placeholder="Въведете Имейл" [(ngModel)]="addWarranty.client_email" />
                    </div>
                    <div class="col-xl-6 col-lg-6 input-row"  style="float:left;">
                        <span class="label">Адрес на монтаж <span style="color:red;" alt="Трябва да бъде въведен адрес на монтаж (Минимум 4 символа)">*</span></span>
                        <input type="text" placeholder="Въведете адрес на клиент" [ngClass]="{ 'error-field':addWarranty.client_address.length < 4 }" [(ngModel)]="addWarranty.client_address" />
                    </div>
                    <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
                        <span class="label">Населено място <span style="color:red;" alt="Трябва да бъде въведено населено място на клиент">*</span></span>
                        <input type="text" [(ngModel)]="addWarranty.town" [ngClass]="{ 'error-field':addWarranty.town.length < 1 }" (focus)="selectingTown = true" (focusout)="deselectTown()" />
                        <div class="dropdown-list" *ngIf="selectingTown && addWarranty.town.length > 0">
                            <span *ngFor="let town of returnFilteredTown()" (click)="selectTown(town)">{{ town.name }}</span>
                        </div>
                        <!-- <select [(ngModel)]="addWarranty.town">
                            <option value="{{ town.name }}" *ngFor="let town of towns">{{ town.name }}</option>
                        </select> -->
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Телефон на клиент <span style="color:red;" alt="Трябва да бъде въведен телефонен номер на клиент (Минимум 4 символа)">*</span></span>
                        <input type="text" placeholder="Въведете телефон на клиент" [ngClass]="{ 'error-field':addWarranty.client_phone.length < 4 }" [(ngModel)]="addWarranty.client_phone" />
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6" style="float:left;">
                    <div class="category-title">Сервизна информация</div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Техническо лице</span>
                        <input type="text" placeholder="Въведете техническо лице"  [(ngModel)]="addWarranty.technician" />
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Информация за сервиз <span style="color:red;" alt="Трябва да бъде избран сервиз за гаранционната карта">*</span></span>
                        <select [(ngModel)]="addWarranty.service_id" [ngClass]="{ 'error-field':addWarranty.service_id == 0 || addWarranty.service_id == -1 }" (change)="checkService()">
                            <option value="0" disabled>Изберете сервиз</option>
                            <option value="-1" >Добавяне на нов сервиз</option>
                            <option value="{{ office.uid }}" *ngFor="let office of offices">{{office.address}} / {{office.phone}}</option>
                        </select>
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <div id="delivery-date-picker" class="date-picker" [ngClass]="{ 'active':purchase_date_picker }" (clickOutside)="purchase_date_picker = false">
                            <div class="datepicker-header">
                                <div class="datepicker-left-tool hoverable" (click)="prevPurchaseMonth()"><i class='icon arrow-left'></i></div>
                                <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_purchase_date)) }} {{ returnYear(current_purchase_date) }}</span>
                                <div class="datepicker-right-tool hoverable" (click)="nextPurchaseMonth()"><i class='icon arrow-right'></i></div>
                            </div>
                            <div class="datepicker-content">
                                <div class="datepicker-title-row">
                                    <span>Пн</span>
                                    <span>Вт</span>
                                    <span>Ср</span>
                                    <span>Чт</span>
                                    <span>Пт</span>
                                    <span>Сб</span>
                                    <span>Нд</span>
                                </div>
                                <div class="datepicker-row" id="date_delivery_body">
                                    <span *ngFor="let dt of current_purchase_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_purchase_date) && dt.month == returnMonth(addWarranty.date_purchase) - 1 }" (click)="selectPurchaseDate(dt)">{{ dt.date }}</span>
                                </div>
                            </div>
                        </div>
                        <span class="label">Дата на закупуване</span>
                        <input type="text" placeholder="Въведете дата на закупуване" readonly value="{{ returnDateFormated(addWarranty.date_purchase) }}" (click)="openPurchasePicker()"  />
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <div id="delivery-date-picker" class="date-picker" [ngClass]="{ 'active':installation_date_picker }" (clickOutside)="installation_date_picker = false">
                            <div class="datepicker-header">
                                <div class="datepicker-left-tool hoverable" (click)="prevInstallationMonth()"><i class='icon arrow-left'></i></div>
                                <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_installation_date)) }} {{ returnYear(current_installation_date) }}</span>
                                <div class="datepicker-right-tool hoverable" (click)="nextInstallationMonth()"><i class='icon arrow-right'></i></div>
                            </div>
                            <div class="datepicker-content">
                                <div class="datepicker-title-row">
                                    <span>Пн</span>
                                    <span>Вт</span>
                                    <span>Ср</span>
                                    <span>Чт</span>
                                    <span>Пт</span>
                                    <span>Сб</span>
                                    <span>Нд</span>
                                </div>
                                <div class="datepicker-row" id="date_delivery_body">
                                    <span *ngFor="let dt of current_purchase_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_installation_date) && dt.month == returnMonth(addWarranty.date_installation) - 1 }" (click)="selectInstallationDate(dt)">{{ dt.date }}</span>
                                </div>
                            </div>
                        </div>
                        <span class="label">Дата на монтаж</span>
                        <input type="text" placeholder="Въведете дата на монтаж"  readonly value="{{ returnDateFormated(addWarranty.date_installation) }}" (click)="openInstallationPicker()"  />
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Адрес на сервиза</span>
                        <input type="text" placeholder="Въведете адрес на сервиза"  [(ngModel)]="addWarranty.service_address" />
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Телефон на сервиз</span>
                        <input type="text" placeholder="Въведете телефон на сервиз" [(ngModel)]="addWarranty.service_phone" />
                    </div> -->
                </div>
                <!-- <div class="col-xl-6 col-lg-6" style="float:left;" *ngIf="system.returnIsAdmin()">
                    <div class="category-title">Администрация</div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Създаване от името на</span>
                        <select>
                            <option>Собствено име</option>
                            <option>Дилър</option>
                            <option>Склад</option>
                        </select>
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Дилър</span>
                        <select>
                            <option>Собствено име</option>
                            <option>Дилър</option>
                            <option>Склад</option>
                        </select>
                    </div>
                </div> -->
                <!-- <div class="info-message">
                    <span style="float:left;width:100%;">При регистрация на климатици <strong>Fuji Electric</strong>, моля попълвайте серийния номер като изпишете модела на тялото пред него, тъй като различни модели климатици могат да имат еднакви серийни номера</span>
                    <strong style="float:left;text-transform: uppercase;">Пример:</strong>
        
                    <ul>
                        <li>Ако номерът на вътрешно тяло <strong>RSG12LMCA</strong> е <strong>Е000123</strong>, то трябва да въведете <strong>RSG12LMCAE000123</strong></li>
                        <li>Ако номерът на външно тяло <strong>ROG12LMCA</strong> е <strong>Е000456</strong>, то трябва да въведете <strong>ROG12LMCAE000456</strong></li>
                    </ul>
                </div> -->
            </div>
            <div class="tab" *ngIf="tab == 'products'">
                <div class="category-title" style="margin-bottom:0px;">Артикули</div>
                <div class="header-row">
                    <div class="col-xl-3 input-row" style="margin-left:10px;"><span class="hint">Марка</span></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Сериен номер (Вътрешно тяло)</span> <i class="icon icon-before-label indoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Модел (Вътрешно тяло)</span></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Сериен номер (Външно тяло)</span> <i class="icon icon-before-label outdoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Модел (Външно тяло)</span></div>
                </div>
                <div class="inner-table product-catalog" id="product-table">
                    <div class="inner-row" *ngFor="let item of items;let i = index">
                        <div class="col-xl-3 input-row small"> 
                            <span style="float: left; width: 35px; margin-left: 20px; height: 30px; line-height: 30px; font-size: 16px;">{{ i + 1 }}.</span>
                            <select style="width: calc(100% - 70px); margin-left: 0px;" [(ngModel)]="item.brand" (change)="checkForNewRow(i, '')"><option value="{{brand.name}}" *ngFor="let brand of availableBrands">{{ brand.name }}</option></select>
                        </div>
                        <div class="col-xl-2 input-row small">
                            <input type="text" id="serial_internal{{i}}" [(ngModel)]="item.serial_internal" (keyup)="checkForNewRow(i, 'serial_internal')" (focus)="item.selectingInternal = true" (focusout)="deselectItemInternal(i)" placeholder="Сериен № (Вътрешно тяло)" (keyup.enter)="onDetected($event, 'serial_internal', i)" [ngClass]="{ 'error-field':!returnAnySerialEntered() }" >
                            <!-- <div class="dropdown-list smaller" *ngIf="item.selectingInternal && item.serial_internal.length > 3">
                                <span *ngFor="let product of serialInternalSuggestions" (click)="selectInternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div> -->
                        </div>
                        <div class="col-xl-2 input-row small"> 
                            <input type="text" id="model_internal{{i}}" [(ngModel)]="item.model_internal" (keyup)="checkForNewRow(i, 'model_internal')" placeholder="Модел (Вътрешно тяло)">
                            <!-- <div class="dropdown-list smaller" *ngIf="item.selectingInternal && item.serial_internal.length > 3">
                                <span *ngFor="let product of serialInternalSuggestions" (click)="selectInternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div> -->
                        </div>
                        <div class="col-xl-2 input-row small"> <input type="text" id="serial_external{{i}}" [(ngModel)]="item.serial_external" (keyup)="checkForNewRow(i, 'serial_external')" (focus)="item.selectingExternal = true" (focusout)="deselectItemExternal(i)" placeholder="Сериен № (Външно тяло)"  (keyup.enter)="onDetected($event, 'serial_external', i)" [ngClass]="{ 'error-field':!returnAnySerialEntered() }" />
                            <!-- <div class="dropdown-list smaller" *ngIf="item.selectingExternal && item.serial_external.length > 3">
                                <span *ngFor="let product of serialExternalSuggestions" (click)="selectExternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div> -->
                        </div>
                        <div class="col-xl-2 input-row small"> <input type="text" id="model_external{{i}}" [(ngModel)]="item.model_external" (keyup)="checkForNewRow(i, 'model_external')" placeholder="Модел (Външно тяло)" />
                            <!-- <div class="dropdown-list smaller" *ngIf="item.selectingExternal && item.serial_external.length > 3">
                                <span *ngFor="let product of serialExternalSuggestions" (click)="selectExternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div> -->
                        </div>
                        <div style='float:left;'> <div class='action-remove' *ngIf="items.length > 1" (click)="removeItem(i)"><i class='icon cross'></i></div> </div>
                    </div>
                </div>
            </div>
            <div class="tab" *ngIf="tab == 'warranty_text'">
                <div class="category-title" style="margin-bottom:0px;">Гаранционни условия</div>
                <div class="list-selection">
                    <div [ngClass]="{ 'active':currentWarrantyText.uid == text.uid }" (click)="currentWarrantyText = text" *ngFor="let text of warranty_text">{{ text.ttl }}</div>
                </div>

                <div class="category-title" style="margin-bottom:0px;">Преглед</div>

                <div class="warranty_details" [innerHTML]="decodeHTMLEntities(currentWarrantyText.layout)">
                    
                </div>
            </div>
            <div class="tab" *ngIf="tab == 'warranty_time'">
                <div class="category-title" style="margin-bottom:0px;">Гаранционни сроци</div>
                <div class="header-row">
                    <div class="col-xl-3 input-row" style="margin-left:20px;"><span class="hint">Марка</span></div>
                    <div class="col-xl-8 input-row"><span class="hint">Срок (месеци)</span></div>
                </div>
                <div class="inner-table product-catalog" id="product-table">
                    <div class="inner-row" *ngFor="let brand of availableBrands;let i = index" [ngClass]="{ 'hide':!returnIsBrandAdded(brand) }">
                        <div class="col-xl-3 input-row small"> 
                           <input type="text" readonly value="{{ brand.name }}" />
                        </div>
                        <div class="col-xl-9 input-row small"> 
                          <input type="text" [(ngModel)]="brand.warranty_period" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>