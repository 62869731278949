<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-protocol'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете този протокол от системата</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="deleteDelivery(modal.obj.protocol_id)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'session-timeout'">
        <span class="alert-text">Вашията сесия е изтекла, моля влезте отново</span>
        <div class="btn-holder">
            <div class="btn positive" style="width:100%;">Приемам</div>
        </div>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'loading-drone-services'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Зареждане на протоколи...</span>
    </div>
</div>
<div class="content">
    <span class="page-title">
        <span class="name">Сервиз - "Дронове"</span>
        <div class="label-searched" (click)="clearSearched()" *ngIf="searched">
            <strong class="type" *ngIf="search_by == 'client_name'">Име на клиент:</strong>
            <strong class="type" *ngIf="search_by == 'serial_number'">Сериен номер:</strong>
            <span>{{ search }}</span>
            <i class="icon delete-label"></i>
        </div>
        <div class="search-title-box" *ngIf="!searched">
            <select [(ngModel)]="search_by">
                <option value="client_name">Име на клиент</option>
                <option value="serial_number">Сериен номер</option>
            </select>
            <!-- (keydown.enter)="searchSales()" -->
            <input type="text"  (keydown.enter)="searchProtocolDrones()" style="height:30px;width:240px;" [(ngModel)]="search" />
            <div class="round-btn" (click)="searchProtocolDrones()"><i class="icon search-white"></i></div>
        </div> 
        <span routerLink="/add-drone-service" class="right-btn" *ngIf="system.returnRole() == 4 || system.returnIsAdmin()">Нов протокол</span>
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-1 col-lg-1 text-center text-overflow">Протокол №</div>
            <div class="col-xl-2 col-lg-2 text-center">Сервиз</div>
            <div class="col-xl-2 col-lg-2 text-center">Клиент</div>
            <div class="col-xl-2 col-lg-1 text-center">Дата на покупка</div>
            <div class="col-xl-2 col-lg-1 text-center">Дата на постъпване</div>
            <div class="col-xl-2 col-lg-1 text-center">Обслужващо лице</div>
            <div class="col-xl-1 col-lg-1 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row" *ngIf="protocols.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма добавени протоколи</div>
        </div>
        <div class="table-wrapper-row selectable" *ngFor="let protocol of protocols; let i = index">
            <div class="col-xl-1 col-lg-1 text-center text-overflow">{{ protocol.service_num }}</div>
            <div class="col-xl-2 col-lg-2 text-center">{{ protocol.service }}</div>
            <div class="col-xl-2 col-lg-2 text-center">{{ protocol.client_name }}</div>
            <div class="col-xl-2 col-lg-1 text-center">{{ protocol.date_of_sale }}</div>
            <div class="col-xl-2 col-lg-1 text-center">{{ protocol.date_of_incoming }}</div>
            <div class="col-xl-2 col-lg-1 text-center">{{ protocol.service_user }}</div>
            <div class="col-xl-1 col-lg-1 text-center">
                <div class="action-btn" (click)="printDroneProtocol(protocol)"><i class="icon print"></i> <span class="tooltip">Принтиране</span></div>
                <div class="action-btn" routerLink="/edit-drone-service/{{ protocol.uid }}"><i class="icon edit"></i> <span class="tooltip">Промяна</span></div>
                <div class="action-btn negative" (click)="promptDelete(protocol.uid)"><i class="icon remove-white"></i> <span class="tooltip">Изтриване</span></div>
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 2" (click)="switchPage(1)">1</span>
            <span class="non-hover" *ngIf="currentPage > 3">...</span>
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>
        </div>
    </div>
</div>