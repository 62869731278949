import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.scss']
})
export class AddBrandComponent implements OnInit {
  addBrand:any = { name:"", duration:0 };

  constructor(private http:HttpClient,private router:Router) { }

  ngOnInit(): void {
  }

  AddBrand() {
    this.http.post("api/service.php?action=add_brand&name=" + this.addBrand.name + "&duration=" + this.addBrand.duration, { 'warranty_field':this.addBrand.warranty_field }).subscribe((data) => {
      this.router.navigate(['brands']);
    });
  }

}
