<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="modal-sm" *ngIf="modal.cntx == 'add-office'">
        <div class="modal-ttl">Добавяне на офис</div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Адрес нa офис</span>
            <input type="text" placeholder="Въведете адрес на офис" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Телефон на офис</span>
            <input type="text" placeholder="Въведете телефон на офис" [(ngModel)]="modal.obj.phone" />
        </div>
        <div class="btn-line">
            <div class="btn back" (click)="closeModal()">Отказ</div>
            <div class="btn positive" [ngClass]="{ 'disabled':modal.obj.address.length == 0 || modal.obj.phone.length == 0 }" (click)="addOffice()">Добавяне</div>
        </div>
    </div>

    <div class="modal-sm" *ngIf="modal.cntx == 'edit-office'">
        <div class="modal-ttl">Промяна на офис</div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Адрес нa офис</span>
            <input type="text" placeholder="Въведете адрес на офис" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Телефон на офис</span>
            <input type="text" placeholder="Въведете телефон на офис" [(ngModel)]="modal.obj.phone" />
        </div>
        <div class="btn-line">
            <div class="btn back" (click)="closeModal()">Отказ</div>
            <div class="btn positive" [ngClass]="{ 'disabled':modal.obj.address.length == 0 || modal.obj.phone.length == 0 }"  (click)="editOffice()">Запис</div>
        </div>
    </div>

    <div class="modal-sm" *ngIf="modal.cntx == 'change-password'">
        <div class="modal-ttl">Смяна на парола</div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Стара парола <span style="color:red;float:right;font-size:10px" *ngIf="modal.obj.error">Въведената парола е грешна</span></span>
            <input type="password" placeholder="Въведете старата си парола" [(ngModel)]="modal.obj.old_password" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Нова парола</span>
            <input type="password" placeholder="Въведете новата си парола" [(ngModel)]="modal.obj.new_password" />
        </div>
        <div class="btn-line">
            <div class="btn back" (click)="closeModal()">Отказ</div>
            <div class="btn positive" [ngClass]="{ 'disabled':modal.obj.old_password.length == 0 || modal.obj.new_password.length == 0 }" (click)="changePassword()">Запис</div>
        </div>
    </div>


    <div class="alert" *ngIf="modal.cntx == 'remove-office'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете този офис от системата</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="deleteOffice(modal.obj.uid)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'remove-warranty-text'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете тези гаранционни условия ?</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="RemoveWarrantyText()">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'set-default-warranty-text'">
        <span class="alert-text">Сигурни ли сте, че искате да зададете тези гаранционни условия като основни ?</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="SetDefaultWarrantyText()">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>


    <div class="alert" *ngIf="modal.cntx == 'loading-warranties'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Зареждане на гаранционни условия...</span>
    </div>
</div>

<!-- <div id="import-html" class="import-html" style="display:none;" [innerHTML]="importHTML">
</div>

<div id="print-label" *ngIf="labels.length > 0">
    <div class="page-print-title">
        <span style="margin-right:10px;" (click)="PrintLabels()" class="right-btn">Разпечатване</span>
        <span style="margin-right:10px;" (click)="labels = []" class="right-btn negative">Отказ</span>
    </div>
    <div class="label-card" *ngFor="let label of labels">
        <div class="label-name">ОПОС-{{label.num}}</div>
        <div class="label-description">
            <div>{{label.client}}</div>
            <div>{{label.phone}}</div>
            <div>{{label.city}}</div>
            <div>{{label.address}}</div>
        </div>
    </div>
</div> -->

<div class="content no-subheader" *ngIf="labels.length == 0">
    <input type="file" id="document-upload" style="display:none;" accept=".xlsx" (change)="onFileChange($event)" />
    <span class="page-title">
        <span class="name" [ngClass]="{ 'underlink':chosenSection != '' }" (click)="goHome()">Профил</span>
        <span class="name-splitter" *ngIf="chosenSection != ''">-></span>
        <span class="subname" *ngIf="chosenSection == 'offices'">Офиси</span>
        <span class="subname" *ngIf="chosenSection == 'warranties'">Гаранционни условия</span>
        <span *ngIf="chosenSection == 'offices'" class="right-btn" (click)="openModal('add-office', { address:'', phone:'' })">Добави нов</span>
        <span *ngIf="chosenSection == 'warranties' && !addingWarranty" (click)="addingWarranty = true" class="right-btn">Добави нов</span>
    </span>
    <div class="hint-card" *ngIf="chosenSection == ''">
        Добре дошли във вашия профил
    </div>
    <div *ngIf="chosenSection == ''">
        <div class="selectable-card" (click)="goSection('offices')">Офиси</div>
        <div class="selectable-card" (click)="openModal('change-password', { old_password:'', new_password:'' })">Смяна на парола</div>
        <div class="selectable-card" *ngIf="system.returnIsAdmin()" (click)="goSection('warranties')">Гаранционни условия</div>
        <!-- <div class="selectable-card" *ngIf="system.returnName() == 'Илияна Христова' || system.returnName() == 'Илия Щерев' || system.returnName() == 'Пламен Петров'" (click)="openFileManagement($event)">Импортиране на етикети</div> -->
    </div>


    <div class="table-wrapper" *ngIf="chosenSection == 'offices'">
        <div class="table-wrapper-header-row">
            <div class="col-xl-3 col-lg-3 text-center">Потребител</div>
            <div class="col-xl-4 col-lg-4 text-center">Адрес на офис</div>
            <div class="col-xl-3 col-lg-3 text-center">Телефон на офис</div>
            <div class="col-xl-1 col-lg-1 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row selectable" *ngIf="offices.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма добавени офиси</div>          
        </div>
        <div class="table-wrapper-row selectable" *ngFor="let office of offices; let i = index">
            <div class="col-xl-3 col-lg-3 text-center">{{ system.returnName() }}</div>
            <div class="col-xl-4 col-lg-4 text-center">{{ office.address }}</div>
            <div class="col-xl-3 col-lg-3 text-center">{{ office.phone }}</div>
            <div class="col-xl-1 col-lg-1 text-center">
                <div class="action-btn" (click)="openModal('edit-office', returnShadowCopy(office))"><i class="icon edit"></i></div>
                <div class="action-btn negative" (click)="openModal('remove-office', { 'uid': office.uid })"><i class="icon remove-white"></i></div>
            </div>              
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>
        </div>
    </div>

    <div *ngIf="chosenSection == 'warranties'">
        <div class="help-card" [ngClass]="{ 'expand':warranty_text.expanded, 'hide':editWarranty.uid == warranty_text.uid }" *ngFor="let warranty_text of warranty_text">
            <span class="ttl" (click)="warranty_text.expanded = !warranty_text.expanded">
                <span style="font-weight:600;float:left;">{{ warranty_text.ttl }}</span>
                <div class="badge-default" *ngIf="warranty_text.default_warranty == '1'">СТАНДАРТЕН</div>
                <i [ngClass]="{ 'reverse':warranty_text.expanded }" class="icon expand expand-icon"></i>
                <i class="icon set-default opacity-hover" style="margin-right:0px;" *ngIf="warranty_text.default_warranty != '1'" (click)="openModal('set-default-warranty-text', { 'uid':warranty_text.uid })"></i>
                <i class="icon remove opacity-hover" style="margin-right:0px;" (click)="openModal('remove-warranty-text', { uid:warranty_text.uid, default:warranty_text.default_warranty })"></i>
                <i class="icon edit-blue opacity-hover" style="margin-right:0px;" (click)="loadEditWarrantyText(warranty_text)"></i>
            </span>
            <div class="cnt" [innerHTML]="warranty_text.layout"></div>
        </div>
    
        <div class="add-card" *ngIf="addingWarranty">
            <div class="add-card-title">
                <input type="text" [(ngModel)]="addWarranty.ttl" placeholder="Въведете наименование" />
            </div>
            <angular-editor [placeholder]="'Въведете текст за гаранционни условия...'" [(ngModel)]="addWarranty.layout" [config]="editorConfig"></angular-editor>
            <div class="btn" (click)="AddWarrantyText()">Добавяне</div>
            <div class="btn back" (click)="cancelAddWarrantyText()">Отказ</div>
        </div>
    
        <div class="add-card" *ngIf="editingWarranty">
            <div class="add-card-title">
                <input type="text" [(ngModel)]="editWarranty.ttl" placeholder="Въведете наименование" />
            </div>
            <angular-editor [placeholder]="'Въведете текст за гаранционни условия...'" [(ngModel)]="editWarranty.layout" [config]="editorConfig"></angular-editor>
            <div class="btn" (click)="EditWarrantyText()">Запис</div>
            <div class="btn back" (click)="cancelEditWarrantyText()">Отказ</div>
        </div> 
    </div>
</div>