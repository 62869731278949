import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LocalSystemService } from '../local-system.service';

@Component({
  selector: 'app-error-codes',
  templateUrl: './error-codes.component.html',
  styleUrls: ['./error-codes.component.css']
})
export class ErrorCodesComponent implements OnInit {

  error_codes:any = [ ]
  chosenBrand:any = "";
  chosenBrandId = 0;
  brands:any = [];
  all_brands:any = [];

  currentPage:any = 1;
  total_pages = 0;
  total_pages_array = [];

  search:any = "";
  searched:any = false;
  search_type:any = "error";

  modal:any = { open:false, cntx:"", obj:{} }

  constructor(private http:HttpClient, public localSystem:LocalSystemService) { }

  ngOnInit(): void {
    this.GetAllBrands();
    this.getBrands();
  }

  selectBrand(brand, id) {
    this.chosenBrand = brand;
    this.chosenBrandId = id;
    this.GetErrorCodes();
  }

  clearSearched() { 
    this.searched = false; 
    this.search_type = "error";
    this.search = "";
    this.currentPage = 1;
    this.GetErrorCodes();
  }


  getBrands() {
    this.http.get("api/service.php?action=get_error_code_brands").subscribe((data) => {
      if(data['logged']) {
        this.brands = data['brands'];  
      } else {
        this.localSystem.Logout();
      }
    });
  }
  
  GetAllBrands() {
    this.http.get("api/service.php?action=get_brands").subscribe((data) => {
      if(data['logged']) {
        this.all_brands = data['brands'];  
      } else {
        this.localSystem.Logout();
      }
    });
  }

  GetErrorCodes() {
    this.http.get("api/service.php?action=get_error_codes&brand_id=" + this.chosenBrandId + "&page=" + this.currentPage).subscribe((data) => {
      this.error_codes = data['error_codes'];
      for(var ei = 0; ei < this.error_codes.length; ei++) {
        this.error_codes[ei]['additional_data'] = JSON.parse(this.error_codes[ei]['additional_data']);
      }
      this.total_pages = data['total_pages'];
    });
  }

  AddErrorCode() {
    let postObject = { 'name':this.modal.obj.name, 'description':this.modal.obj.description, 'solution':this.modal.obj.solution, 'additional_data':JSON.stringify(this.modal.obj.additional_data), 'brand_id':this.modal.obj.brand_id, 'residential':this.modal.obj.residential, 'commercial':this.modal.obj.commercial };
    this.http.post("api/service.php?action=add_error_code", postObject).subscribe((data) => {
      this.closeModal();
      if(this.chosenBrand != '') { this.GetErrorCodes(); }
    });
  }

  EditErrorCode() {
    let postObject = { 'uid':this.modal.obj.uid, 'name':this.modal.obj.name, 'description':this.modal.obj.description, 'solution':this.modal.obj.solution,  'additional_data':JSON.stringify(this.modal.obj.additional_data), 'brand_id':this.modal.obj.brand_id, 'residential':this.modal.obj.residential, 'commercial':this.modal.obj.commercial };
    console.log(postObject);
    this.http.post("api/service.php?action=edit_error_code", postObject).subscribe((data) => {
      this.closeModal();
      if(this.chosenBrand != '') { this.GetErrorCodes(); }
    });
  }

  RemoveErrorCode() {
    let postObject = { 'uid':this.modal.obj.uid };
    this.http.post("api/service.php?action=remove_error_code", postObject).subscribe((data) => {
      this.closeModal();
      if(this.chosenBrand != '') { this.GetErrorCodes(); }
    });
  }

  SwitchErrorCodeBrand() {
    if(this.modal.obj.brand_id == '3') {
      this.modal.obj.additional_data = { 'operation':1, 'timer':1, 'economy':'Непрекъснато' }
    } else if(this.modal.obj.brand_id == '4' || this.modal.obj.brand_id == '10') {
      this.modal.obj.additional_data = { 'operation':'--', 'timer':'--', 'display':'' }
    }
  }

  SwitchResidential() {
    if(this.modal.obj.residential == 1) { this.modal.obj.residential = 0; }
    else { this.modal.obj.residential = 1; }
  }

  SwitchCommercial() {
    if(this.modal.obj.commercial == 1) { this.modal.obj.commercial = 0; }
    else { this.modal.obj.commercial = 1; }
  }

  switchPage(page) {
    this.currentPage = page;
    this.GetErrorCodes();
  }

  searchCodes() {
    this.currentPage = 1;
    this.searched = true;
    this.openModal('loading-error-codes', {});
    this.http.get("api/service.php?action=search_error_codes&page=" + this.currentPage + "&search_type=" + this.search_type + "&search=" + this.search + "&brand_id=" + this.chosenBrandId).subscribe((data) => {
      this.error_codes = data['error_codes'];
      for(var ei = 0; ei < this.error_codes.length; ei++) {
        this.error_codes[ei]['additional_data'] = JSON.parse(this.error_codes[ei]['additional_data']);
      }
      this.total_pages = data['total_pages'];
      this.total_pages_array = [];
      for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
      this.closeModal();
    });
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj}; } 
  closeModal() { this.modal = { open:false, cntx:"", obj:{} }; }


  clearBrand() { this.chosenBrand = ""; this.chosenBrandId = 0; }
  promptDelete(document_id) { this.openModal('remove-document', { 'document_id':document_id }); }
  deleteDocument(document_id) {
    this.http.get("api/service.php?action=remove_document&document_id=" + document_id).subscribe((data) => {
      this.closeModal();
      this.GetErrorCodes();
    });
  }
  ReturnShadowCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

}
