<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'loading'">
      <div class="loader">
          <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
          <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
      </div>
      <span class="loader-text">Зареждане на карта...</span>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'editting-warranty'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Променяне на гаранционна карта...</span>
    </div>
  </div>

<div class="content no-subheader">
    <span class="page-title">
        <span style="float:left;">Промяна на гаранционна карта ({{ editWarranty.warranty_id }})</span>

        <a class="right-btn" [ngClass]="{ 'disabled':!returnIsValid() }" (click)="EditWarranty()">Запис</a>
        <span routerLink="/warranties" class="right-btn negative">Отказ</span>
        <div class="info-message">
            <span style="float:left;width:100%;">При регистрация на климатици <Strong>Fuji Electric</Strong>, моля попълвайте серийния номер като изпишете модела на тялото пред него, тъй като различни модели климатици могат да имат еднакви серийни номера</span>
            <strong style="float:left;text-transform: uppercase;">Пример:</strong>
            <ul>
                <li>Ако номерът на вътрешно тяло <strong>RSG12LMCA</strong> е <strong>Е000123</strong>, то трябва да въведете <strong>RSG12LMCAE000123</strong></li>
                <li>Ако номерът на външно тяло <strong>ROG12LMCA</strong> е <strong>Е000456</strong>, то трябва да въведете <strong>ROG12LMCAE000456</strong></li>
            </ul>
        </div>

    </span>

    <div class="tab-holder">
        <div class="tab-headers">
            <div class="tab-header" (click)="switchTab('general')" [ngClass]="{ 'active':tab == 'general' }">Основна информация</div>
            <div class="tab-header" (click)="switchTab('warranty_text')" *ngIf="system.returnIsAdmin() || system.returnRole() == 3" [ngClass]="{ 'active':tab == 'warranty_text' }">Гаранционни условия</div>
            <div class="tab-header" (click)="switchTab('products')"  [ngClass]="{ 'active':tab == 'products' }">Артикули <span class="count-badge">{{ returnTotalProducts() }}</span></div>
            <div class="tab-header" (click)="switchTab('warranty_time')" *ngIf="returnTotalProducts() > 0 && system.returnRole() == 3 || returnTotalProducts() > 0 && system.returnRole() == 1"  [ngClass]="{ 'active':tab == 'warranty_time' }">Гаранционни сроци</div>
        </div>
        <div class="tab-content">
            <div class="tab" *ngIf="tab == 'general'">
                <div class="col-xl-6 col-lg-6" style="float:left;">
                    <div class="category-title">Информация за клиент</div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Име на клиент <span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
                        <input type="text" placeholder="Въведете име на клиент" (focus)="selectingClient = true" (focusout)="deselectClient()" [(ngModel)]="editWarranty.client_name" (keyup)="searchClient()" />
                        <div class="dropdown-list" *ngIf="selectingClient && editWarranty.client_name.length > 3" [(ngModel)]="editWarranty.client_name">
                            <span *ngFor="let client of clientsSearches" (click)="selectClient(client)">{{ client.name }}</span>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Имейл </span>
                        <input type="text" placeholder="Въведете Имейл" [(ngModel)]="editWarranty.client_email" />
                    </div>
                    <div class="col-xl-6 col-lg-6 input-row">
                        <span class="label">Адрес на монтаж <span style="color:red;" alt="Трябва да бъде въведен адрес на монтаж">*</span></span>
                        <input type="text" placeholder="Въведете адрес на монтаж" [(ngModel)]="editWarranty.client_address" />
                    </div>
                    <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
                        <span class="label">Населено място <span style="color:red;" alt="Трябва да бъде въведено населено място на клиент">*</span></span>
                        <input type="text" [(ngModel)]="editWarranty.town" (focus)="selectingTown = true" (focusout)="deselectTown()" />
                        <div class="dropdown-list" *ngIf="selectingTown && editWarranty.town.length > 0">
                            <span *ngFor="let town of returnFilteredTown()" (click)="selectTown(town)">{{ town.name }}</span>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Телефон на клиент</span>
                        <input type="text" placeholder="Въведете телефон на клиент" [(ngModel)]="editWarranty.client_phone" />
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <div id="delivery-date-picker" class="date-picker" [ngClass]="{ 'active':purchase_date_picker }" (clickOutside)="purchase_date_picker = false">
                            <div class="datepicker-header">
                                <div class="datepicker-left-tool hoverable" (click)="prevPurchaseMonth()"><i class='icon arrow-left'></i></div>
                                <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_purchase_date)) }} {{ returnYear(current_purchase_date) }}</span>
                                <div class="datepicker-right-tool hoverable" (click)="nextPurchaseMonth()"><i class='icon arrow-right'></i></div>
                            </div>
                            <div class="datepicker-content">
                                <div class="datepicker-title-row">
                                    <span>Пн</span>
                                    <span>Вт</span>
                                    <span>Ср</span>
                                    <span>Чт</span>
                                    <span>Пт</span>
                                    <span>Сб</span>
                                    <span>Нд</span>
                                </div>
                                <div class="datepicker-row" id="date_delivery_body">
                                    <span *ngFor="let dt of current_purchase_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_purchase_date) && dt.month == returnMonth(editWarranty.date_purchase) - 1 }" (click)="selectPurchaseDate(dt)">{{ dt.date }}</span>
                                </div>
                            </div>
                        </div>
                        <span class="label">Дата на закупуване</span>
                        <input type="text" placeholder="Въведете дата на закупуване" readonly value="{{ returnDateFormated(editWarranty.date_purchase) }}" (click)="openPurchasePicker()"  />
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <div id="delivery-date-picker" class="date-picker" [ngClass]="{ 'active':installation_date_picker }" (clickOutside)="installation_date_picker = false">
                            <div class="datepicker-header">
                                <div class="datepicker-left-tool hoverable" (click)="prevInstallationMonth()"><i class='icon arrow-left'></i></div>
                                <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_installation_date)) }} {{ returnYear(current_installation_date) }}</span>
                                <div class="datepicker-right-tool hoverable" (click)="nextInstallationMonth()"><i class='icon arrow-right'></i></div>
                            </div>
                            <div class="datepicker-content">
                                <div class="datepicker-title-row">
                                    <span>Пн</span>
                                    <span>Вт</span>
                                    <span>Ср</span>
                                    <span>Чт</span>
                                    <span>Пт</span>
                                    <span>Сб</span>
                                    <span>Нд</span>
                                </div>
                                <div class="datepicker-row" id="date_delivery_body">
                                    <span *ngFor="let dt of current_purchase_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_installation_date) && dt.month == returnMonth(editWarranty.date_installation) - 1 }" (click)="selectInstallationDate(dt)">{{ dt.date }}</span>
                                </div>
                            </div>
                        </div>
                        <span class="label">Дата на монтаж</span>
                        <input type="text" placeholder="Въведете дата на монтаж"  readonly value="{{ returnDateFormated(editWarranty.date_installation) }}" (click)="openInstallationPicker()"  />
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6" style="float:left;">
                    <div class="category-title">Сервизна информация</div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Техническо лице</span>
                        <input type="text" placeholder="Въведете техническо лице"  [(ngModel)]="editWarranty.technician" />
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Адрес на сервиз</span>
                        <input type="text" placeholder="Въведете адрес на сервиз"  [(ngModel)]="editWarranty.service_address" />
                    </div>
                    <div class="col-xl-12 col-lg-12 input-row">
                        <span class="label">Телефон на сервиз</span>
                        <input type="text" placeholder="Въведете телефон на сервиз" [(ngModel)]="editWarranty.service_phone" />
                    </div>
                </div>
            </div>
            <div class="tab" *ngIf="tab == 'products'">
                <div class="category-title" style="margin-bottom:0px;">Артикули</div>
                <div class="header-row">
                    <div class="col-xl-3 input-row" style="margin-left:17px;"><span class="hint">Марка</span></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Сериен номер (Вътрешно тяло)</span> <i class="icon icon-before-label indoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Модел (Вътрешно тяло)</span></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Сериен номер (Външно тяло)</span> <i class="icon icon-before-label outdoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Модел (Външно тяло)</span></div>
                </div>
                <div class="inner-table product-catalog" id="product-table">
                    <div class="inner-row" *ngFor="let item of items;let i = index">
                        <div class="col-xl-3 input-row small"> 
                            <span style="float: left; width: 35px; margin-left: 20px; height: 30px; line-height: 30px; font-size: 16px;">{{ i + 1 }}.</span>
                            <select style="width: calc(100% - 70px); margin-left: 0px;" [(ngModel)]="item.brand" (change)="checkForNewRow(i, '')"><option value="{{brand.name}}" *ngFor="let brand of availableBrands">{{ brand.name }}</option></select>
                        </div>
                        <div class="col-xl-2 input-row small">
                            <input type="text" id="serial_internal{{i}}" [(ngModel)]="item.serial_internal" (keyup)="checkForNewRow(i, 'serial_internal')" (focus)="item.selectingInternal = true" (focusout)="deselectItemInternal(i)" placeholder="Сериен № (Вътрешно тяло)" (keyup.enter)="onDetected($event, 'serial_internal', i)">
                            <!-- <div class="dropdown-list smaller" *ngIf="item.selectingInternal && item.serial_internal.length > 3">
                                <span *ngFor="let product of serialInternalSuggestions" (click)="selectInternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div> -->
                        </div>
                        <div class="col-xl-2 input-row small"> 
                            <input type="text" id="model_internal{{i}}" [(ngModel)]="item.model_internal" (keyup)="checkForNewRow(i, 'model_internal')" placeholder="Модел (Вътрешно тяло)">
                        </div>
                        <div class="col-xl-2 input-row small"> <input type="text" id="serial_external{{i}}" [(ngModel)]="item.serial_external" (keyup)="checkForNewRow(i, 'serial_external')" (focus)="item.selectingExternal = true" (focusout)="deselectItemExternal(i)" placeholder="Сериен № (Външно тяло)" (keyup.enter)="onDetected($event, 'serial_external', i)" />
                            <!-- <div class="dropdown-list smaller" *ngIf="item.selectingExternal && item.serial_external.length > 3">
                                <span *ngFor="let product of serialExternalSuggestions" (click)="selectExternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div> -->
                        </div>
                        <div class="col-xl-2 input-row small"> <input type="text" id="model_external{{i}}" [(ngModel)]="item.model_external" (keyup)="checkForNewRow(i, 'model_external')" placeholder="Модел (Външно тяло)" />
                        </div>
                        <div style='float:left;'> <div class='action-remove' *ngIf="items.length > 1" (click)="removeItem(i)"><i class='icon cross'></i></div> </div>
                    </div>
                </div>
            </div>

            <div class="tab" *ngIf="tab == 'warranty_text'">
                <div class="category-title" style="margin-bottom:0px;">Гаранционни условия</div>
                <div class="list-selection">
                    <div [ngClass]="{ 'active':currentWarrantyText.uid == text.uid }" (click)="currentWarrantyText = text" *ngFor="let text of warranty_text">{{ text.ttl }}</div>
                </div>

                <div class="category-title" style="margin-bottom:0px;">Преглед</div>

                <div class="warranty_details" [innerHTML]="decodeHTMLEntities(currentWarrantyText.layout)">
                    
                </div>
            </div>

            <div class="tab" *ngIf="tab == 'warranty_time'">
                <div class="category-title" style="margin-bottom:0px;">Гаранционни сроци</div>
                <div class="header-row">
                    <div class="col-xl-3 input-row" style="margin-left:20px;"><span class="hint">Марка</span></div>
                    <div class="col-xl-8 input-row"><span class="hint">Срок (месеци)</span></div>
                </div>
                <div class="inner-table product-catalog" id="product-table">
                    <div class="inner-row" *ngFor="let brand of availableBrands;let i = index" [ngClass]="{ 'hide':!returnIsBrandAdded(brand) }">
                        <div class="col-xl-3 input-row small"> 
                           <input type="text" readonly value="{{ brand.name }}" />
                        </div>
                        <div class="col-xl-9 input-row small"> 
                          <input type="text" [(ngModel)]="brand.warranty_period" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>