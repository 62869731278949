import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-brand',
  templateUrl: './edit-brand.component.html',
  styleUrls: ['./edit-brand.component.scss']
})
export class EditBrandComponent implements OnInit {
  brand_id:any;
  editBrand:any = { name:"", duration:0 };


  @HostListener('window:beforeunload')
  onBeforeUnload() {
      return confirm("Сигурни ли сте, че искате да излезете в процес на добавяне ?");
  }

  constructor(private http:HttpClient,private router:Router, private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.brand_id = this.activatedRoute.snapshot.params.uid;
    this.getBrand();
  }


  getBrand() {
    this.http.get("api/service.php?action=get_brands&uid=" + this.brand_id).subscribe((data) => {
      this.editBrand.name = data['brands'][0]['name'];
      this.editBrand.duration = data['brands'][0]['warranty_period'];
      this.editBrand.warranty_field = data['brands'][0]['warranty_field'];
    });
  }

  EditBrand() {
    this.http.post("api/service.php?action=edit_brand&name=" + this.editBrand.name + "&duration=" + this.editBrand.duration + "&uid=" + this.brand_id, { 'warranty_field':this.editBrand.warranty_field }).subscribe((data) => {
      this.router.navigate(['brands']);
    });
  }
}
