import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalSystemService } from '../local-system.service';

@Component({
  selector: 'app-return-sales',
  templateUrl: './return-sales.component.html',
  styleUrls: ['./return-sales.component.css']
})
export class ReturnSalesComponent implements OnInit {
  events:any = [];
  currentPage:any = 1;

  constructor(private http:HttpClient, private system:LocalSystemService, private router:Router) {
    if(this.system.returnRole() > 1 && this.system.returnRole() < 3 && !this.system.returnIsAdmin()) { this.router.navigate(['/warranties']); }
  }

  ngOnInit(): void {
    this.GetReturns();
  }
  
  GetReturns() {
    this.events = [];
    this.currentPage = 1;
    this.http.get("api/service.php?action=get_returned_products").subscribe((data) => {
      this.events = data['events'];
    });
  }

}
