import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss']
})
export class EditDocumentComponent implements OnInit {
  document_id:any;
  editDocument:any = { name:"", brand:0, files:[] };
  availableBrands:any = [];
  loaded:any = false;

  file:any = { name:"", type:"", data:"", original:true }

  constructor(private http:HttpClient, private router:Router, private activatedRoute:ActivatedRoute) {}

  ngOnInit(): void {
    this.document_id = this.activatedRoute.snapshot.params.uid;
    this.getBrands();
    this.GetDocument();
  }

  GetDocument() {
    this.http.get("api/service.php?action=get_document&document_id=" + this.document_id).subscribe(
    response => { 
      this.editDocument = response['document'];
      this.file.name = this.editDocument.name;
      let file_split = this.editDocument.link.split(".");
      this.file.type = file_split[1];
      this.loaded = true;
    },
    error => {  });
  }

  EditDocument(){
    let params = { 'file':this.file };
    let file_original = "1";
    if(!this.file.original) { file_original = "0"; }
    this.http.post("api/service.php?action=edit_document&name="+ this.editDocument.name +"&brand_id=" + this.editDocument.brand_id + "&file_original=" + file_original + "&link=" + this.editDocument.link + "&document_id=" + this.document_id, params).subscribe(
    response => { 
      this.router.navigate(['documentation']);
    },
    error => {  });
  }

  openFileManagement(event: any){
    event.preventDefault();

    var fileManager = document.getElementById("document-upload");
    fileManager.click();
  }

  onFileChange(event: any) {
    let files = event.target.files;
    for(let i = 0; i < files.length; i++) {
      this.getBase64(files[i]).then(
        data => this.loadFile(files[i].name, data, files[i].type)
      );
    }
  }

  loadFile(name, data, type){
    this.file = { 'name':name, 'data':data, 'type':type };
    this.file.original = false;
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  getBrands() {
    this.http.get("api/service.php?action=get_brands").subscribe((data) => {
      this.availableBrands = data['brands'];
    });
  }
  

  returnFileType(file){
    if(file.type.indexOf("document") > -1 || file.type.indexOf('msword') > -1) { return 'doc' }
    else if(file.type.indexOf('png') > -1 || file.type.indexOf('jpeg') > -1) { return 'picture'; }
    else if(file.type.indexOf('pdf') > -1) { return 'pdf'; }
    else if(file.type.indexOf('ms-excel') > -1 || file.type.indexOf('spreadsheetml.sheet') > -1) { return 'sheet'; }
    return '';
  }

}
