<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>

    <div class="alert" *ngIf="modal.cntx == 'adding-protocol'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Добавяне на протокол...</span>
    </div>
</div>

<div class="content no-subheader">
    <span class="page-title">
        Нов протокол 
        <span class="right-btn" (click)="AddProtocol()">Добавяне</span>
        <span routerLink="/drone-service" class="right-btn negative">Отказ</span>
    </span>

    <div class="tab-holder">
        <div class="tab-headers">
            <div class="tab-header" (click)="switchTab('general')" [ngClass]="{ 'active':tab == 'general' }">Основна информация</div>
            <div class="tab-header" (click)="switchTab('configuration')" [ngClass]="{ 'active':tab == 'configuration' }">Конфигурация ({{ ReturnConfigurationsCount() }})</div>
            <div class="tab-header" (click)="switchTab('actions')"  [ngClass]="{ 'active':tab == 'actions' }">Извършени дейности ({{ ReturnActionsCount() }})</div>
            <div class="tab-header" (click)="switchTab('returns')"  [ngClass]="{ 'active':tab == 'returns' }">Връщане на продукта ({{ ReturnReturnsCount() }})</div>
        </div>
        <div class="tab-content">
            <div class="tab" *ngIf="tab == 'general'">
                <div class="category-title">Основна информация</div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Сервиз</span>
                    <select [(ngModel)]="addProtocol.service" style="height:34px!important;" >
                        <option>COPTER. BG Пловдив</option>
                        <option>COPTER. BG София</option>
                        <option>DJI Store Sofia</option>
                    </select>
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Сервизно лице</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="addProtocol.service_user" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <div id="delivery-date-picker" class="date-picker bot" [ngClass]="{ 'active':sale_date_picker }" (clickOutside)="sale_date_picker = false">
                        <div class="datepicker-header">
                            <div class="datepicker-left-tool hoverable" (click)="prevMonth()"><i class='icon arrow-left'></i></div>
                            <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_sale_date)) }} {{ returnYear(current_sale_date) }}</span>
                            <div class="datepicker-right-tool hoverable" (click)="nextMonth()"><i class='icon arrow-right'></i></div>
                        </div>
                        <div class="datepicker-content">
                            <div class="datepicker-title-row">
                                <span>Пн</span>
                                <span>Вт</span>
                                <span>Ср</span>
                                <span>Чт</span>
                                <span>Пт</span>
                                <span>Сб</span>
                                <span>Нд</span>
                            </div>
                            <div class="datepicker-row" id="date_delivery_body">
                                <span *ngFor="let dt of current_sale_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_sale_date) && dt.month == returnMonth(addProtocol.date_of_sale) - 1 }" (click)="selectDate(dt, 'date_of_sale')">{{ dt.date }}</span>
                            </div>
                        </div>
                    </div>
                    <span class="label">Дата на покупка</span>
                    <input type="text" placeholder="Въведете дата на покупка"  readonly value="{{ returnDateFormated(addProtocol.date_of_sale) }}" (click)="openPicker('date_of_sale')"  />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <div id="delivery-date-picker" class="date-picker bot" [ngClass]="{ 'active':incoming_date_picker }" (clickOutside)="incoming_date_picker = false">
                        <div class="datepicker-header">
                            <div class="datepicker-left-tool hoverable" (click)="prevMonth()"><i class='icon arrow-left'></i></div>
                            <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_incoming_date)) }} {{ returnYear(current_incoming_date) }}</span>
                            <div class="datepicker-right-tool hoverable" (click)="nextMonth()"><i class='icon arrow-right'></i></div>
                        </div>
                        <div class="datepicker-content">
                            <div class="datepicker-title-row">
                                <span>Пн</span>
                                <span>Вт</span>
                                <span>Ср</span>
                                <span>Чт</span>
                                <span>Пт</span>
                                <span>Сб</span>
                                <span>Нд</span>
                            </div>
                            <div class="datepicker-row" id="date_delivery_body">
                                <span *ngFor="let dt of current_sale_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_incoming_date) && dt.month == returnMonth(addProtocol.date_of_incoming) - 1 }" (click)="selectDate(dt, 'date_of_incoming')">{{ dt.date }}</span>
                            </div>
                        </div>
                    </div>
                    <span class="label">Дата на постъпване</span>
                    <input type="text" placeholder="Въведете дата на постъпване"  readonly value="{{ returnDateFormated(addProtocol.date_of_incoming) }}" (click)="openPicker('date_of_incoming')"  />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Гаранционна карта</span>
                    <input type="text" placeholder="Въведете номер на гар.карта"  [(ngModel)]="addProtocol.warranty_id" />
                </div>
                <div class="col-xl-9 col-lg-9 input-row">
                    <span class="label">Линк към видео</span>
                    <input type="text" placeholder="Въведете линк към видео"  [(ngModel)]="addProtocol.video_url" />
                </div>
                <div class="col-xl-12 col-lg-12 input-row">
                    <span class="label">Заключение</span>
                    <textarea  [(ngModel)]="addProtocol.notes"></textarea>
                </div>
                <div class="category-title">Информация на клиент</div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Име на клиент</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="addProtocol.client_name" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Телефон на клиент</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="addProtocol.client_phone" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Е-мейл на клиент</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="addProtocol.client_email" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row" style="float:left;">
                    <span class="label">Населено място</span>
                    <input type="text" [(ngModel)]="addProtocol.client_town" (focus)="selectingTown = true" (focusout)="deselectTown()" />
                    <div class="dropdown-list" *ngIf="selectingTown && addProtocol.client_town.length > 0">
                        <span *ngFor="let town of returnFilteredTown()" (click)="selectTown(town)">{{ town.name }}</span>
                    </div>
                </div>
            </div>
            <div class="tab" *ngIf="tab == 'configuration'">
                <div class="header-row" style="margin-bottom:10px;">
                    <div class="col-xl-3 input-row" style="margin-left:20px;"><span class="hint">Модел</span></div>
                    <div class="col-xl-4 input-row"><span class="hint">Сериен номер</span></div>
                    <div class="col-xl-4 input-row"><span class="hint">Забележка</span></div>
                </div>
                <div class="inner-row" *ngFor="let item of addProtocol.configurations;let i = index">
                    <div class="col-xl-3 input-row small"> <input type="text" (keyup)="checkForNewRow(i, addProtocol.configurations, 'configuration')" [(ngModel)]="item.model"> </div>
                    <div class="col-xl-4 input-row small"> <input type="text" (keyup)="checkForNewRow(i, addProtocol.configurations, 'configuration')" [(ngModel)]="item.serial"> </div>
                    <div class="col-xl-4 input-row small"> <input type="text" (keyup)="checkForNewRow(i, addProtocol.configurations, 'configuration')" [(ngModel)]="item.note"> </div>
                    <div style='float:left;'> <div class='action-remove' *ngIf="addProtocol.configurations.length > 1" (click)="RemoveProtocolField('configurations', i)"><i class='icon cross'></i></div> </div>
                </div>
            </div>
            <div class="tab" *ngIf="tab == 'actions'">
                <div class="header-row" style="margin-bottom:10px;">
                    <div class="col-xl-11 input-row" style="margin-left:20px;"><span class="hint">Дейност</span></div>
                </div>
                <div class="inner-row" *ngFor="let item of addProtocol.actions;let i = index">
                    <div class="col-xl-11 input-row small"> <input type="text" (keyup)="checkForNewRow(i, addProtocol.actions, 'actions')" [(ngModel)]="item.action"> </div>
                    <div style='float:left;'> <div class='action-remove' *ngIf="addProtocol.actions.length > 1" (click)="RemoveProtocolField('actions', i)"><i class='icon cross'></i></div> </div>
                </div>
            </div>
            <div class="tab" *ngIf="tab == 'returns'">
                <div class="header-row" style="margin-bottom:10px;">
                    <div class="col-xl-6 input-row" style="margin-left:20px;"><span class="hint">Дата</span></div>
                    <div class="col-xl-5 input-row" style="margin-left:20px;"><span class="hint">Лично / Куриер. тов</span></div>
                </div>
                <div class="inner-row" *ngFor="let item of addProtocol.returns;let i = index">
                    <div class="col-xl-6 input-row small"> 
                        <input type="text" (keyup)="checkForNewRow(i, addProtocol.returns, 'returns')" readonly (click)="OpenReturnPicker(item)" value="{{ returnDateFormated(item.date) }}"> 
                        <div id="delivery-date-picker" class="date-picker bot" [ngClass]="{ 'active':item.picker }" (clickOutside)="item.picker = false">
                            <div class="datepicker-header">
                                <div class="datepicker-left-tool hoverable" (click)="prevMonth()"><i class='icon arrow-left'></i></div>
                                <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(item.current_date)) }} {{ returnYear(item.current_date) }}</span>
                                <div class="datepicker-right-tool hoverable" (click)="nextMonth()"><i class='icon arrow-right'></i></div>
                            </div>
                            <div class="datepicker-content">
                                <div class="datepicker-title-row">
                                    <span>Пн</span>
                                    <span>Вт</span>
                                    <span>Ср</span>
                                    <span>Чт</span>
                                    <span>Пт</span>
                                    <span>Сб</span>
                                    <span>Нд</span>
                                </div>
                                <div class="datepicker-row" id="date_delivery_body">
                                    <span *ngFor="let dt of current_sale_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(item.current_date) && dt.month == returnMonth(item.date) - 1 }" (click)="selectDate(dt, item)">{{ dt.date }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 input-row small"> <input type="text" (keyup)="checkForNewRow(i, addProtocol.returns, 'returns')" [(ngModel)]="item.shipping"> </div>
                    <div style='float:left;'> <div class='action-remove' *ngIf="addProtocol.returns.length > 1" (click)="RemoveProtocolField('returns', i)"><i class='icon cross'></i></div> </div>
                </div>
            </div>
        </div>
    </div>
</div>
