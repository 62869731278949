import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {ScannerDetectionModule} from 'ngx-scanner-detection';
import { HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AgGridModule } from 'ag-grid-angular';
 

import { AppRoutingModule } from './app-routing.module';
import { NgxBarCodePutModule } from 'ngx-barcodeput';
import { AppComponent } from './app.component';
import { DeliveriesComponent } from './deliveries/deliveries.component';
import { SalesComponent } from './sales/sales.component';
import { WarrantiesComponent } from './warranties/warranties.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { UsersComponent } from './users/users.component';
import { AddDeliveryComponent } from './add-delivery/add-delivery.component';
import { FormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AddUserComponent } from './add-user/add-user.component';
import { DealersComponent } from './dealers/dealers.component';
import { AddDealerComponent } from './add-dealer/add-dealer.component';
import { StorehousesComponent } from './storehouses/storehouses.component';
import { AddStorehouseComponent } from './add-storehouse/add-storehouse.component';
import { AddWarrantyComponent } from './add-warranty/add-warranty.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { SearchProductComponent } from './search-product/search-product.component';
import { AddSaleComponent } from './add-sale/add-sale.component';
import { BrandsComponent } from './brands/brands.component';
import { AddBrandComponent } from './add-brand/add-brand.component';
import { EditBrandComponent } from './edit-brand/edit-brand.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EditDeliveryComponent } from './edit-delivery/edit-delivery.component';
import { HelpComponent } from './help/help.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { EditDocumentComponent } from './edit-document/edit-document.component';
import { EditWarrantyComponent } from './edit-warranty/edit-warranty.component';
import { ProfileComponent } from './profile/profile.component';
import { EditSaleComponent } from './edit-sale/edit-sale.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ReturnSalesComponent } from './return-sales/return-sales.component';
import { OtherComponent } from './other/other.component';
import { ErrorCodesComponent } from './error-codes/error-codes.component';
import { DeliveriesDealersComponent } from './deliveries-dealers/deliveries-dealers.component';
import { DroneServiceComponent } from './drone-service/drone-service.component';

@NgModule({
  declarations: [
    AppComponent,
    DeliveriesComponent,
    SalesComponent,
    WarrantiesComponent,
    DocumentationComponent,
    UsersComponent,
    AddDeliveryComponent,
    AddUserComponent,
    DealersComponent,
    AddDealerComponent,
    StorehousesComponent,
    AddStorehouseComponent,
    AddWarrantyComponent,
    SearchProductComponent,
    AddSaleComponent,
    BrandsComponent,
    AddBrandComponent,
    EditBrandComponent,
    EditUserComponent,
    EditDeliveryComponent,
    HelpComponent,
    AddDocumentComponent,
    EditDocumentComponent,
    EditWarrantyComponent,
    ProfileComponent,
    EditSaleComponent,
    ReturnSalesComponent,
    OtherComponent,
    ErrorCodesComponent,
    DeliveriesDealersComponent,
    DroneServiceComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ScannerDetectionModule,
    NgxBarCodePutModule,
    HttpClientModule,
    ClickOutsideModule,
    AngularEditorModule,
    AgGridModule.withComponents([]), 
    VirtualScrollerModule,
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
