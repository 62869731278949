<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="modal-sm modal" *ngIf="modal.cntx == 'add-dealer'">
        <div class="modal-ttl">Добавяне на дилър</div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
            <span class="label">Наименование<span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
            <input type="text" placeholder="Въведете наименование на дилър" [(ngModel)]="modal.obj.name" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Адрес</span>
            <input type="text" placeholder="Въведете адрес на дилър" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <span class="label">Населено място</span>
            <input type="text" placeholder='Въведете населено място' [(ngModel)]="modal.obj.town" (focus)="modal.obj.selectingTown = true" (focusout)="deselectTown()" />
            <div class="dropdown-list" *ngIf="modal.obj.selectingTown && modal.obj.town.length > 0" style="top:63px;">
                <span *ngFor="let town of returnFilteredTown()" (click)="selectTown(town)">{{ town.name }}</span>
            </div>
        </div>
        <div class="btn-line">
            <div class="btn negative" (click)="closeModal()">Отказ</div>
            <div class="btn positive" (click)="AddDealer()">Добавяне</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'adding-dealer'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Добавяне на дилър...</span>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'adding-sale'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Добавяне на продажба...</span>
    </div>


    <div class="warning-alert" *ngIf="modal.cntx == 'duplicate-serial'">
        <div class="illustration duplicate-warning"></div>
        <span class="warning-alert-ttl">Дупликиран артикул</span>
        <span class="warning-alert-description">
            Сериения номер "<strong>{{modal.obj.serial}}</strong>", който се опитвате да въведете вече съществува в тази продажба
        </span>
        <div class="btn-confirm" (click)="closeModal()">Напред</div>
    </div>
</div>


<div class="content no-subheader">
    <span class="page-title">
        Нова продажба 
        <span (click)="AddSale()" class="right-btn" [ngClass]="{ 'disabled':addSale.client_id == -1 && addSale.client_name == '' || addSale.order_number == 0 }">Добавяне</span>
        <span routerLink="/sales" class="right-btn negative">Отказ</span>
    </span>

    <div class="tab-holder">
        <div class="tab-headers">
            <div class="tab-header" (click)="switchTab('general')" [ngClass]="{ 'active':tab == 'general' }">Основна информация</div>
            <div class="tab-header" (click)="switchToProducts()"  [ngClass]="{ 'active':tab == 'products' }">Артикули <span class="count-badge">{{ returnTotalProducts() }}</span></div>
        </div>
        <div class="tab-content">
            <div class="tab" *ngIf="tab == 'general'">
                <div class="category-title">Основна информация</div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Тип на клиент</span>
                    <select [(ngModel)]="addSale.user_type" (change)="changedUserType()" style="height:34px !important;">
                        <option value="2" *ngIf="system.returnRole() != 2">Дилър</option>
                        <option value="3">Клиент</option>
                        <option value="1" *ngIf="system.returnRole() != 2">Склад</option>
                    </select>
                </div>
                <div class="col-xl-3 col-lg-3 input-row" *ngIf="addSale.user_type == '3'">
                    <span class="label">Име на клиент</span>
                    <input type="text" placeholder="Въведете наименование" (keyup)="searchSaleTo()" (focus)="selectingClient = true" (focusout)="clearSelectingClient()"  [(ngModel)]="addSale.client_name" />
                    <div class="dropdown-list" *ngIf="selectingClient && addSale.client_name.length > 3 && clientSuggestions.length > 0">
                        <span *ngFor="let client of clientSuggestions" (click)="selectName(client)">{{ returnName(client) }}</span>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 input-row" *ngIf="addSale.user_type == '2'">
                    <span class="label">Дилър</span>
                    <input [(ngModel)]="addSale.dealer_name" (paste)="changedDealerName()" (input)="changedDealerName()" (keyup)="changedDealerName()" (focus)="selecting_dealer = true" (focusout)="deselectingDealer()" type="text" placeholder="Въведете име на дилър" />
                    <div class="dropdown-list extended" *ngIf="selecting_dealer">
                        <div class="dropdown-list-item" (click)="selectDealer(dealer)" *ngFor="let dealer of returnFilterDealers()">{{ dealer.name }}</div>
                    </div>
                    <div class="add-btn" [ngClass]="{ 'disabled':addSale.client_id != -1 }" (click)="startAddDealer()"><i class="icon plus"></i></div>
                </div>
                <div class="col-xl-3 col-lg-3 input-row" *ngIf="addSale.user_type == '1'">
                    <span class="label">Склад</span>
                    <select [(ngModel)]="addSale.client_id" (change)="changedStorehouse()" style="height:34px !important;">
                        <option value="-1" disabled>Моля изберете склад</option>
                        <option *ngFor="let storehouse of availableStorehouses" [value]="storehouse.uid" value="{{ storehouse.uid }}">{{ storehouse.name }}</option>
                    </select>
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Номер на поръчка <span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
                    <input [(ngModel)]="addSale.order_number" type="text" placeholder="Въведете номер на поръчка" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <div id="delivery-date-picker" class="date-picker bot" [ngClass]="{ 'active':sale_date_picker }" (clickOutside)="sale_date_picker = false">
                        <div class="datepicker-header">
                            <div class="datepicker-left-tool hoverable" (click)="prevMonth()"><i class='icon arrow-left'></i></div>
                            <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_sale_date)) }} {{ returnYear(current_sale_date) }}</span>
                            <div class="datepicker-right-tool hoverable" (click)="nextMonth()"><i class='icon arrow-right'></i></div>
                        </div>
                        <div class="datepicker-content">
                            <div class="datepicker-title-row">
                                <span>Пн</span>
                                <span>Вт</span>
                                <span>Ср</span>
                                <span>Чт</span>
                                <span>Пт</span>
                                <span>Сб</span>
                                <span>Нд</span>
                            </div>
                            <div class="datepicker-row" id="date_delivery_body">
                                <span *ngFor="let dt of current_sale_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_sale_date) && dt.month == returnMonth(addSale.date_of_sale) - 1 }" (click)="selectDate(dt)">{{ dt.date }}</span>
                            </div>
                        </div>
                    </div>
                    <span class="label">Дата на продажба</span>
                    <input type="text" placeholder="Въведете дата на продажба" (keyup.Tab)="switchToProducts()"  readonly value="{{ returnDateFormated(addSale.date_of_sale) }}" (click)="openPicker()"  />
                </div>
                <div class="col-xl-3 col-lg-3 input-row" *ngIf="system.returnName() != 'Склад Пловдив'">
                    <span class="label">Фактура</span>
                    <div class="toggle" (click)="SwitchInvoice()" [ngClass]="{ 'active':addSale.invoice == 1 }">
                        <div class="toggle-dot"></div>
                        <span class="toggle-hint" *ngIf="addSale.invoice == 1">Да</span>
                        <span class="toggle-hint" *ngIf="addSale.invoice == 0">Не</span>
                    </div>
                </div>
            </div>
            <div class="tab" *ngIf="tab == 'products'">
                <div class="category-title" style="margin-bottom:0px;">Артикули</div>
                <div class="header-row">
                    <div class="col-xl-3 input-row" style="margin-left:10px;"><span class="hint" style="margin-left:50px;">Сериен номер (Вътрешно тяло)</span> <i class="icon icon-before-label indoor-unit"></i></div>
                    <div class="col-xl-3 input-row"><span class="hint">Модел (Вътрешно тяло)</span></div>
                    <div class="col-xl-3 input-row"><span class="hint" style="margin-left:50px;">Сериен номер (Външно тяло)</span> <i class="icon icon-before-label outdoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint">Модел (Външно тяло)</span></div>
                </div>
                <div class="inner-table product-catalog" id="product-table">
                    <div class="inner-row" *ngFor="let item of addSale.items;let i = index">
                        <div class="col-xl-3 input-row small"> 
                            <span style="float: left; width: 25px; margin-left: 20px; height: 30px; line-height: 30px; font-size: 16px;">{{ i + 1 }}.</span>
                            <input type="text" (paste)="onPaste($event, i, 'serial_internal')" style="width: calc(100% - 60px); margin-left: 0px;" id="serial_internal{{i}}" [(ngModel)]="item.serial_internal" (keyup)="checkForNewRow(i, 'serial_internal')" (focus)="item.selectingInternal = true" (focusout)="deselectItemInternal(i)" placeholder="Сериен № (Вътрешно тяло)"  (keyup.enter)="onKey($event, 'serial_internal', i)">
                            <!-- <div class="dropdown-list smaller" *ngIf="item.selectingInternal && item.serial_internal.length > 3">
                                <span *ngFor="let product of serialInternalSuggestions" (click)="selectInternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div> -->
                            <div class="model-liner" style="right: 16px;height: 26px;" [ngClass]="{ 'fuji':item.brand == 'Fuji Electric', 'midea':item.brand == 'Midea', 'daikin':item.brand == 'Daikin', 'treo':item.brand == 'TREO', 'panasonic':item.brand == 'Panasonic', 'samsung':item.brand == 'Samsung', 'mitsubishi':item.brand == 'Mitsubishi' }"></div>
                        </div>
                        <div class="col-xl-3 input-row small"> 
                            <input type="text" id="model_internal{{i}}" [(ngModel)]="item.model_internal" (paste)="onPaste($event, i, 'model_internal')" (keyup)="checkForNewRow(i, 'model_internal')" placeholder="Модел (Вътрешно тяло)">
                            <div class="model-liner" [ngClass]="{ 'fuji':item.brand == 'Fuji Electric', 'midea':item.brand == 'Midea', 'daikin':item.brand == 'Daikin', 'treo':item.brand == 'TREO', 'panasonic':item.brand == 'Panasonic', 'samsung':item.brand == 'Samsung', 'mitsubishi':item.brand == 'Mitsubishi' }"></div>
                            <!-- <div class="dropdown-list smaller" *ngIf="item.selectingInternal && item.serial_internal.length > 3">
                                <span *ngFor="let product of serialInternalSuggestions" (click)="selectInternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div> -->
                        </div>
                        <div class="brand-num" [ngClass]="{ 'fuji':item.brand == 'Fuji Electric', 'midea':item.brand == 'Midea', 'daikin':item.brand == 'Daikin', 'treo':item.brand == 'TREO', 'panasonic':item.brand == 'Panasonic', 'samsung':item.brand == 'Samsung', 'mitsubishi':item.brand == 'Mitsubishi' }">{{ item.brand_id }}</div>
                        <div class="col-xl-3 input-row small"> <input type="text" id="serial_external{{i}}" [(ngModel)]="item.serial_external" (paste)="onPaste($event, i, 'serial_external')" (keyup)="checkForNewRow(i, 'serial_external')" (focus)="item.selectingExternal = true" (focusout)="deselectItemExternal(i)" placeholder="Сериен № (Външно тяло)"  (keyup.enter)="onKey($event, 'serial_external', i)" />
                            <div class="dropdown-list smaller" *ngIf="item.selectingExternal && item.serial_external.length > 3">
                                <span *ngFor="let product of serialExternalSuggestions" (click)="selectExternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div>
                            <div class="model-liner" [ngClass]="{ 'fuji':item.brand == 'Fuji Electric', 'midea':item.brand == 'Midea', 'daikin':item.brand == 'Daikin', 'treo':item.brand == 'TREO', 'panasonic':item.brand == 'Panasonic', 'samsung':item.brand == 'Samsung', 'mitsubishi':item.brand == 'Mitsubishi' }"></div>
                        </div>
                        <div class="col-xl-2 input-row small"> <input type="text" id="model_external{{i}}" [(ngModel)]="item.model_external" (paste)="onPaste($event, i, 'model_external')" (keyup)="checkForNewRow(i, 'model_external')" placeholder="Модел (Външно тяло)" />
                            <!-- <div class="dropdown-list smaller" *ngIf="item.selectingExternal && item.serial_external.length > 3">
                                <span *ngFor="let product of serialExternalSuggestions" (click)="selectExternalProduct(i, product)">{{ product.serial_number }}</span>
                            </div> -->
                            <div class="model-liner" [ngClass]="{ 'fuji':item.brand == 'Fuji Electric', 'midea':item.brand == 'Midea', 'daikin':item.brand == 'Daikin', 'treo':item.brand == 'TREO', 'panasonic':item.brand == 'Panasonic', 'samsung':item.brand == 'Samsung', 'mitsubishi':item.brand == 'Mitsubishi' }"></div>
                        </div>
                        <!-- <div class="col-xl-2 input-row"> <input type="text" readonly [(ngModel)]="item.model_internal" (keyup)="checkForNewRow(i, '')" placeholder="Модел (Вътрешно тяло)" /> </div>
                        <div class="col-xl-2 input-row"> <input type="text" readonly [(ngModel)]="item.model_external" (keyup)="checkForNewRow(i, '')" placeholder="Модел (Външно тяло)" /> </div>
                        <div class="col-xl-3 input-row"> <input type="text" readonly [(ngModel)]="item.brand" (keyup)="checkForNewRow(i, '')" placeholder="Марка" /> </div> -->
                        <div style='float:left;'> <div class='action-remove' *ngIf="addSale.items.length > 1" (click)="removeItem(i)"><i class='icon cross'></i></div> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
  function onBeforeUnload(e) {
    if (thereAreUnsavedChanges()) {
        e.preventDefault();
        e.returnValue = '';
        return;
    }

    delete e['returnValue'];
}

window.addEventListener('beforeunload', onBeforeUnload);
    </script>