import { HttpClient } from '@angular/common/http';
import { Component, OnInit, HostListener, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgxBarCodePutModule, IDetect, IDelete } from 'ngx-barcodeput';
import { LocalSystemService } from '../local-system.service';

@Component({
  selector: 'app-add-delivery',
  templateUrl: './add-delivery.component.html',
  styleUrls: ['./add-delivery.component.scss']
})
export class AddDeliveryComponent implements OnInit {
  availableBrands:any = [];
  addDelivery:any = { uid:0, name:"", date_add:new Date(), comment:"", items:[{ uuid:this.MakeUnique(16), num:1, splitter:false, selected:false, brand:"", model_internal:"", model_external:"", serial_internal:"", serial_external:"" }], document_number:'' };
  example:any = { selected:false, brand_example:"", model_internal_example:"", model_external_example:"" }
  modal:any = { open:false, cntx:'', obj:{ } };

  selected_picker:any = false;

  timeoutModelInternal:any;
  modelInternalSuggestion:any = [];

  timeoutModelExternal:any;
  modelExternalSuggestion:any;

  delivery_date_picker:any = false;
  current_delivery_date:any;
  current_delivery_month_dates:any = [];
  timeoutPicker:any;
  availableID:any = "0";

  num:any = 1;

  columnDefs = [
    { field: 'brand' },
    { field: 'model_internal' },
    { field: 'model_external'},
    { field: 'serial_internal'},
    { field: 'serial_external'}
  ];

  holdStartIdx:any = 0;
  holdEndIdx:any = 0;
  holdField:any = "";
  holdStarted:any = false;

  tab:any = "general";
  audio:any;

  @HostListener('window:beforeunload')
    onBeforeUnload() {
      return confirm("Сигурни ли сте, че искате да излезете в процес на добавяне ?");
  }

  constructor(private http:HttpClient, private system:LocalSystemService, private router:Router, public ngZone:NgZone) {
    if(this.system.returnRole() > 1 && !this.system.returnIsAdmin()) { this.router.navigate(['/warranties']); }
    this.getAvailableBrands();
    this.current_delivery_date = this.addDelivery.date_add;
    // this.getAvailableId();
    this.audio = new Audio();
    // this.audio.src = "../../assets/audio/duplicate-alarm.wav";
    this.audio.src = "../../assets/audio/warranty.mp3";
    this.audio.load();
  }
  ngOnInit(): void { }

  public onKey(event: IDetect, field:String, idx:number) {
    let idx_found = this.addDelivery.items.findIndex(item => item.num == idx);
    if(field == 'serial_internal') {
      if(this.addDelivery.items.filter(item => item.serial_internal == this.addDelivery.items[idx_found].serial_internal).length > 1 && this.addDelivery.items[idx_found].serial_internal != ""){
        this.openModal('duplicate-serial', { 'serial':this.addDelivery.items[idx_found].serial_internal });
        this.audio.currentTime = 0;
        this.audio.play();
        this.addDelivery.items[idx_found].serial_internal = "";
        this.removeItem(idx_found, this.addDelivery.items[idx_found].uuid);
      } else {
        if(idx < this.addDelivery.items.length) {
          document.getElementById('serial_internal' + [idx + 1])?.focus();
        }
      }

    } else if(field == 'serial_external') {
      if(this.addDelivery.items.filter(item => item.serial_external == this.addDelivery.items[idx_found].serial_external).length > 1 && this.addDelivery.items[idx_found].serial_external != ""){
        this.openModal('duplicate-serial', { 'serial':this.addDelivery.items[idx_found].serial_external });
        this.audio.currentTime = 0;
        this.audio.play();
        this.addDelivery.items[idx_found].serial_external = "";
        this.removeItem(idx_found, this.addDelivery.items[idx_found].uuid);
      } else {
        if(idx < this.addDelivery.items.length) {
          document.getElementById('serial_external' + [idx + 1])?.focus();
        }
      }
    }
  }

  public onDelete(event: IDelete) {
    /* {event: KeyboardEvent, value: "3333333", type: "delete"} */
  }

  public startHold(e, idx, type){
    if(!this.holdStarted) {
      this.holdEndIdx = 0;
      this.holdStartIdx = idx;
      this.holdStarted = true;
      this.holdField = type;
    }

  }

  public releaseHold(e, idx, type) {
    if(this.holdStarted) {
      this.holdStarted = false;
    }
  }

  switchTab(tab) { this.tab = tab; }

  clearFields(){
    let start_num = 0;
    let end_num = 0;
    if(this.holdStartIdx > this.holdEndIdx) { start_num = this.holdEndIdx; end_num = this.holdStartIdx; }
    else { start_num = this.holdStartIdx; end_num = this.holdEndIdx; }
    for(let i = start_num; i < end_num + 1; i++){
      this.addDelivery.items[i][this.holdField] = "";
    }
    this.holdStartIdx = 0;
    this.holdEndIdx = 0;
    this.holdField = '';
  }
  
  deleteRows() {
    let start_num = 0;
    let end_num = 0;
    if(this.holdStartIdx > this.holdEndIdx) { start_num = this.holdEndIdx; end_num = this.holdStartIdx; }
    else { start_num = this.holdStartIdx; end_num = this.holdEndIdx; }
    this.addDelivery.items.splice(start_num, (end_num - start_num) + 1);
    this.holdStartIdx = 0;
    this.holdEndIdx = 0;
    this.holdField = '';
    this.num = 0;
    for(let i = 0; i < this.addDelivery.items.length; i++){
      this.num += 1;
      this.addDelivery.items[i].num = this.num;
      if(this.addDelivery.items[i].splitter) { this.num = 0; }
    }
  }

  checkForNewRow(uuid:String, type:String){
    let idx = this.addDelivery.items.findIndex( x => x.uuid == uuid );
    if(idx == this.addDelivery.items.length - 1) {
      if(this.addDelivery.items[idx].brand != "" || this.addDelivery.items[idx].model_internal != "" || this.addDelivery.items[idx].model_external != "" || this.addDelivery.items[idx].serial_internal != "" || this.addDelivery.items[idx].serial_external != ""){
        this.num += 1;
        this.addDelivery.items.push({ 'uuid':this.MakeUnique(16), 'num':this.num, splitter:false, selected:false, brand:this.example.brand_example, model_internal:this.example.model_internal_example, model_external:this.example.model_external_example, serial_internal:"", serial_external:"" });
      }
    }

    this.addDelivery.items[idx].model_internal = this.addDelivery.items[idx].model_internal.toUpperCase();
    this.addDelivery.items[idx].model_external = this.addDelivery.items[idx].model_external.toUpperCase();

    this.addDelivery.items[idx].serial_internal = this.addDelivery.items[idx].serial_internal.toUpperCase();
    this.addDelivery.items[idx].serial_external = this.addDelivery.items[idx].serial_external.toUpperCase();
    this.addDelivery.items[idx].serial_internal = this.returnTranslate(this.addDelivery.items[idx].serial_internal);
    this.addDelivery.items[idx].serial_external = this.returnTranslate(this.addDelivery.items[idx].serial_external);

    if(type == 'model_internal') { this.searchModelInternal(idx) }
    else if(type == 'model_external') { this.searchModelExternal(idx); }
  }

  selectModel(idx, field, item) {
    if(field == 'model_internal') { item.selectingModelInternal = true; }
    if(field == 'model_external') { item.selectingModelExternal = true; }
    this.checkMarking(idx, field);
  }

  checkMarking(idx, field){
    if(this.holdStarted) {
      this.holdEndIdx = idx;
    } else {
      this.holdStartIdx = 0;
      this.holdEndIdx = 0;
      this.holdField = '';
      this.holdStarted = false;
    }
  }

  searchModelInternal(idx) {
    if(this.addDelivery.items[idx].model_internal.length > 1) {
      clearTimeout(this.timeoutModelInternal);
      this.timeoutModelInternal = setTimeout(()=>{
        this.http.get("api/service.php?action=get_models&brand=" + this.addDelivery.items[idx].brand + "&model=" + this.addDelivery.items[idx].model_internal + "&type=1").subscribe((data) => {
          this.modelInternalSuggestion = data['models'];
        });
      }, 300);
    }
  }

  searchModelExternal(idx) {
    if(this.addDelivery.items[idx].model_external.length > 1) {
      clearTimeout(this.timeoutModelExternal);
      this.timeoutModelExternal = setTimeout(()=>{ 
        this.http.get("api/service.php?action=get_models&brand=" + this.addDelivery.items[idx].brand + "&model=" + this.addDelivery.items[idx].model_external + "&type=2").subscribe((data) => {
          this.modelExternalSuggestion = data['models'];
        });
      }, 300);
    }
  }

  saveExample() {
    let changed = false;
    if(this.modal.obj.brand_selected) { this.example.brand_example = this.addDelivery.items.filter(item => item.selected)[0].brand; changed = true; }
    if(this.modal.obj.model_internal_selected) { this.example.model_internal_example = this.addDelivery.items.filter(item => item.selected)[0].model_internal; changed = true; }
    if(this.modal.obj.model_external_selected) { this.example.model_external_example = this.addDelivery.items.filter(item => item.selected)[0].model_external; changed = true; }
    if(changed) { this.example.selected = true; }
    this.closeModal();
    for(let i = 0; i < this.addDelivery.items.length; i++) { this.addDelivery.items[i].selected = false; }
  }

  createSplitter() {
    this.addDelivery.items.push({ 'uuid':this.MakeUnique(16), splitter:true, 'brand':"", model_internal:"", model_external: "", serial_internal:"", serial_external:"" })
    this.num = 1;
    this.addDelivery.items.push({ 'uuid':this.MakeUnique(16), num:this.num, splitter:false, 'brand':"", model_internal:"", model_external: "", serial_internal:"", serial_external:"" })
  }

  promptDuplicate() {
    var x = prompt("Въведете брой редове (1-400)", "0");
    var number = parseInt(x);
    if(number > 400) {
      alert("Въведете число между 1 и 400");
      this.promptDuplicate();
    } else {
      let example_product = this.addDelivery.items.filter(item => item.selected)[0];

      for(let i = 0; i < number; i++) {
        this.num += 1;
        this.addDelivery.items.push({ 'uuid':this.MakeUnique(16), 'num':this.num, splitter:false, 'brand':example_product.brand, model_internal:example_product.model_internal, model_external: example_product.model_external, serial_internal:"", serial_external:"" })
      }
      this.num += 1;
      this.addDelivery.items.push({ 'uuid':this.MakeUnique(16), num:this.num, splitter:false, 'brand':"", model_internal:"", model_external: "", serial_internal:"", serial_external:"" })

      for(let i = 0 ; i < this.addDelivery.items.length; i++) { this.addDelivery.items[i].selected = false; }
    }
  }

  AddDelivery(action) {
    this.openModal('adding-delivery', {});
    if(this.addDelivery.name.length > 0) {
      let postObject = { products:[], date: this.returnDateFormatedForStorage(this.addDelivery.date_add) };
      for(let i = 0; i < this.addDelivery.items.length; i++) {
        postObject.products.push(this.addDelivery.items[i]);
      }
      this.http.post("api/service.php?action=add_delivery&name=" + this.addDelivery.name + "&comment=" + this.addDelivery.comment + "&delivery_id=" + this.addDelivery.uid + "&document_number=" + this.addDelivery.document_number, postObject).subscribe((data) => {
        if(action == 1) {
          this.system.getDeliveries();
          setTimeout(()=>{ 
            this.closeModal();
            this.router.navigate(['deliveries']);
          }, 500);
        } else if(action == 2) {
          this.router.navigate(["edit-delivery", data['delivery_id']]);
        }

      });
    }
  }

  deselectItemInternal(idx) {
    setTimeout(()=>{ this.addDelivery.items[idx].selectingModelInternal = false }, 300);
  }

  deselectItemExternal(idx) {
    setTimeout(()=>{ this.addDelivery.items[idx].selectingModelExternal = false }, 300);
  }

  openPicker() {
    this.timeoutPicker = setTimeout(()=>{      
      this.delivery_date_picker = true; 
      this.current_delivery_date = new Date(this.addDelivery.date_add);
      this.Formulate(this.current_delivery_date);
    }, 50);
  }

  closePicker(ev) { 
    this.delivery_date_picker = false;
  }

  getAvailableId() {
    this.http.get("api/service.php?action=get_delivery_id").subscribe((data) => {
      this.availableID = data['available_id'];
      this.addDelivery.uid = parseInt(this.availableID);
    });
  }


  getAvailableBrands() {
    this.http.get("api/service.php?action=get_brands").subscribe((data) => {
      this.availableBrands = data['brands'];
    });
  }

  selectDate(dt){
    this.addDelivery.date_add.setYear(this.current_delivery_date.getFullYear());
    this.addDelivery.date_add.setDate(dt.date);
    this.addDelivery.date_add.setMonth(dt.month);
    this.delivery_date_picker = false;
  }

  nextMonth() { this.current_delivery_date.setMonth(this.current_delivery_date.getMonth() + 1); this.Formulate(this.current_delivery_date); }
  prevMonth() { this.current_delivery_date.setMonth(this.current_delivery_date.getMonth() - 1); this.Formulate(this.current_delivery_date); }

  selectInternalModel(idx, model) { this.addDelivery.items[idx].model_internal = model.name; }
  selectExternalModel(idx, model) { this.addDelivery.items[idx].model_external = model.name; }

  Formulate(dt) {
    this.current_delivery_month_dates = [];

    var firstDay = new Date(dt.getFullYear(), dt.getMonth(), 1);
    var lastDay = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    let predate_num = 0;
    // GENERATE PREDATE

    for(let i = 0; i < 42 - lastDay.getDate(); i++) {
        let day_idx = i;
        let first_day = firstDay.getDay();
        if(first_day == 0) { first_day = 7; }
        if(day_idx < first_day - 1) {
            let negative_day = day_idx * -1;
            let date_reformed = new Date(dt.getFullYear(), dt.getMonth(), negative_day);
            this.current_delivery_month_dates.unshift({ "date": date_reformed.getDate(), "month":date_reformed.getMonth(), "active":false})
            predate_num += 1;
        }
    }
    for(let i = 0; i < lastDay.getDate(); i++) {
        let day_id = i + 1;
        this.current_delivery_month_dates.push({ "date":day_id, "month":dt.getMonth(), "active":true });
    }
    for(let i = 0; i < 42 - lastDay.getDate() - predate_num; i++) {
        let date_reformed = new Date(dt.getFullYear(), dt.getMonth() + 2, i + 1);
        this.current_delivery_month_dates.push({ "date":date_reformed.getDate(), "month":date_reformed.getMonth(), "active":false });
    }
  }

  returnDateFormatedForStorage(dt) {
    return dt.getFullYear() + "-" + this.returnPadZero(dt.getMonth()+ 1, 2) + "-" + this.returnPadZero(dt.getDate(), 2) +  " " +  this.returnPadZero(dt.getHours(), 2) + ":" + this.returnPadZero(dt.getMinutes(), 2) + ":" + this.returnPadZero(dt.getSeconds(), 2);
  }

  returnDateFormated(dt){
    return this.returnPadZero(dt.getDate(), 2) + "-" + this.returnPadZero(dt.getMonth()+ 1, 2) + "-" + dt.getFullYear();
  }

  clearExample() { this.example = { selected:false, brand_example:"", model_internal_example:"", model_external_example:"" }; }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj } }
  closeModal() { this.modal.open = false; }

  MakeUnique(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *  charactersLength));
    }
    return result;
  }


  removeItem(idx:number, uuid) { 
    if(this.addDelivery.items[idx].splitter) {
      this.addDelivery.items.splice(idx, 1); 
      this.num = 0;
      for(let i = 0; i < this.addDelivery.items.length; i++){
        this.num += 1;
        this.addDelivery.items[i].num = this.num;
        if(this.addDelivery.items[i].splitter) { this.num = 0; }
      }
    } else {
      let num = 0;
      let start_idx = 0;
      for(let i = idx; i < this.addDelivery.items.length; i++){
        if(this.addDelivery.items[i].uuid == uuid) {
          num = this.addDelivery.items[idx].num;
          this.addDelivery.items.splice(i, 1);
          start_idx = i;
        }
      }
      for(let i = idx; i < this.addDelivery.items.length; i++){
        if(this.addDelivery.items[i].num > num && i >= start_idx) { this.addDelivery.items[i].num -= 1; }
      }
      this.num = this.addDelivery.items[this.addDelivery.items.length - 1].num;
    }
    this.ngZone.run(()=>{});
  }

  returnAnySelected() {
    if(this.addDelivery.items.filter(item => item.selected).length > 0) { return true; }
    else { return false; }
  }

  returnYear(dt) { return dt.getFullYear(); }
  returnMonth(dt) { return dt.getMonth() + 1; }
  returnMonthName(month) {
    if(month == 1) { return "Януари"; }
    else if(month == 2) { return "Февруари"; }
    else if(month == 3) { return "Март"; }
    else if(month == 4) { return "Април"; }
    else if(month == 5) { return "Май"; }
    else if(month == 6) { return "Юни"; }
    else if(month == 7) { return "Юли"; }
    else if(month == 8) { return "Август"; }
    else if(month == 9) { return "Септември"; }
    else if(month == 10) { return "Октомври"; }
    else if(month == 11) { return "Ноември"; }
    else if(month == 12) { return "Декември"; }
    else { return ""; }
  }
  returnDate(dt) { return dt.getDate();  }
  returnPadZero(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }

  returnTranslate = function(text) {
    text = text
        .replace(/\u0401/g, '')
        .replace(/\u0419/g, '')
        .replace(/\u0426/g, '')
        .replace(/\u0423/g, '')
        .replace(/\u041A/g, '')
        .replace(/\u0415/g, '')
        .replace(/\u041D/g, '')
        .replace(/\u0413/g, '')
        .replace(/\u0428/g, '')
        .replace(/\u0429/g, '')
        .replace(/\u0417/g, '')
        .replace(/\u0425/g, '')
        .replace(/\u042A/g, '')
        .replace(/\u0451/g, '')
        .replace(/\u0439/g, '')
        .replace(/\u0446/g, '')
        .replace(/\u0443/g, '')
        .replace(/\u043A/g, '')
        .replace(/\u0435/g, '')
        .replace(/\u043D/g, '')
        .replace(/\u0433/g, '')
        .replace(/\u0448/g, '')
        .replace(/\u0449/g, '')
        .replace(/\u0437/g, '')
        .replace(/\u0445/g, '')
        .replace(/\u044A/g, "")
        .replace(/\u0424/g, '')
        .replace(/\u042B/g, '')
        .replace(/\u0412/g, '')
        .replace(/\u0410/g, '')
        .replace(/\u041F/g, '')
        .replace(/\u0420/g, '')
        .replace(/\u041E/g, '')
        .replace(/\u041B/g, '')
        .replace(/\u0414/g, '')
        .replace(/\u0416/g, '')
        .replace(/\u042D/g, '')
        .replace(/\u0444/g, '')
        .replace(/\u044B/g, '')
        .replace(/\u0432/g, '')
        .replace(/\u0430/g, '')
        .replace(/\u043F/g, '')
        .replace(/\u0440/g, '')
        .replace(/\u043E/g, '')
        .replace(/\u043B/g, '')
        .replace(/\u0434/g, '')
        .replace(/\u0436/g, '')
        .replace(/\u044D/g, '')
        .replace(/\u042F/g, '')
        .replace(/\u0427/g, '')
        .replace(/\u0421/g, '')
        .replace(/\u041C/g, '')
        .replace(/\u0418/g, '')
        .replace(/\u0422/g, '')
        .replace(/\u042C/g, "")
        .replace(/\u0411/g, '')
        .replace(/\u042E/g, '')
        .replace(/\u044F/g, '')
        .replace(/\u0447/g, '')
        .replace(/\u0441/g, '')
        .replace(/\u043C/g, '')
        .replace(/\u0438/g, '')
        .replace(/\u0442/g, '')
        .replace(/\u044C/g, "")
        .replace(/\u0431/g, '')
        .replace(/\u044E/g, '');

    return text;
  };

  //function(text) {
  //   text = text
  //       .replace(/\u0401/g, 'YO')
  //       .replace(/\u0419/g, 'I')
  //       .replace(/\u0426/g, 'TS')
  //       .replace(/\u0423/g, 'U')
  //       .replace(/\u041A/g, 'K')
  //       .replace(/\u0415/g, 'E')
  //       .replace(/\u041D/g, 'N')
  //       .replace(/\u0413/g, 'G')
  //       .replace(/\u0428/g, 'SH')
  //       .replace(/\u0429/g, 'SCH')
  //       .replace(/\u0417/g, 'Z')
  //       .replace(/\u0425/g, 'H')
  //       .replace(/\u042A/g, '')
  //       .replace(/\u0451/g, 'yo')
  //       .replace(/\u0439/g, 'i')
  //       .replace(/\u0446/g, 'ts')
  //       .replace(/\u0443/g, 'u')
  //       .replace(/\u043A/g, 'k')
  //       .replace(/\u0435/g, 'e')
  //       .replace(/\u043D/g, 'n')
  //       .replace(/\u0433/g, 'g')
  //       .replace(/\u0448/g, 'sh')
  //       .replace(/\u0449/g, 'sch')
  //       .replace(/\u0437/g, 'z')
  //       .replace(/\u0445/g, 'h')
  //       .replace(/\u044A/g, "'")
  //       .replace(/\u0424/g, 'F')
  //       .replace(/\u042B/g, 'I')
  //       .replace(/\u0412/g, 'V')
  //       .replace(/\u0410/g, 'a')
  //       .replace(/\u041F/g, 'P')
  //       .replace(/\u0420/g, 'R')
  //       .replace(/\u041E/g, 'O')
  //       .replace(/\u041B/g, 'L')
  //       .replace(/\u0414/g, 'D')
  //       .replace(/\u0416/g, 'ZH')
  //       .replace(/\u042D/g, 'E')
  //       .replace(/\u0444/g, 'f')
  //       .replace(/\u044B/g, 'i')
  //       .replace(/\u0432/g, 'v')
  //       .replace(/\u0430/g, 'a')
  //       .replace(/\u043F/g, 'p')
  //       .replace(/\u0440/g, 'r')
  //       .replace(/\u043E/g, 'o')
  //       .replace(/\u043B/g, 'l')
  //       .replace(/\u0434/g, 'd')
  //       .replace(/\u0436/g, 'zh')
  //       .replace(/\u044D/g, 'e')
  //       .replace(/\u042F/g, 'Ya')
  //       .replace(/\u0427/g, 'CH')
  //       .replace(/\u0421/g, 'S')
  //       .replace(/\u041C/g, 'M')
  //       .replace(/\u0418/g, 'I')
  //       .replace(/\u0422/g, 'T')
  //       .replace(/\u042C/g, "'")
  //       .replace(/\u0411/g, 'B')
  //       .replace(/\u042E/g, 'YU')
  //       .replace(/\u044F/g, 'ya')
  //       .replace(/\u0447/g, 'ch')
  //       .replace(/\u0441/g, 's')
  //       .replace(/\u043C/g, 'm')
  //       .replace(/\u0438/g, 'i')
  //       .replace(/\u0442/g, 't')
  //       .replace(/\u044C/g, "'")
  //       .replace(/\u0431/g, 'b')
  //       .replace(/\u044E/g, 'yu');

  //   return text;
  // };

  returnTotalProducts() {
    let count = 0;
    count += this.addDelivery.items.filter(item => item.brand != '' || item.model_internal != '' || item.serial_internal != '').length;
    count += this.addDelivery.items.filter(item => item.serial_external != ''  || item.model_external != '').length;
    return count;
  }

  trackByFn(index, item) {
    return item.uuid;}

}
