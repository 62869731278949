import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-dealer',
  templateUrl: './add-dealer.component.html',
  styleUrls: ['./add-dealer.component.scss']
})
export class AddDealerComponent implements OnInit {
  addDealer:any = { name:"", city:"", address:"" }
  offices:any = [
    { city:"", address:"", phone:"" }
  ]

  constructor(private http:HttpClient, private router:Router) { }

  ngOnInit(): void {
  }

  checkForNewRow(idx:number){
    if(idx == this.offices.length - 1) {
      if(this.offices[idx].city != "" || this.offices[idx].address != "" || this.offices[idx].phone != ""){
        this.offices.push({ city:"", address:"" , phone:"" });
      }
    }
  }

  AddDealer() {
    let postObject = { offices:[] };
    for(let i = 0; i < this.offices.length; i++) {
      if(this.offices[i].city != "" || this.offices[i].address != "" || this.offices[i].phone != "") {
        postObject.offices.push(this.offices[i]);
      }
    }
    this.http.post("api/service.php?action=add_dealer&name=" + this.addDealer.name + "&city=" + this.addDealer.city + "&address=" + this.addDealer.address, postObject).subscribe((data) => {
      this.router.navigate(['dealers']);
    });
  }

}
