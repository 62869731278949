import { Component, OnInit } from '@angular/core';
import { LocalSystemService } from '../local-system.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-drone-service',
  templateUrl: './drone-service.component.html',
  styleUrls: ['./drone-service.component.css']
})
export class DroneServiceComponent implements OnInit {

  constructor(public system:LocalSystemService,private router:Router) { }

  ngOnInit(): void {
    if(this.system.returnRole() != 4 && !this.system.returnIsAdmin()) { 
      this.router.navigate(['/warranties']); 
    }
  }

}
