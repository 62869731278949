<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-storehouse'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете този склад ?</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="removeStorehouse()">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>

    <div class="modal-sm modal" *ngIf="modal.cntx == 'add-storehouse'">
        <div class="modal-ttl">Добавяне на склад</div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
            <span class="label">Наименование<span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
            <input type="text" placeholder="Въведете наименование на дилър" [(ngModel)]="modal.obj.name" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Адрес</span>
            <input type="text" placeholder="Въведете адрес на дилър" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <span class="label">Населено място</span>
            <input type="text" placeholder='Въведете населено място' [(ngModel)]="modal.obj.town" (focus)="modal.obj.selectingTown = true" (focusout)="deselectTown()" />
            <div class="dropdown-list" *ngIf="modal.obj.selectingTown && modal.obj.town.length > 0" style="top:63px;">
                <span *ngFor="let town of returnFilteredTown()" (click)="selectTown(town)">{{ town.name }}</span>
            </div>
        </div>
        <div class="btn-line">
            <div class="btn negative" (click)="closeModal()">Отказ</div>
            <div class="btn positive" (click)="addStorehouse()">Добавяне</div>
        </div>
    </div>

    <div class="modal-sm modal" *ngIf="modal.cntx == 'edit-storehouse'">
        <div class="modal-ttl">Промяна на склад</div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
            <span class="label">Наименование<span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
            <input type="text" placeholder="Въведете наименование на дилър" [(ngModel)]="modal.obj.name" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Адрес</span>
            <input type="text" placeholder="Въведете адрес на дилър" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <span class="label">Населено място</span>
            <input type="text" placeholder='Въведете населено място' [(ngModel)]="modal.obj.city" (focus)="modal.obj.selectingTown = true" (focusout)="deselectTown()" />
            <div class="dropdown-list" *ngIf="modal.obj.selectingTown && modal.obj.city.length > 0" style="top:63px;">
                <span *ngFor="let town of returnFilteredCity()" (click)="selectCity(town)">{{ town.name }}</span>
            </div>
        </div>
        <div class="btn-line">
            <div class="btn negative" (click)="closeModal()">Отказ</div>
            <div class="btn positive" (click)="editStorehouse()">Запис</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'adding-storehouse'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Добавяне на склад...</span>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'editing-storehouse'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Промяна на склад...</span>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'removing-storehouse'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Премахване на склад...</span>
    </div>
</div>

<div class="content">
    <span class="page-title">
        <span class="name">Складове</span>
        <span (click)="openModal('add-storehouse', { name:'', town:'', address:'' })" class="right-btn">Нов склад</span>
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-4 col-lg-4 text-center">Наименование</div>
            <div class="col-xl-3 col-lg-3 text-center">Град</div>
            <div class="col-xl-3 col-lg-3 text-center">Адрес</div>
            <div class="col-xl-2 col-lg-2 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row" *ngIf="storehouses.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма добавени складове</div>
        </div>
        <div class="table-wrapper-row" *ngFor="let storehouse of storehouses; let i = index">
            <div class="col-xl-4 col-lg-4 text-center">{{ storehouse.name }}</div>
            <div class="col-xl-3 col-lg-3 text-center">
                {{ storehouse.city }}
                <span *ngIf="storehouse.city == ''">-</span>
            </div>
            <div class="col-xl-3 col-lg-3 text-center text-overflow">
                <span>{{ storehouse.address }}</span>
                <span *ngIf="storehouse.address == ''">-</span>
            </div>
            <div class="col-xl-2 col-lg-2 text-center">
                <div class="action-btn" (click)="openModal('edit-storehouse', storehouse)"><i class="icon edit"></i> <span class="tooltip">Промяна</span></div>
                <div class="action-btn negative" (click)="openModal('remove-storehouse', { 'storehouse_id':storehouse.uid })"><i class="icon remove-white"></i> <span class="tooltip">Изтриване</span></div>
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>

            <!-- <span (click)="switchPage(i + 1)" *ngFor="let page of total_pages_array;let i= index" [ngClass]="{ 'active':i + 1 == currentPage }">{{ i + 1 }}</span> -->
        </div>
    </div>
</div>