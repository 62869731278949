<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-dealer'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете този дилър ?</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="removeDealer()">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>

    <div class="modal-sm modal" *ngIf="modal.cntx == 'add-dealer'">
        <div class="modal-ttl">Добавяне на дилър</div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
            <span class="label">Наименование<span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
            <input type="text" placeholder="Въведете наименование на дилър" [(ngModel)]="modal.obj.name" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Адрес</span>
            <input type="text" placeholder="Въведете адрес на дилър" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <span class="label">Населено място</span>
            <input type="text" placeholder='Въведете населено място' [(ngModel)]="modal.obj.town" (focus)="modal.obj.selectingTown = true" (focusout)="deselectTown()" />
            <div class="dropdown-list" *ngIf="modal.obj.selectingTown && modal.obj.town.length > 0" style="top:63px;">
                <span *ngFor="let town of returnFilteredTown()" (click)="selectTown(town)">{{ town.name }}</span>
            </div>
        </div>
        <div class="btn-line">
            <div class="btn negative" (click)="closeModal()">Отказ</div>
            <div class="btn positive" (click)="addDealer()">Добавяне</div>
        </div>
    </div>

    <div class="modal-sm modal" *ngIf="modal.cntx == 'edit-dealer'">
        <div class="modal-ttl">Промяна на дилър</div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
            <span class="label">Наименование<span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
            <input type="text" placeholder="Въведете наименование на дилър" [(ngModel)]="modal.obj.name" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Адрес</span>
            <input type="text" placeholder="Въведете адрес на дилър" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <span class="label">Населено място</span>
            <input type="text" placeholder='Въведете населено място' [(ngModel)]="modal.obj.city" (focus)="modal.obj.selectingTown = true" (focusout)="deselectTown()" />
            <div class="dropdown-list" *ngIf="modal.obj.selectingTown && modal.obj.city.length > 0" style="top:63px;">
                <span *ngFor="let town of returnFilteredCity()" (click)="selectCity(town)">{{ town.name }}</span>
            </div>
        </div>
        <div class="btn-line">
            <div class="btn negative" (click)="closeModal()">Отказ</div>
            <div class="btn positive" (click)="editDealer()">Запис</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'adding-dealer'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Добавяне на дилър...</span>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'editing-dealer'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Промяна на дилър...</span>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'removing-dealer'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Премахване на дилър...</span>
    </div>
</div>


<div class="content">
    <span class="page-title">
        <span class="name">Дилъри</span>

        <span (click)="openModal('add-dealer', {})" class="right-btn">Нов дилър</span>
        <div class="label-searched" (click)="clearSearched()" *ngIf="searched">
            <strong class="type" *ngIf="search_type == 'dealer_name'">Име на дилър:</strong>
            <span>{{ search }}</span>
            <i class="icon delete-label"></i>
        </div>
        <div class="search-title-box" *ngIf="!searched">
            <select [(ngModel)]="search_type">
                <option value="dealer_name">Име на дилър</option>
            </select>
            <input type="text" (keydown.enter)="searchDealers()" style="height:30px;width:240px;" [(ngModel)]="search" />
            <div class="round-btn" (click)="searchDealers()"><i class="icon search-white"></i></div>
        </div> 
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-3 col-lg-3 text-center">Дилър</div>
            <div class="col-xl-2 col-lg-2 text-center">Град</div>
            <div class="col-xl-2 col-lg-2 text-center">Адрес</div>
            <div class="col-xl-2 col-lg-2 text-center">Брой офиси</div>
            <div class="col-xl-1 col-lg-1 text-center">Регистриран</div>
            <div class="col-xl-2 col-lg-2 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row" *ngFor="let dealer of dealers; let i = index">
            <div class="col-xl-3 col-lg-3 text-center">{{ dealer.name }}</div>
            <div class="col-xl-2 col-lg-2 text-center">
                {{ dealer.city }}
                <span *ngIf="dealer.city == ''">-</span>
            </div>
            <div class="col-xl-2 col-lg-2 text-center text-overflow">
                <span>{{ dealer.address }}</span>
                <span *ngIf="dealer.address == ''">-</span>
            </div>
            <div class="col-xl-2 col-lg-2 text-center">{{ dealer.office_count }}</div>
            <div class="col-xl-1 col-lg-1 text-center">
                <span *ngIf="dealer.account_count > 0">Да</span>
                <span  *ngIf="dealer.account_count == 0">Не</span>
            </div>
            <div class="col-xl-2 col-lg-2 text-center">
                <div class="action-btn" (click)="openModal('edit-dealer', dealer)"><i class="icon edit"></i> <span class="tooltip">Промяна</span></div>
                <div class="action-btn negative" (click)="openModal('remove-dealer', { 'dealer_id':dealer.uid })"><i class="icon remove-white"></i></div>
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>

            <!-- <span (click)="switchPage(i + 1)" *ngFor="let page of total_pages_array;let i= index" [ngClass]="{ 'active':i + 1 == currentPage }">{{ i + 1 }}</span> -->
        </div>
    </div>
</div>