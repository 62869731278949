import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  brands:any;
  currentPage:any = 1;
  total_pages = 0;
  total_pages_array = [];

  modal:any = { open:false, cntx:"", obj:{} }

  constructor(private http:HttpClient) {
    this.getBrands();
  }

  ngOnInit(): void {
  }


  getBrands() {
    this.http.get("api/service.php?action=get_brands&page=" + this.currentPage).subscribe((data) => {
      this.brands = data['brands'];
      this.total_pages = data['total_pages'];
      this.total_pages_array = [];
      for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
    });
  }

  deleteBrand(uid) {
    this.http.get("api/service.php?action=remove_brand&uid=" + uid).subscribe((data) => {
      this.getBrands();
      this.closeModal();
    });
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeModal() { this.modal.open = false; }

  switchPage(page) {
    this.currentPage = page;
    this.getBrands();
  }
}
