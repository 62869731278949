import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalSystemService } from './local-system.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tempexWarranty';
  loginForm:any = { email:"", password:"" };
  error:any = false;
  modal:any = { open:true, cntx:'logging', obj:{} }


  constructor(private router:Router, public system:LocalSystemService) {
    this.system.setupMain(this);
  }

  errorOn() {
    this.error = true;
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeModal() { this.modal.open = false; }

  Logout() {
    this.closeModal();
    this.system.Logout();
  }

  ReturnIsInDeliveiresAndIsAllowed() {
    let allowed = false;

    if(this.returnRoute() == '/deliveries' || this.returnRoute() == '/sales' || this.returnRoute() == '/search-product') {
      if(this.system.returnIsAdmin() || this.system.returnRole() < 2  || this.system.returnRole() == 3) {
        allowed = true;
      }
    }

    return allowed;
  }

  ReturnIsInDeliveiresAndDealerAndIsAllowed() {
    let allowed = false;

    if(this.returnRoute() == '/deliveries-dealers') {
      if(this.system.returnRole() == 2) {
        allowed = true;
      }
    }

    return allowed;
  }

  returnRoute() {
    return this.router.url;
  }

  returnRouteExists(route) {
    if(this.router.url.indexOf(route) > -1) { return true; } else { return false; }
  }
}
