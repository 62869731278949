<div class="content">
    <span class="page-title">Нов потребител 
        <span class="right-btn" (click)="AddAccount()" [ngClass]="{ 'disabled':!validateEmail(addUser.email) }">Запис</span>
        <span routerLink="/users" class="right-btn negative">Отказ</span>
    </span>
    <div class="table-wrapper">
        <div class="category-title">Основна информация</div>
        <div class="col-xl-3 col-lg-3 input-row">
            <span class="label">Наименование</span>
            <input type="text" placeholder="Въведете наименование" [(ngModel)]="addUser.username" />
        </div>
        <div class="col-xl-3 col-lg-3 input-row">
            <span class="label">Имейл</span>
            <input type="text" placeholder="Въведете Имейл" [(ngModel)]="addUser.email" />
        </div>
        <div class="col-xl-3 col-lg-3 input-row">
            <span class="label">Тип потребител</span>
            <select [(ngModel)]="addUser.type" (change)="switchDealerType()" >
                <option value="1">Склад</option>
                <option value="2">Дилър</option>
                <option value="3">Администратор</option>
            </select>
        </div>
        <div class="col-xl-3 col-lg-3 input-row">
            <span class="label">Парола</span>
            <input type="text" placeholder="Въведете парола" [(ngModel)]="addUser.password" />
            <div class="after-action" (click)="generateRandomPassword(12)"><i class="icon random"></i></div>
        </div>

        <!-- <div class="category-title" *ngIf="addUser.type == '2'">Дилър</div>
        <div class="col-xl-12 col-lg-12 input-row"  *ngIf="addUser.type == '2'">
            <span class="label">Изберете дилър</span>
            <select [(ngModel)]="addUser.dealer_id">
                <option value="-1" [value]="'-1'">Няма избран</option>
                <option value="{{ dealer.uid }}" [value]="dealer.uid" *ngFor="let dealer of availableDealers">{{ dealer.name }}</option>
            </select>
        </div>

        <div class="category-title" *ngIf="addUser.type == '1'">Складове</div>
        <div class="col-xl-12 col-lg-12 input-row"  *ngIf="addUser.type == '1'">
            <span class="label">Изберете склад</span>
            <select [(ngModel)]="addUser.storehouse_id">
                <option value="-1" [value]="'-1'">Няма избран</option>
                <option value="{{ storehouse.uid }}" [value]="storehouse.uid" *ngFor="let storehouse of availableStorehouses">{{ storehouse.name }}</option>
            </select>
        </div> -->
    </div>
</div>