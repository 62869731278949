<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'loading-error-codes'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Зареждане на грешки...</span>
    </div>

    <div class="modal-lg" *ngIf="modal.cntx == 'add-error'">
        <div class="modal-ttl">Добавяне на грешка</div>
        <div class="col-xl-6 col-lg-6 input-row" style="margin-bottom:0px;">
            <span class="label">Код на грешка</span>
            <input type="text" [(ngModel)]="modal.obj.name" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;margin-bottom:0px;">
            <span class="label">Марка</span>
            <select [(ngModel)]="modal.obj.brand_id" (change)="SwitchErrorCodeBrand()">
                <option value="{{ brand.uid }}" *ngFor="let brand of all_brands">{{brand.name}}</option>
            </select>
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="margin-bottom:0px;">
            <span class="label">Битова климатична система</span>
            <div class="toggle" (click)="SwitchResidential()" [ngClass]="{ 'active':modal.obj.residential == 1 }">
                <div class="toggle-dot"></div>
                <span class="toggle-hint" *ngIf="modal.obj.residential == 1">Да</span>
                <span class="toggle-hint" *ngIf="modal.obj.residential == 0">Не</span>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="margin-bottom:0px;">
            <span class="label">Промишлена климатична система</span>
            <div class="toggle" (click)="SwitchCommercial()" [ngClass]="{ 'active':modal.obj.commercial == 1 }">
                <div class="toggle-dot"></div>
                <span class="toggle-hint" *ngIf="modal.obj.commercial == 1">Да</span>
                <span class="toggle-hint" *ngIf="modal.obj.commercial == 0">Не</span>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 input-row" style="float:left;margin-bottom:0px;" *ngIf="modal.obj.brand_id == 3 || modal.obj.brand_id == 10 || modal.obj.brand_id == 4">
            <span class="label">Operation LED 
                <i class="icon fe-operation error-code-icon" *ngIf="modal.obj.brand_id == 3"></i>
                <i class="icon midea-operation error-code-icon" *ngIf="modal.obj.brand_id != 3"></i>
            </span>
            <select [(ngModel)]="modal.obj.additional_data.operation">
                <option value="--" *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">--</option>
                <option value="1">1 мигане</option>
                <option value="2">2 мигания</option>
                <option value="3">3 мигания</option>
                <option value="4">4 мигания</option>
                <option value="5">5 мигания</option>
                <option value="6">6 мигания</option>
                <option value="7">7 мигания</option>
                <option value="8">8 мигания</option>
                <option value="9">9 мигания</option>
                <option value="10">10 мигания</option>
                <option value="11">11 мигания</option>
                <option value="12">12 мигания</option>
                <option value="13">13 мигания</option>
                <option value="14">14 мигания</option>
                <option value="15">15 мигания</option>
            </select>
        </div>
        <div class="col-xl-4 col-lg-4 input-row" style="float:left;margin-bottom:0px;" *ngIf="modal.obj.brand_id == 3 || modal.obj.brand_id == 10 || modal.obj.brand_id == 4">
            <span class="label">Timer LED 
                <i class="icon fe-timer error-code-icon"  *ngIf="modal.obj.brand_id == 3"></i>
                <i class="icon midea-timer error-code-icon"  *ngIf="modal.obj.brand_id != 3"></i>
            </span>
            <select [(ngModel)]="modal.obj.additional_data.timer">
                <option value="--" *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">--</option>
                <option value="1"  *ngIf="modal.obj.brand_id == 3">1 мигане</option>
                <option value="2"  *ngIf="modal.obj.brand_id == 3">2 мигания</option>
                <option value="3"  *ngIf="modal.obj.brand_id == 3">3 мигания</option>
                <option value="4"  *ngIf="modal.obj.brand_id == 3">4 мигания</option>
                <option value="5"  *ngIf="modal.obj.brand_id == 3">5 мигания</option>
                <option value="6"  *ngIf="modal.obj.brand_id == 3">6 мигания</option>
                <option value="7"  *ngIf="modal.obj.brand_id == 3">7 мигания</option>
                <option value="8"  *ngIf="modal.obj.brand_id == 3">8 мигания</option>
                <option value="9"  *ngIf="modal.obj.brand_id == 3">9 мигания</option>
                <option value="10"  *ngIf="modal.obj.brand_id == 3">10 мигания</option>
                <option value="11"  *ngIf="modal.obj.brand_id == 3">11 мигания</option>
                <option value="12"  *ngIf="modal.obj.brand_id == 3">12 мигания</option>
                <option value="13"  *ngIf="modal.obj.brand_id == 3">13 мигания</option>
                <option value="14"  *ngIf="modal.obj.brand_id == 3">14 мигания</option>
                <option value="15"  *ngIf="modal.obj.brand_id == 3">15 мигания</option>
                <option value="Мига"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Мига</option>
                <option value="Мига бързо"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Мига бързо</option>
                <option value="Мига бавно"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Мига бавно</option>
                <option value="Свети"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Свети</option>
                <option value="Не свети"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Не свети</option>
            </select>
        </div>
        <div class="col-xl-4 col-lg-4 input-row" style="float:left;margin-bottom:0px;" *ngIf="modal.obj.brand_id == 3">
            <span class="label">Economy LED <i class="icon fe-economy error-code-icon"></i></span>
            <select [(ngModel)]="modal.obj.additional_data.economy">
                <option>Непрекъснато</option>
            </select>
        </div>
        <div class="col-xl-4 col-lg-4 input-row" style="float:left;margin-bottom:0px;" *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">
            <span class="label">Display LED <i class="icon midea-display error-code-icon"></i></span>
            <input type="text" class="font-digital-7" style="letter-spacing: 1px;
    font-size: 18px;" [(ngModel)]="modal.obj.additional_data.display" />
        </div>
        
        <div class="col-xl-12 col-lg-12 input-row" style="margin-bottom:0px;">
            <span class="label">Описание на грешка</span>
            <textarea [(ngModel)]="modal.obj.description"></textarea>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="margin-bottom:0px;">
            <span class="label">Решение на грешка</span>
            <textarea [(ngModel)]="modal.obj.solution"></textarea>
        </div>
        <div class="btn-line">
            <div class="btn back" (click)="closeModal()">Отказ</div>
            <div class="btn positive" (click)="AddErrorCode()">Добавяне</div>
        </div>
    </div>
    <div class="modal-lg" *ngIf="modal.cntx == 'edit-error'">
        <div class="modal-ttl">Промяна на грешка</div>
        <div class="col-xl-6 col-lg-6 input-row" style="margin-bottom:0px;">
            <span class="label">Код на грешка</span>
            <input type="text" [(ngModel)]="modal.obj.name" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;margin-bottom:0px;">
            <span class="label">Марка</span>
            <select [(ngModel)]="modal.obj.brand_id" (change)="SwitchErrorCodeBrand()">
                <option value="{{ brand.uid }}" *ngFor="let brand of all_brands">{{brand.name}}</option>
            </select>
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="margin-bottom:0px;">
            <span class="label">Битова климатична система</span>
            <div class="toggle" (click)="SwitchResidential()" [ngClass]="{ 'active':modal.obj.residential == 1 }">
                <div class="toggle-dot"></div>
                <span class="toggle-hint" *ngIf="modal.obj.residential == 1">Да</span>
                <span class="toggle-hint" *ngIf="modal.obj.residential == 0">Не</span>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="margin-bottom:0px;">
            <span class="label">Промишлена климатична система</span>
            <div class="toggle" (click)="SwitchCommercial()" [ngClass]="{ 'active':modal.obj.commercial == 1 }">
                <div class="toggle-dot"></div>
                <span class="toggle-hint" *ngIf="modal.obj.commercial == 1">Да</span>
                <span class="toggle-hint" *ngIf="modal.obj.commercial == 0">Не</span>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 input-row" style="float:left;margin-bottom:0px;" *ngIf="modal.obj.brand_id == 3 || modal.obj.brand_id == 10 || modal.obj.brand_id == 4">
            <span class="label">Operation LED 
                <i class="icon fe-operation error-code-icon" *ngIf="modal.obj.brand_id == 3"></i>
                <i class="icon midea-operation error-code-icon" *ngIf="modal.obj.brand_id != 3"></i>
            </span>
            <select [(ngModel)]="modal.obj.additional_data.operation">
                <option value="--" *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">--</option>
                <option value="1">1 мигане</option>
                <option value="2">2 мигания</option>
                <option value="3">3 мигания</option>
                <option value="4">4 мигания</option>
                <option value="5">5 мигания</option>
                <option value="6">6 мигания</option>
                <option value="7">7 мигания</option>
                <option value="8">8 мигания</option>
                <option value="9">9 мигания</option>
                <option value="10">10 мигания</option>
                <option value="11">11 мигания</option>
                <option value="12">12 мигания</option>
                <option value="13">13 мигания</option>
                <option value="14">14 мигания</option>
                <option value="15">15 мигания</option>
            </select>
        </div>
        <div class="col-xl-4 col-lg-4 input-row" style="float:left;margin-bottom:0px;" *ngIf="modal.obj.brand_id == 3 || modal.obj.brand_id == 10 || modal.obj.brand_id == 4">
            <span class="label">Timer LED 
                <i class="icon fe-timer error-code-icon"  *ngIf="modal.obj.brand_id == 3"></i>
                <i class="icon midea-timer error-code-icon"  *ngIf="modal.obj.brand_id != 3"></i>
            </span>
            <select [(ngModel)]="modal.obj.additional_data.timer">
                <option value="--" *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">--</option>
                <option value="1" *ngIf="modal.obj.brand_id == 3">1 мигане</option>
                <option value="2" *ngIf="modal.obj.brand_id == 3">2 мигания</option>
                <option value="3" *ngIf="modal.obj.brand_id == 3">3 мигания</option>
                <option value="4" *ngIf="modal.obj.brand_id == 3">4 мигания</option>
                <option value="5" *ngIf="modal.obj.brand_id == 3">5 мигания</option>
                <option value="6" *ngIf="modal.obj.brand_id == 3">6 мигания</option>
                <option value="7" *ngIf="modal.obj.brand_id == 3">7 мигания</option>
                <option value="8" *ngIf="modal.obj.brand_id == 3">8 мигания</option>
                <option value="9" *ngIf="modal.obj.brand_id == 3">9 мигания</option>
                <option value="10" *ngIf="modal.obj.brand_id == 3">10 мигания</option>
                <option value="11" *ngIf="modal.obj.brand_id == 3">11 мигания</option>
                <option value="12" *ngIf="modal.obj.brand_id == 3">12 мигания</option>
                <option value="13" *ngIf="modal.obj.brand_id == 3">13 мигания</option>
                <option value="14" *ngIf="modal.obj.brand_id == 3">14 мигания</option>
                <option value="15" *ngIf="modal.obj.brand_id == 3">15 мигания</option>
                <option value="Мига"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Мига</option>
                <option value="Мига бързо"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Мига бързо</option>
                <option value="Мига бавно"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Мига бавно</option>
                <option value="Свети"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Свети</option>
                <option value="Не свети"  *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">Не свети</option>
            </select>
        </div>
        <div class="col-xl-4 col-lg-4 input-row" style="float:left;margin-bottom:0px;" *ngIf="modal.obj.brand_id == 3">
            <span class="label">Economy LED <i class="icon fe-economy error-code-icon"></i></span>
            <select [(ngModel)]="modal.obj.additional_data.economy">
                <option>Непрекъснато</option>
            </select>
        </div>
        <div class="col-xl-4 col-lg-4 input-row" style="float:left;margin-bottom:0px;" *ngIf="modal.obj.brand_id == 10 || modal.obj.brand_id == 4">
            <span class="label">Display LED <i class="icon midea-display error-code-icon"></i></span>
            <input type="text" class="font-digital-7" style="letter-spacing: 1px;
    font-size: 18px;" [(ngModel)]="modal.obj.additional_data.display" />
        </div>

        <div class="col-xl-12 col-lg-12 input-row" style="margin-bottom:0px;">
            <span class="label">Описание на грешка</span>
            <textarea [(ngModel)]="modal.obj.description"></textarea>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="margin-bottom:0px;">
            <span class="label">Решение на грешка</span>
            <textarea [(ngModel)]="modal.obj.solution"></textarea>
        </div>
        <div class="btn-line">
            <div class="btn back" (click)="closeModal()">Отказ</div>
            <div class="btn positive" (click)="EditErrorCode()">Запис</div>
        </div>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'remove-error'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете грешка "{{ modal.obj.name }}"</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="RemoveErrorCode()">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>
</div>
<div class="content">
   <span class="page-title">
        <span class="name" [ngClass]="{ 'underlink':chosenBrand != '' }" (click)="clearBrand()">Грешки</span>
        <span class="name-splitter" *ngIf="chosenBrand != ''">-></span>
        <span class="subname" *ngIf="chosenBrand != ''" style="float:left;">{{ chosenBrand }}</span>

        <div class="label-searched" (click)="clearSearched()" *ngIf="searched">
            <strong class="type" *ngIf="search_type == 'client_name'">Грешка:</strong>
            <span>{{ search }}</span>
            <i class="icon delete-label"></i>
        </div>
        <div class="search-title-box" *ngIf="!searched && chosenBrand != ''">
            <select [(ngModel)]="search_type">
                <option value="error">Грешка</option>
            </select>
            <input type="text" style="height:30px;width:240px;" (keydown.enter)="searchCodes()" [(ngModel)]="search" />
            <div class="round-btn" (click)="searchCodes()"><i class="icon search-white"></i></div>
        </div> 

        <span (click)="openModal('add-error', { 'name':'', 'description':'', 'solution':'', 'brand_id':brands[0].uid, additional_data:{}, 'residential':1, 'commercial':0 })" class="right-btn" *ngIf="localSystem.returnIsAdmin()">Въвеждане на грешка</span>
    </span>
    <div class="hint-card" *ngIf="chosenBrand == ''">
        Ако не намирате търсеният от Вас файл/информация, моля свържете се с нашия сервиз: ‎‎0888 457 688 или <a href="mailto:aircon.service@tempexbg.com">aircon.service@tempexbg.com</a>
    </div>
    <div *ngIf="chosenBrand == ''">
        <div class="selectable-card" *ngFor="let brand of brands" (click)="selectBrand(brand.name, brand.uid)">{{ brand.name }}</div>
    </div>


    <div class="table-wrapper" *ngIf="chosenBrand != ''">
        <div class="table-wrapper-header-row">
            <div class="col-xl-3 col-lg-3 text-center">Име на грешка</div>
            <div class="col-xl-2 col-lg-2 text-center">Допълнително</div>
            <div class="col-xl-6 col-lg-6 text-center">Кратко описание</div>
            <div class="col-xl-1 col-lg-1 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row selectable" *ngIf="error_codes.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма резултати за тази марка</div>          
        </div>
        <div class="table-wrapper-row selectable" *ngFor="let error_code of error_codes; let i = index">
            <div class="col-xl-3 col-lg-3 text-center">
                {{ error_code.name }}
            </div>
            <div class="col-xl-3 col-lg-3 text-center">
                <span class="error-label" *ngIf="error_code.brand_id == 3"><i class="icon fe-operation error-code-icon"></i> <span>{{ error_code.additional_data.operation }}</span></span>
                <span class="error-label" *ngIf="error_code.brand_id == 3"><i class="icon fe-timer error-code-icon"></i> <span>{{ error_code.additional_data.timer }}</span></span>
                <span class="error-label" *ngIf="error_code.brand_id == 3"><i class="icon fe-economy error-code-icon"></i> <span>{{ error_code.additional_data.economy }}</span></span>

                <span class="error-label" *ngIf="error_code.brand_id == 4 || error_code.brand_id == 10"><i class="icon midea-operation error-code-icon"></i> <span>{{ error_code.additional_data.operation }}</span></span>
                <span class="error-label" *ngIf="error_code.brand_id == 4 || error_code.brand_id == 10"><i class="icon midea-timer error-code-icon"></i> <span>{{ error_code.additional_data.timer }}</span></span>
                <span class="error-label font-digital-7" *ngIf="error_code.brand_id == 4 || error_code.brand_id == 10"><i class="icon midea-display error-code-icon"></i> <span style="font-size:14px;">{{ error_code.additional_data.display }}</span></span>
            </div>
            <div class="col-xl-5 col-lg-5 text-center text-overflow">{{ error_code.description }}</div>
            <div class="col-xl-1 col-lg-1 text-center" *ngIf="localSystem.returnIsAdmin()">
                <div class="action-btn" (click)="openModal('edit-error', ReturnShadowCopy(error_code))"><i class="icon edit"></i></div>
                <div class="action-btn negative" (click)="openModal('remove-error', error_code)"><i class="icon remove-white"></i></div>
                <!-- <div class="action-btn negative" (click)="remove"><i class="icon remove-white"></i></div> -->
            </div>              
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>
        </div>
    </div>
</div>