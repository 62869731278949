<div class="content">
    <span class="page-title">Добавяне на склад <span class="right-btn" (click)="AddStorehouse()">Запис</span></span>
    <div class="table-wrapper">
        <div class="category-title">Основна информация</div>
        <div class="col-xl-4 col-lg-4 input-row">
            <span class="label">Име на склад</span>
            <input type="text" placeholder="Въведете наименование" [(ngModel)]="addStorehouse.name" />
        </div>
        <div class="col-xl-4 col-lg-4 input-row">
            <span class="label">Град</span>
            <input type="text" placeholder="Въведете наименование"  [(ngModel)]="addStorehouse.city"  />
        </div>
        <div class="col-xl-4 col-lg-4 input-row">
            <span class="label">Адрес</span>
            <input type="text" placeholder="Въведете наименование"  [(ngModel)]="addStorehouse.address" />
        </div>
    </div>
</div>