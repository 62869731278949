<div class="modal-layer" [ngClass]="{ 'active':searching }">
    <div class="backdrop"></div>
    <div class="alert">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Търсене за продукт...</span>
    </div>
</div>


<div class="content">
    <span class="page-title">Движение на продукт</span>
    <div class="search-box">
        <input type="text" (keyup)="CheckSerial()" (keyup.enter)="Search()" placeholder="Въведете сериен номер на продукт" [(ngModel)]="searchable" />
        <div class="btn" (click)="Search()">Търсене</div>
    </div>

    <div class="table-wrapper" *ngIf="searched">
        <div class="table-wrapper-header-row">
            <div class="col-xl-6 col-lg-6 text-center">Събитие</div>
            <div class="col-xl-3 col-lg-3 text-center">Дата на събитие</div>
            <div class="col-xl-3 col-lg-3 text-center"></div>
        </div>
        <div class="table-wrapper-row" *ngIf="events.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">
                Няма събития за този продукт
            </div>
        </div>
        <div class="table-wrapper-row" *ngFor="let event of events; let i = index">
            <div class="col-xl-6 col-lg-6">

                <span style="font-weight:600;margin-left:15px;">{{ i + 1 }}. </span>
                <span *ngIf="event.product_event == '1'">Доставено в <strong>"{{ event.action_to }}"</strong></span>
                <span *ngIf="event.product_event == '2'">Продадено от <strong>"{{ event.action_from }}"</strong> на <strong>"{{ event.action_to }}"</strong></span>
                <span *ngIf="event.product_event == '3'">Преместено от <strong>"{{ event.action_from }}"</strong> в <strong>"{{ event.action_to }}"</strong></span>
                <span *ngIf="event.product_event == '4'">Издадена гаранционна карта от <strong>"{{ event.action_from }}"</strong> на <strong>"{{ event.action_to }}"</strong></span>
                <span *ngIf="event.product_event == '5'">Стока върната в склад <strong>"{{ event.action_to }}"</strong></span>
            </div>
            <div class="col-xl-3 col-lg-3 text-center">{{ returnDateFormat(event.dtime) }}</div>
            <div class="col-xl-3 col-lg-3 text-center">-</div>
        </div>
    </div>
</div>