<div class="content">
    <span class="page-title">Промяна на марка 
        <span class="right-btn" (click)="EditBrand()">Запис</span>
        <span routerLink="/brands" class="right-btn negative">Отказ</span>
    </span>
    <div class="table-wrapper">
        <div class="category-title">Основна информация</div>
        <div class="col-xl-6 col-lg-6 input-row">
            <span class="label">Наименование</span>
            <input type="text" placeholder="Въведете наименование" [(ngModel)]="editBrand.name" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row">
            <span class="label">Гаранционен срок (месеца)</span>
            <input type="number" placeholder="Въведете гаранционен срок" [(ngModel)]="editBrand.duration" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Гаранционно поле</span>
            <input type="text" placeholder="Гаранционно поле" [(ngModel)]="editBrand.warranty_field" />
        </div>
    </div>
</div>