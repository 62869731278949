import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent implements OnInit {
  addDocument:any = { name:"", brand:0, files:[] };
  availableBrands:any = [];

  file:any = { name:"", type:"", data:"" }

  constructor(private http:HttpClient, private router:Router) {}

  ngOnInit(): void {
    this.getBrands();
  }

  AddDocument(){
    let params = { 'file':this.file };
    this.http.post("api/service.php?action=add_document&name="+ this.addDocument.name +"&brand_id=" + this.addDocument.brand, params).subscribe(
    response => { 
      this.router.navigate(['documentation']);
    },
    error => {  });
  }

  openFileManagement(event: any){
    event.preventDefault();

    var fileManager = document.getElementById("document-upload");
    fileManager.click();
  }

  onFileChange(event: any) {
    let files = event.target.files;
    for(let i = 0; i < files.length; i++) {
      this.getBase64(files[i]).then(
        data => this.file = { name:files[i].name, 'data':data, type:files[i].type }
      );
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  getBrands() {
    this.http.get("api/service.php?action=get_brands").subscribe((data) => {
      this.availableBrands = data['brands'];
    });
  }
  

  returnFileType(file){
    if(file.type.indexOf("document") > -1 || file.type.indexOf('msword') > -1) { return 'doc' }
    else if(file.type.indexOf('png') > -1 || file.type.indexOf('jpeg') > -1) { return 'picture'; }
    else if(file.type.indexOf('pdf') > -1) { return 'pdf'; }
    else if(file.type.indexOf('ms-excel') > -1 || file.type.indexOf('spreadsheetml.sheet') > -1) { return 'sheet'; }
    return '';
  }
}
