import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-storehouse',
  templateUrl: './add-storehouse.component.html',
  styleUrls: ['./add-storehouse.component.scss']
})
export class AddStorehouseComponent implements OnInit {
  addStorehouse:any = { name:"", city:"", address:"" };

  constructor(private http:HttpClient, private router:Router) { }

  ngOnInit(): void {
  }

  AddStorehouse() {
    this.http.get("api/service.php?action=add_storehouse&name=" + this.addStorehouse.name + "&city=" + this.addStorehouse.city + "&address=" + this.addStorehouse.address).subscribe((data) => {
      this.router.navigate(['storehouses']);
    });
  }

}
