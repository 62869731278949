import { HttpClient } from '@angular/common/http';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LocalSystemService } from '../local-system.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  addUser:any = { username:"", password:"", email:"", type:"1", dealer_id:"-1", storehouse_id:"-1" };
  availableDealers:any = [];
  availableStorehouses:any = [];


  @HostListener('window:beforeunload')
  onBeforeUnload() {
      return confirm("Сигурни ли сте, че искате да излезете в процес на добавяне ?");
  }

  constructor(private http:HttpClient, private router:Router, private system:LocalSystemService) {
    if(this.system.returnRole() > 1 && !this.system.returnIsAdmin()) { this.router.navigate(['/warranties']); }
  }

  ngOnInit(): void {
    // this.getAvailableDealers();
    // this.getAvailableStorehouse();
  }

  getAvailableDealers() {
    this.http.get("api/service.php?action=get_dealers").subscribe((data) => {
      this.availableDealers = data['dealers'];
    });
  }

  getAvailableStorehouse() {
    this.http.get("api/service.php?action=get_storehouses").subscribe((data) => {
      this.availableStorehouses = data['storehouses'];
    });
  }

  AddAccount() {
    let url = "api/service.php?action=add_account";
    url += "&username=" + this.addUser.username;
    url += "&password=" + this.addUser.password;
    url += "&email=" + this.addUser.email;
    url += "&type=" + this.addUser.type;
    url += "&dealer_id=" + this.addUser.dealer_id;
    url += "&storehouse_id=" + this.addUser.storehouse_id;
    this.http.get(url).subscribe((data) => {
      this.router.navigate(['users']);
    });
  }

  switchDealerType() {
    if(this.addUser.type == "1") {  this.addUser.dealer_id = "-1"; }
    else if(this.addUser.type == "2") { this.addUser.storehouse_id = "-1"; } else {
      this.addUser.dealer_id = "-1";
      this.addUser.storehouse_id = "-1";
    }
  }

  generateRandomPassword(size){
    this.addUser.password = "";
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (var i=0;i<size;i++) {
      var c = Math.floor(Math.random()*chars.length + 1);
      this.addUser.password += chars.charAt(c)
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}
