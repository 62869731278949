import { Component, NgZone, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient } from '@angular/common/http';
import { LocalSystemService } from '../local-system.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  chosenSection:any = "";
  offices:any = [];
  total_pages:any;
  total_pages_array = [];
  currentPage:any = 1;
  importHTML:any = "";

  warranty_text:any = [];

  addingWarranty:any = false;
  addWarranty:any = { ttl:"", layout:"", expanded:false };

  editingWarranty:any = false;
  editWarranty:any = { uid:-999 };

  labels:any = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '100px',
      placeholder: 'Enter text here...',
  };

  modal:any = { open:false, cntx:'', obj:{} };

  constructor(private http:HttpClient, public system:LocalSystemService, private ngZone:NgZone) {
    this.getOffices();
  }

  ngOnInit(): void {
  }

  goHome() { this.chosenSection = ''; }
  goSection(section) { 
    this.chosenSection = section; 
    if(section == "warranties") {
      this.getWarrantyText();
      this.openModal('loading-warranties', {});
    }
  }

  getOffices() {
    this.http.get("api/service.php?action=get_offices&page=" + this.currentPage).subscribe((data) => {
      this.offices = data['offices'];
      this.total_pages = data['total_pages'];
      this.total_pages_array = [];
      for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
    });
  }

  switchPage(page) {
    this.currentPage = page;
    this.getOffices();
  }

  addOffice() {
    let params = { 'address':this.modal.obj.address, 'phone':this.modal.obj.phone }
    this.http.post("api/service.php?action=add_office", params).subscribe((data) => {
      this.getOffices();
      this.closeModal();
    });
  }

  editOffice() {
    let params = { 'address':this.modal.obj.address, 'phone':this.modal.obj.phone, 'uid':this.modal.obj.uid }
    this.http.post("api/service.php?action=edit_office", params).subscribe((data) => {
      this.getOffices();
      this.closeModal();
    });
  }

  deleteOffice(uid) {
    let params = { 'uid':uid }
    this.http.post("api/service.php?action=remove_office", params).subscribe((data) => {
      this.getOffices();
      this.closeModal();
    });
  }

  AddWarrantyText() {
    let postObject = { "layout":this.encodeHTMLEntities(this.addWarranty.layout), title:this.addWarranty.ttl };
    this.http.post("api/service.php?action=add_warranty_text", postObject).subscribe((data) => {
      this.addWarranty = { ttl:"", layout:"", expanded:false };
      this.addingWarranty = false;
      this.getWarrantyText();
    });
  }

  getWarrantyText() {
    this.http.get("api/service.php?action=get_warranty_text").subscribe((data) => {
      this.warranty_text = data['warranty_text'];
      for(let i = 0; i < this.warranty_text.length; i++) {
        this.warranty_text[i].layout = this.decodeHTMLEntities(this.warranty_text[i].layout);
      }
      this.closeModal();
      this.ngZone.run(()=>{});
    });
  }

  loadEditWarrantyText(warranty_text) {
    this.editWarranty = JSON.parse(JSON.stringify(warranty_text));
    this.editingWarranty = true;
  }

  SetDefaultWarrantyText() {
    this.http.get("api/service.php?action=set_default_warranty_text&uid=" + this.modal.obj.uid).subscribe((data) => {
      this.getWarrantyText();
      this.ngZone.run(()=>{});
    });
  }

  RemoveWarrantyText() {
    this.http.get("api/service.php?action=remove_warranty_text&uid=" + this.modal.obj.uid + "&default=" + this.modal.obj.default).subscribe((data) => {
      this.getWarrantyText();
      this.ngZone.run(()=>{});
    });
  }

  EditWarrantyText() {
    let postObject = { "layout":this.encodeHTMLEntities(this.editWarranty.layout), title:this.editWarranty.ttl };
    this.http.post("api/service.php?action=edit_warranty_text&uid=" + this.editWarranty.uid, postObject).subscribe((data) => {
      this.editingWarranty = false;
      this.editWarranty = { uid:-999 };
      this.getWarrantyText();
    });
  }

  cancelAddWarrantyText() {
    this.addingWarranty = false;
    this.addWarranty = { ttl:"", layout:"", expanded:false };
  }

  cancelEditWarrantyText() {
    this.editingWarranty = false;
    this.editWarranty = { uid:-999 };
  }

  changePassword() {
    this.http.get("api/service.php?action=change_password&old_password=" + this.modal.obj.old_password + "&new_password=" + this.modal.obj.new_password).subscribe((data) => {
      if(data['success']) {
        this.closeModal();
      } else {
        this.modal.obj.error = true;
      }
    });
  }

  switchSeenDealer(help) {
    if(help.seen_dealer == 1) { help.seen_dealer = 0; }
    else { help.seen_dealer = 1; }
  }

  switchSeenStorehouse(help) {
    if(help.seen_storehouse == 1) { help.seen_storehouse = 0; }
    else { help.seen_storehouse = 1; }
  }

  decodeHTMLEntities(text) {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (var i = 0, max = entities.length; i < max; ++i) 
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return text;
  }

  encodeHTMLEntities(text) {
    var entities = [
        ['&','amp'],
        ['\'','apos'],
        ['\'','#x27'],
        ['/','#x2F'],
        ['\'','#39'],
        ['/','#47'],
        ['<','lt'],
        ['>','gt'],
        [' ','nbsp'],
        ['"','quot']
    ];

    for (var i = 0, max = entities.length; i < max; ++i) 
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return text;
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj } }
  closeModal() { this.modal.open = false; }

  PrintLabels() {
    window.print();
  }

  openFileManagement(event: any){
    event.preventDefault();

    var fileManager = document.getElementById("document-upload");
    fileManager.click();
  }

  onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_html(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data[0][0]); // Data will be logged in array format containing objects
      // console.log(data[1][0]);
      this.importHTML = data;
      setTimeout(()=>{
        let data_table = this.tableToArray(document.getElementById('import-html').getElementsByTagName('table')[0]);
        let real_counter = 0;
        let start = false;
        let orders = [];
        for(var ci = 0; ci < data_table.length; ci++) {
          if(data_table[ci][1] == 'Име на клиент') {
            start = true;
            continue;
          }
          if(start) {
            if(data_table[ci].length == 8) {
              if(data_table[ci][0] == '') {
                orders[real_counter - 1]['count'] += parseInt(data_table[ci][3])
              } else {
                orders.push({ 'num':data_table[ci][0], 'client':data_table[ci][1], 'city':data_table[ci][4], 'count':data_table[ci][3], 'address':data_table[ci][5], 'phone':data_table[ci][6] });
                real_counter += 1;
              }
            } else {
              orders[real_counter - 1]['count'] += parseInt(data_table[ci][1])
            }
            
          }
        }
        this.labels = orders;
        console.log(orders);
      }, 500);
      // console.log(XLSX.utils.decode_row('B6')); 
      // for(var i = 0; i < data.length; i++) {
      //   if(data[i]['Сер ном външно'] === undefined && data[i]['"Сер ном вътрешно '] === undefined) { data.splice(i, 1); }
      // }
      // let comment = "";
      // if(this.system.returnIsAdmin()) { comment = "Разтоварени в Пловдив"; }
      // this.openModal('importing-delivery', { 'data_rows':data, 'name':'', document_number:0, container_number:"", date_add:new Date(), 'comment':comment, step:1, current_delivery_date:new Date(), 'data':data })
      // this.getAvailableId();
    };
  }

  tableToArray(table) {
    var result = []
    var rows = table.rows;
    var cells, t;
  
    // Iterate over rows
    for (var i=0, iLen=rows.length; i<iLen; i++) {
      cells = rows[i].cells;
      t = [];
  
      // Iterate over cells
      for (var j=0, jLen=cells.length; j<jLen; j++) {
        t.push(cells[j].textContent);
      }
      result.push(t);
    }
    return result; 
  }

  returnShadowCopy(obj) { return JSON.parse(JSON.stringify(obj)); }
}
