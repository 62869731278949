import { Component, NgZone, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient } from '@angular/common/http';
import { LocalSystemService } from '../local-system.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.css']
})
export class OtherComponent implements OnInit {
  chosenSection:any = "";
  offices:any = [];
  total_pages:any;
  total_pages_array = [];
  currentPage:any = 1;
  importHTML:any = "";
  importHTMLFirst:any = "";
  importHTMLSecond:any = "";
  importHTMLBurgas:any = "";
  importHTMLAcList:any = "";

  print_contracts:any = false;
  contracts:any = [];
  warranties:any = [];
  print_warranties:any = false;
  print_labels:any = false;

  protocols_sofia:any = [];
  print_protocols_sofia:any = false;

  import_contract = [];

  warranty_text:any = [];

  addingWarranty:any = false;
  addWarranty:any = { ttl:"", layout:"", expanded:false };

  editingWarranty:any = false;
  editWarranty:any = { uid:-999 };

  labels:any = [];
  protocols:any = [];
  print_protocols:any = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '300px',
      placeholder: 'Enter text here...',
  };

  modal:any = { open:false, cntx:'', obj:{} };

  constructor(private http:HttpClient, public system:LocalSystemService, private ngZone:NgZone) {
    // this.getOffices();
  }

  ngOnInit(): void {
  }

  goHome() { this.chosenSection = ''; }
  goSection(section) { 
    this.chosenSection = section; 
    if(section == "warranties") {
      this.getWarrantyText();
      this.openModal('loading-warranties', {});
    }
  }

  getOffices() {
    this.http.get("api/service.php?action=get_offices&page=" + this.currentPage).subscribe((data) => {
      this.offices = data['offices'];
      this.total_pages = data['total_pages'];
      this.total_pages_array = [];
      for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
    });
  }

  switchPage(page) {
    this.currentPage = page;
    this.getOffices();
  }

  addOffice() {
    let params = { 'address':this.modal.obj.address, 'phone':this.modal.obj.phone }
    this.http.post("api/service.php?action=add_office", params).subscribe((data) => {
      this.getOffices();
      this.closeModal();
    });
  }

  editOffice() {
    let params = { 'address':this.modal.obj.address, 'phone':this.modal.obj.phone, 'uid':this.modal.obj.uid }
    this.http.post("api/service.php?action=edit_office", params).subscribe((data) => {
      this.getOffices();
      this.closeModal();
    });
  }

  deleteOffice(uid) {
    let params = { 'uid':uid }
    this.http.post("api/service.php?action=remove_office", params).subscribe((data) => {
      this.getOffices();
      this.closeModal();
    });
  }

  AddWarrantyText() {
    let postObject = { "layout":this.encodeHTMLEntities(this.addWarranty.layout), title:this.addWarranty.ttl };
    this.http.post("api/service.php?action=add_warranty_text", postObject).subscribe((data) => {
      this.addWarranty = { ttl:"", layout:"", expanded:false };
      this.addingWarranty = false;
      this.getWarrantyText();
    });
  }

  getWarrantyText() {
    this.http.get("api/service.php?action=get_warranty_text").subscribe((data) => {
      this.warranty_text = data['warranty_text'];
      for(let i = 0; i < this.warranty_text.length; i++) {
        this.warranty_text[i].layout = this.decodeHTMLEntities(this.warranty_text[i].layout);
      }
      this.closeModal();
      this.ngZone.run(()=>{});
    });
  }

  loadEditWarrantyText(warranty_text) {
    this.editWarranty = JSON.parse(JSON.stringify(warranty_text));
    this.editingWarranty = true;
  }

  SetDefaultWarrantyText() {
    this.http.get("api/service.php?action=set_default_warranty_text&uid=" + this.modal.obj.uid).subscribe((data) => {
      this.getWarrantyText();
      this.ngZone.run(()=>{});
    });
  }

  RemoveWarrantyText() {
    this.http.get("api/service.php?action=remove_warranty_text&uid=" + this.modal.obj.uid + "&default=" + this.modal.obj.default).subscribe((data) => {
      this.getWarrantyText();
      this.ngZone.run(()=>{});
    });
  }

  EditWarrantyText() {
    let postObject = { "layout":this.encodeHTMLEntities(this.editWarranty.layout), title:this.editWarranty.ttl };
    this.http.post("api/service.php?action=edit_warranty_text&uid=" + this.editWarranty.uid, postObject).subscribe((data) => {
      this.editingWarranty = false;
      this.editWarranty = { uid:-999 };
      this.getWarrantyText();
    });
  }

  cancelAddWarrantyText() {
    this.addingWarranty = false;
    this.addWarranty = { ttl:"", layout:"", expanded:false };
  }

  cancelEditWarrantyText() {
    this.editingWarranty = false;
    this.editWarranty = { uid:-999 };
  }

  changePassword() {
    this.http.get("api/service.php?action=change_password&old_password=" + this.modal.obj.old_password + "&new_password=" + this.modal.obj.new_password).subscribe((data) => {
      if(data['success']) {
        this.closeModal();
      } else {
        this.modal.obj.error = true;
      }
    });
  }

  switchSeenDealer(help) {
    if(help.seen_dealer == 1) { help.seen_dealer = 0; }
    else { help.seen_dealer = 1; }
  }

  switchSeenStorehouse(help) {
    if(help.seen_storehouse == 1) { help.seen_storehouse = 0; }
    else { help.seen_storehouse = 1; }
  }

  decodeHTMLEntities(text) {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (var i = 0, max = entities.length; i < max; ++i) 
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return text;
  }

  encodeHTMLEntities(text) {
    var entities = [
        ['&','amp'],
        ['\'','apos'],
        ['\'','#x27'],
        ['/','#x2F'],
        ['\'','#39'],
        ['/','#47'],
        ['<','lt'],
        ['>','gt'],
        [' ','nbsp'],
        ['"','quot']
    ];

    for (var i = 0, max = entities.length; i < max; ++i) 
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return text;
  }

  openModal(cntx, obj) { 
    this.modal = { open:true, 'cntx':cntx, 'obj':obj } 
    if(cntx == 'importing-contracts') {
      this.contracts = [];
      this.warranties = [];
      this.print_contracts = false;
      this.print_warranties = false;
    }
  }
  closeModal() { this.modal.open = false; }

  PrintLabels() {
    window.print();
  }

  openFileManagement(event: any){
    event.preventDefault();

    var fileManager = document.getElementById("document-upload");
    fileManager.click();
  }

  openFileManagementNew(event:any) {
    event.preventDefault();

    var fileManager = document.getElementById("document-upload-new");
    fileManager.click();
  }


  openFileManagementFirst(event: any){
    event.preventDefault();

    var fileManager = document.getElementById("document-upload1");
    fileManager.click();
  }


  openFileManagementSecond(event: any){
    event.preventDefault();

    var fileManager = document.getElementById("document-upload2");
    fileManager.click();
  }

  openFileManagementAcList(event:any) {
    event.preventDefault();

    var fileManager = document.getElementById("document-upload3");
    fileManager.click();
  }

  openFileBurgas(event:any) {
    event.preventDefault();

    var fileManager = document.getElementById("document-upload-burgas");
    fileManager.click();
  }

  onFileChangeBurgas(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      console.log(wb.SheetNames);
      let generated = [];
      let sheet_names = [];
      for(var si = 0; si < wb.SheetNames.length; si++) {
        if(wb.SheetNames[si] != 'Sheet1') { sheet_names.push(wb.SheetNames[si]); }
        generated.push({ protocol:false, warranty:false, profilaktika:false })
      }
      // console.log(sheet_names);
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      // /* save data */
      const data = XLSX.utils.sheet_to_html(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      this.importHTMLBurgas = data;
      setTimeout(()=>{
        let data_table = this.tableToArray(document.getElementById('import-htmlburgas').getElementsByTagName('table')[0]);
        console.log(data_table);
        let available_products = [];
        for(var ai = 11; ai < 16; ai++) { available_products.push( { 'description':data_table[0][ai].substring(3), 'label':"" } ) }
        this.openModal('importing-contracts-burgas', { 'sheets':wb.Sheets, 'sheets_names':sheet_names, 'generated':generated, 'available_products':available_products, 'contract':'', 'selected_index_sheet':0, 'warranty_period':66, step:1, 'wb':wb, 'contracts':[] })
        this.HandleWorksheet();
      }, 500);
 
    }
  }

  SwitchSelectedSheet(i) {
    this.modal.obj.selected_index_sheet = i;
    this.HandleWorksheet();
  }

  HandleWorksheet() {
    this.modal.obj.contracts = [];

    let wb = this.modal.obj.wb;
    const wsname: string = wb.SheetNames[this.modal.obj.selected_index_sheet];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_html(ws); // to get 2d array pass 2nd parameter as object {header: 1}
    this.importHTMLBurgas = data;
    setTimeout(()=>{
      let data_table = this.tableToArray(document.getElementById('import-htmlburgas').getElementsByTagName('table')[0]);
      console.log(data_table);
      let address_idx = -1;
      let contract_num_idx = -1;
      let client_name_idx = -1;
      let phone_idx = -1;

      let street_idx = -1;
      let street_num_idx = -1;
      let build_idx = -1;
      let ent_idx = -1;
      let floor_idx = -1;
      let ap_idx = -1;

      let clima9_idx = -1;
      let clima12_idx = -1;
      let clima15_idx = -1;
      let clima18_idx = -1;
      let clima24_idx = -1;
      for(var i = 0; i < data_table[0].length; i++) {
        if(data_table[0][i] == 'номер на образеца') { contract_num_idx = i; }
        else if(data_table[0][i] == 'трите имена') { client_name_idx = i; }
        else if(data_table[0][i] == 'Квартал'  || data_table[0][i] == 'квартал') { address_idx = i; }
        else if(data_table[0][i] == 'телефон' || data_table[0][i] == 'Телефон') { phone_idx = i; }

        else if(data_table[0][i] == 'блок' || data_table[0][i] == 'Блок') { build_idx = i; }
        else if(data_table[0][i] == 'етаж' || data_table[0][i] == 'Етаж') { floor_idx = i; }
        else if(data_table[0][i] == 'вход' || data_table[0][i] == 'Вход') { ent_idx = i; }
        else if(data_table[0][i] == 'апартмент' || data_table[0][i] == 'Aпартмент') { ap_idx = i; }
        else if(data_table[0][i] == 'улица' || data_table[0][i] == 'Улица') { street_idx = i; }
        else if(data_table[0][i] == '№' || data_table[0][i] == 'номер') { street_num_idx = i; }
        
        else if(data_table[0][i] == '11. Климатик 9000  BTU (2.6  kW)' || data_table[0][i] == '11. Климатик 9000 BTU (2.6 kW)') { clima9_idx = i; }
        else if(data_table[0][i] == '12. Климатик 12000  BTU (3.4 kW)' || data_table[0][i] == "12. Климатик 12000 BTU (3.4 kW)") { clima12_idx = i; }
        else if(data_table[0][i] == '13. Климатик 15000  BTU (4.3 kW)' || data_table[0][i] == "13. Климатик 15000 BTU (4.3 kW)") { clima15_idx = i; }
        else if(data_table[0][i] == '14. Климатик 18000  BTU (5.2 kW)' || data_table[0][i] == "14. Климатик 18000 BTU (5.2 kW)") { clima18_idx = i; }
        else if(data_table[0][i] == '15. Климатик 24000  BTU (6.9 kW)' || data_table[0][i] == "15. Климатик 24000 BTU (6.9 kW)") { clima24_idx = i; }
      }
      for(var i = 1; i < data_table.length; i++) {
        let products = [0,0,0,0,0];
        let phone_match = data_table[i][phone_idx].match(/\d+/);
        let obj = { 'name':data_table[i][client_name_idx], 'contract':data_table[i][contract_num_idx], 'products':products, 'address':data_table[i][address_idx], 'phone':phone_match[0] };
        if(obj.name != '' && obj.contract != '' && obj.address != '') {
          if(street_idx != -1) { obj.address += ', ул.' + data_table[i][street_idx]; } 
          if(street_idx != -1) { obj.address += ' ' + data_table[i][street_num_idx]; } 
          if(build_idx != -1) { 
            if(data_table[i][build_idx] != 0) {
              obj.address += ', бл.' + data_table[i][build_idx]; 
            }
          } 
          if(ent_idx != -1) { 
            if(data_table[i][ent_idx] != 0) {
              obj.address += ', вх.' + data_table[i][ent_idx]; 
            }
          } 
          if(floor_idx != -1) { 
            if(data_table[i][floor_idx] != 0) {
              obj.address += ', ет.' + data_table[i][floor_idx]; 
            }
          } 
          if(ap_idx != -1) { 
            if(data_table[i][ap_idx] != 0) {
              obj.address += ', ап.' + data_table[i][ap_idx]; 
            } 
          }

          if(clima9_idx != -1) { obj.products[0] += parseInt(data_table[i][clima9_idx]);  }
          if(clima12_idx != -1) { obj.products[1] += parseInt(data_table[i][clima12_idx]); }
          if(clima15_idx != -1) { obj.products[2] += parseInt(data_table[i][clima15_idx]); }
          if(clima18_idx != -1) { obj.products[3] += parseInt(data_table[i][clima18_idx]); }
          if(clima24_idx != -1) { obj.products[4] += parseInt(data_table[i][clima24_idx]); }
          this.modal.obj.contracts.push(obj);
        }
      }
      console.log(this.modal.obj.contracts);
    }, 500);


    // let name = "";
    // let products = [];
    // let address = "";
    
  }

  PrintBurgasProtocols() {
    this.modal.obj.generated[this.modal.obj.selected_index_sheet].protocol = true;
    this.modal.obj.print_burgas_protocols = true;
    this.modal.open = false;
  }

  PrintBurgasWarranties() {
    this.modal.obj.generated[this.modal.obj.selected_index_sheet].warranty = true;
    this.modal.obj.print_burgas_warranties = true;
    this.modal.open = false;
  }

  PrintBurgasProfilaktika() {
    this.modal.obj.generated[this.modal.obj.selected_index_sheet].profilaktika = true;
    this.modal.obj.print_burgas_profilaktika = true;
    this.modal.open = false;
  }

  onFileChangeNew(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_html(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data[0][0]); // Data will be logged in array format containing objects
      // console.log(data[1][0]);
      this.importHTML = data;
      this.labels = [];
      this.protocols = [];
      setTimeout(()=>{
        let data_table = this.tableToArray(document.getElementById('import-html').getElementsByTagName('table')[0]);
        let real_counter = 0;
        let start = false;
        let orders = [];
        console.log(data_table);
        for(var ci = 0; ci < data_table.length; ci++) {
          if(data_table[ci][1] == 'Име на клиент' || data_table[ci][2] == 'Име на клиент') {
            start = true;
            continue;
          }
          if(start) {
            if(data_table[ci].length == 8) {
              if(data_table[ci][0] == '') {
                // orders[real_counter - 1]['products'].push({ 'product':data_table[ci][2], 'count':parseInt(data_table[ci][3]) });
                // orders[real_counter - 1]['count'] += parseInt(data_table[ci][3])
              } else {
                let products = [];
                let dt = new Date(data_table[ci][7]);
                let dt_format = this.returnPadZero(dt.getDate(), 2) + '.' + this.returnPadZero(dt.getMonth() + 1, 2) + '.' + dt.getFullYear() + "г";
                products.push({ 'product':data_table[ci][2], 'count':parseInt(data_table[ci][3]) });
                orders.push({ 'num':data_table[ci][0], 'client':data_table[ci][1], 'city':data_table[ci][4], 'products':products, 'count':parseInt(data_table[ci][3]), 'address':data_table[ci][5], 'phone':data_table[ci][6], 'date':dt_format });
                real_counter += 1;
              }
            } else if(data_table[ci].length == 9) {
              if(data_table[ci][1] == '') {
                // orders[real_counter - 1]['products'].push({ 'product':data_table[ci][3], 'count':parseInt(data_table[ci][4]) });
                // orders[real_counter - 1]['count'] += parseInt(data_table[ci][3])
              } else {
                let products = [];
                let dt = new Date(data_table[ci][7]);
                let dt_format = this.returnPadZero(dt.getDate(), 2) + '.' + this.returnPadZero(dt.getMonth() + 1, 2) + '.' + dt.getFullYear() + "г";
                products.push({ 'product':data_table[ci][2], 'count':parseInt(data_table[ci][3]) });
                orders.push({ 'num':data_table[ci][0], 'client':data_table[ci][1], 'city':data_table[ci][4], 'products':products, 'count':parseInt(data_table[ci][3]), 'address':data_table[ci][5], 'phone':data_table[ci][6], 'date':dt_format });
                // orders.push({ 'num':data_table[ci][1], 'client':data_table[ci][2], 'city':data_table[ci][5], 'products':products, 'count':parseInt(data_table[ci][4]), 'address':data_table[ci][6], 'phone':data_table[ci][7], 'date':data_table[ci][8] });
                real_counter += 1;
              }
            } else {
              if(data_table[ci].length == 2) {
                if(data_table[ci][0] != '') {
                  orders[real_counter - 1]['products'].push({ 'product':data_table[ci][0], 'count':parseInt(data_table[ci][1]) });
                  orders[real_counter - 1]['count'] += parseInt(data_table[ci][1])
                }
              } else if(data_table[ci].length == 6 || data_table[ci].length == 5) {
                if(data_table[ci][1] != '') {
                  orders[real_counter - 1]['products'].push({ 'product':data_table[ci][0], 'count':parseInt(data_table[ci][1]) });
                  orders[real_counter - 1]['count'] += parseInt(data_table[ci][2])
                }
              }

            }
            
          }
        }
        let products_available = [];
        // console.log(orders);
        for(let oi = 0; oi < orders.length; oi++) {
          console.log(orders[oi]['products']);
          for(let pi = 0; pi < orders[oi]['products'].length; pi++) {
            // console.log(orders[oi]['products'][pi]['product']);
            let split_model = orders[oi]['products'][pi]['product'].split("(")[1].split(")")[0].trim();
            if(products_available.filter(prod => prod.description == split_model).length == 0) {
              products_available.push({ 'description':split_model, 'product':orders[oi]['products'][pi]['product'], 'label':'' });
            }
          }
    
        }
        this.openModal('contract-dimitrovgrad', { 'orders':orders, 'available_products':products_available, step:1 })
        // this.labels = [];
        // for(let oi = 0; oi < orders.length; oi++) {
        //   let label_limit = 8;
        //   for(let li = 0; li < label_limit; li++) {
        //     this.labels.push(orders[oi]);
        //   }
        // }
        // this.ngZone.run(()=>{});
      }, 500);
    };
  }

  GenerateLabels() {
        this.modal.open = false;
        this.labels = [];
        for(let oi = 0; oi < this.modal.obj.orders.length; oi++) {
          let label_limit = 8;
          for(let li = 0; li < label_limit; li++) {
            this.labels.push(this.modal.obj.orders[oi]);
          }
        }
        this.print_labels = true;
        this.ngZone.run(()=>{});
  }

  GenerateProtocols() {
    this.modal.open = false;
    this.protocols = [];
    this.print_protocols = true;
    for(let oi = 0; oi < this.modal.obj.orders.length; oi++) {
      this.protocols.push(this.modal.obj.orders[oi]);
    }
    this.ngZone.run(()=>{});
}

  onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_html(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data[0][0]); // Data will be logged in array format containing objects
      // console.log(data[1][0]);
      this.importHTML = data;
      setTimeout(()=>{
        let data_table = this.tableToArray(document.getElementById('import-html').getElementsByTagName('table')[0]);
        let real_counter = 0;
        let start = false;
        let orders = [];
        console.log(data_table);
        for(var ci = 0; ci < data_table.length; ci++) {
          if(data_table[ci][1] == 'Име на клиент' || data_table[ci][2] == 'Име на клиент') {
            start = true;
            continue;
          }
          if(start) {
            if(data_table[ci].length == 8) {
              if(data_table[ci][0] == '') {
                orders[real_counter - 1]['count'] += parseInt(data_table[ci][3])
              } else {
                orders.push({ 'num':data_table[ci][0], 'client':data_table[ci][1], 'city':data_table[ci][4], 'count':parseInt(data_table[ci][3]), 'address':data_table[ci][5], 'phone':data_table[ci][6] });
                real_counter += 1;
              }
            } else if(data_table[ci].length == 9) {
              if(data_table[ci][1] == '') {
                orders[real_counter - 1]['count'] += parseInt(data_table[ci][3])
              } else {
                orders.push({ 'num':data_table[ci][1], 'client':data_table[ci][2], 'city':data_table[ci][5], 'count':parseInt(data_table[ci][4]), 'address':data_table[ci][6], 'phone':data_table[ci][7] });
                real_counter += 1;
              }
            } else {
              if(data_table[ci][0] != '') {
                orders[real_counter - 1]['count'] += parseInt(data_table[ci][1])
              }
            }
            
          }
        }
        this.labels = [];
        for(let oi = 0; oi < orders.length; oi++) {
          let label_limit = 8;
          for(let li = 0; li < label_limit; li++) {
            this.labels.push(orders[oi]);
          }
        }
        this.ngZone.run(()=>{});
        console.log(orders);
      }, 500);
      // console.log(XLSX.utils.decode_row('B6')); 
      // for(var i = 0; i < data.length; i++) {
      //   if(data[i]['Сер ном външно'] === undefined && data[i]['"Сер ном вътрешно '] === undefined) { data.splice(i, 1); }
      // }
      // let comment = "";
      // if(this.system.returnIsAdmin()) { comment = "Разтоварени в Пловдив"; }
      // this.openModal('importing-delivery', { 'data_rows':data, 'name':'', document_number:0, container_number:"", date_add:new Date(), 'comment':comment, step:1, current_delivery_date:new Date(), 'data':data })
      // this.getAvailableId();
    };
  }

  onFileChangeFirst(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      if(wsname == "Монтаж_ПГО") {
        /* save data */
        const data = XLSX.utils.sheet_to_html(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        // console.log(data[0][0]); // Data will be logged in array format containing objects
        // console.log(data[1][0]);
        this.importHTMLFirst = data;
        setTimeout(()=>{
          this.modal.obj.first_file_loaded = true;
          let data_table = this.tableToArray(document.getElementById('import-html1').getElementsByTagName('table')[0]);
          console.log(data_table);
          let first_idx = 0;
          let first = false;
          this.modal.obj.first_table = data_table;
          // for(let ri = 0; ri < data_table.length; ri++) {
          //   if(data_table[ri].length == 26) {
          //     if(data_table[ri][0] != 0 && data_table[ri][0] != "") {
          //       if(!first) { console.log(ri); first =true; }
          //     }
          //   }
          // }
        }, 500);
      } else {
        this.modal.obj.first_file_loaded = false;
        alert("Невалиден файл, моля използвайте само Монтаж ПГО шаблона")
      }
    };
  }

  onFileChangeSecond(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      if(wsname == "Справка") {
        this.modal.obj.second_file_loaded = true;
        /* save data */
        const data = XLSX.utils.sheet_to_html(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        // console.log(data[0][0]); // Data will be logged in array format containing objects
        // console.log(data[1][0]);
        this.importHTMLSecond = data;
        setTimeout(()=>{
          let data_table = this.tableToArray(document.getElementById('import-html2').getElementsByTagName('table')[0]);
          console.log(data_table);
          let first_idx = 0;
          let first = false;
          this.modal.obj.second_table = data_table;
        }, 250);
      } else {
        this.modal.obj.second_file_loaded = false;
        alert("Невалиден файл, моля използвайте само Справка шаблона")
      }
    };
  }

  onFileChangeAcList(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.modal.obj.third_file_loaded = true;
        /* save data */
        const data = XLSX.utils.sheet_to_html(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        // console.log(data[0][0]); // Data will be logged in array format containing objects
        // console.log(data[1][0]);
        this.importHTMLAcList = data;
        setTimeout(()=>{
          this.modal.obj.ac_list = [];
          let data_table = this.tableToArray(document.getElementById('import-html-ac-list').getElementsByTagName('table')[0]);
          for(let di = 2; di < data_table.length; di++) {
            if(data_table[di][3] != '' || data_table[di][4] != '') {
              this.modal.obj.ac_list.push({ 'make':data_table[di][0], 'model_internal':data_table[di][1], 'model_external':data_table[di][2], 'serial_internal':data_table[di][3], 'serial_external':data_table[di][4] });
            }
          }
          console.log(this.modal.obj.ac_list);
        }, 250);
    };
  }

  PrepareContracts() {
    let primary_table = this.modal.obj.first_table;
    this.modal.obj.contracts = [];
    this.modal.obj.protocols_sofia = [];
    this.modal.obj.available_products = [];
    let first = false;
    let real_count = -1;
    for(let ri = 0; ri < primary_table.length; ri++) {
      if(primary_table[ri].length == 26) {
        console.log(primary_table[ri][0]);
        if(primary_table[ri][0] != 0 && primary_table[ri][1] != "№" || primary_table[ri][0] == '' && primary_table[ri][1].length > 0 && primary_table[ri][1] != '№') {
          if(!first) { first = true; }
          if(first) {
            if(primary_table[ri][1] != '') {
              console.log(primary_table[ri][3]);
              let obj = {
                opos:primary_table[ri][3],
                client_name:primary_table[ri][4],
                product_description:primary_table[ri][5],
                product_count:primary_table[ri][6],
                address:primary_table[ri][8],
                phone:primary_table[ri][10],
                contract:this.ReturnFromSecondaryTable(primary_table[ri][3]),
                products:[{ 'product':primary_table[ri][5], product_count:primary_table[ri][6] }]
              }
              console.log(obj);
              if(this.modal.cntx == 'importing-contracts-sofia') {
                this.modal.obj.protocols_sofia.push(obj);
              } else {
                this.modal.obj.contracts.push(obj);
              }

              real_count += 1;
            } else {
              if(this.modal.cntx == 'importing-contracts-sofia') {
                this.modal.obj.protocols_sofia[real_count]['products'].push({ 'product':primary_table[ri][5], product_count:primary_table[ri][6] });
              } else {
                this.modal.obj.contracts[real_count]['products'].push({ 'product':primary_table[ri][5], product_count:primary_table[ri][6] });
              }
            }
            if(this.modal.obj.available_products.filter(prod => prod.type == primary_table[ri][5].match(/\d+/)[0]).length == 0) {
              let product_type = { product_description:primary_table[ri][5], type:primary_table[ri][5].match(/\d+/)[0], "label":"" };
              this.modal.obj.available_products.push(product_type);
            }
          }
        }
      } else if(primary_table[ri].length == 21) {
        if(primary_table[ri][4].indexOf('съгласно договор №') > -1) {
          let split_contract = primary_table[ri][4].split("съгласно договор №")[1];
          this.modal.obj.general_contract = split_contract;
        }
      }
    }
    this.modal.obj.available_products = this.modal.obj.available_products.sort((a, b) => {
        return b.type - a.type;
    });
  }

  NextPrepareContracts() {
    this.modal.obj.assigned_serials = [];
    this.modal.obj.assigned_serials.push(["Договор", "Серийни номера"]);
    if(this.modal.cntx == 'importing-contracts-sofia') {
      for(let ci =0; ci < this.modal.obj.protocols_sofia.length; ci++) {
        for(let pi = 0; pi < this.modal.obj.protocols_sofia[ci]['products'].length; pi++) {
          let obj = [ this.modal.obj.protocols_sofia[ci]['opos'], ""];
          let count = this.modal.obj.protocols_sofia[ci]['products'][pi]['product_count'];
          this.modal.obj.protocols_sofia[ci]['products'][pi]['internal_serials'] = [];
          this.modal.obj.protocols_sofia[ci]['products'][pi]['external_serials'] = [];
          if(this.modal.obj.ac_list !== undefined) {
            for(var si = 0; si < this.modal.obj.ac_list.length; si++) {
              if(count > 0) {
                if(this.modal.obj.protocols_sofia[ci].products[pi].product.match(/\d+/)[0] == 30000 && this.modal.obj.ac_list[si]['model_internal'] == "MA3-24HRFN8-QRD0GW"
                || this.modal.obj.protocols_sofia[ci].products[pi].product.match(/\d+/)[0] == 24000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I24MF3"
                || this.modal.obj.protocols_sofia[ci].products[pi].product.match(/\d+/)[0] == 18000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I18MF3"
                || this.modal.obj.protocols_sofia[ci].products[pi].product.match(/\d+/)[0] == 15000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I18MF3"
                || this.modal.obj.protocols_sofia[ci].products[pi].product.match(/\d+/)[0] == 12000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I12MF3"
                || this.modal.obj.protocols_sofia[ci].products[pi].product.match(/\d+/)[0] == 9000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I09MF3") {
                  this.modal.obj.protocols_sofia[ci]['products'][pi]['internal_serials'].push(this.modal.obj.ac_list[si]['serial_internal']);
                  this.modal.obj.protocols_sofia[ci]['products'][pi]['external_serials'].push(this.modal.obj.ac_list[si]['serial_external']);
                  if(obj[1] != '') { obj[1] += '\n'; }
                  obj[1] += this.modal.obj.ac_list[si]['serial_internal'] + '/' + this.modal.obj.ac_list[si]['serial_external'];
                  this.modal.obj.ac_list.splice(si, 1);
                  count -= 1;
                }
              } 
            }
          }
          this.modal.obj.assigned_serials.push(obj);
  
          //this.ReturnProperProductLabel(warranty.products[pi].product)
        }
      }
    } else {
      for(let ci =0; ci < this.modal.obj.contracts.length; ci++) {
        for(let pi = 0; pi < this.modal.obj.contracts[ci]['products'].length; pi++) {
          let obj = [ this.modal.obj.contracts[ci]['opos'], ""];
          let count = this.modal.obj.contracts[ci]['products'][pi]['product_count'];
          this.modal.obj.contracts[ci]['products'][pi]['internal_serials'] = [];
          this.modal.obj.contracts[ci]['products'][pi]['external_serials'] = [];
          if(this.modal.obj.ac_list !== undefined) {
            for(var si = 0; si < this.modal.obj.ac_list.length; si++) {
              if(count > 0) {
                if(this.modal.obj.contracts[ci].products[pi].product.match(/\d+/)[0] == 30000 && this.modal.obj.ac_list[si]['model_internal'] == "MA3-24HRFN8-QRD0GW"
                || this.modal.obj.contracts[ci].products[pi].product.match(/\d+/)[0] == 24000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I24MF3"
                || this.modal.obj.contracts[ci].products[pi].product.match(/\d+/)[0] == 18000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I18MF3"
                || this.modal.obj.contracts[ci].products[pi].product.match(/\d+/)[0] == 15000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I18MF3"
                || this.modal.obj.contracts[ci].products[pi].product.match(/\d+/)[0] == 12000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I12MF3"
                || this.modal.obj.contracts[ci].products[pi].product.match(/\d+/)[0] == 9000 && this.modal.obj.ac_list[si]['model_internal'] == "CS-I09MF3") {
                  this.modal.obj.contracts[ci]['products'][pi]['internal_serials'].push(this.modal.obj.ac_list[si]['serial_internal']);
                  this.modal.obj.contracts[ci]['products'][pi]['external_serials'].push(this.modal.obj.ac_list[si]['serial_external']);
                  if(obj[1] != '') { obj[1] += '\n'; }
                  obj[1] += this.modal.obj.ac_list[si]['serial_internal'] + '/' + this.modal.obj.ac_list[si]['serial_external'];
                  this.modal.obj.ac_list.splice(si, 1);
                  count -= 1;
                }
              } 
            }
          }
          this.modal.obj.assigned_serials.push(obj);
  
          //this.ReturnProperProductLabel(warranty.products[pi].product)
        }
      }
    }
    this.modal.obj.step = 3;
    if(this.modal.cntx != 'importing-contracts-sofia') {
      var wb = XLSX.utils.book_new(); var ws = XLSX.utils.aoa_to_sheet(this.modal.obj.assigned_serials); XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Серийни номера.xlsx", { compression: true});
    }
  }

  GenerateContracts() {
    this.modal.open = false;
    this.contracts = this.modal.obj.contracts;
    this.print_contracts = true;
  }

  GenerateWarranties() {
    this.modal.open = false;
    this.warranties = this.modal.obj.contracts;
    this.print_warranties = true;
  }

  GenerateProtocolsSofia() {
    this.modal.open = false;
    this.protocols_sofia = this.modal.obj.protocols_sofia;
    this.print_protocols_sofia = true;
  }

  tableToArray(table) {
    var result = []
    var rows = table.rows;
    var cells, t;
  
    // Iterate over rows
    for (var i=0, iLen=rows.length; i<iLen; i++) {
      cells = rows[i].cells;
      t = [];
  
      // Iterate over cells
      for (var j=0, jLen=cells.length; j<jLen; j++) {
        t.push(cells[j].textContent);
      }
      result.push(t);
    }
    return result; 
  }

  MoveEmailerToStep(step) {
    if(step == 2) {
      this.http.get("api/service.php?action=get_accounts").subscribe((data) => {
        this.modal.obj.accounts = data['accounts'];
        this.modal.obj.step = 2;
      });
    } else if(step == 3) {
      this.http.post("api/service.php?action=send_mass_mail", { 'title':this.modal.obj.title, 'layout':this.modal.obj.layout, 'emails':this.modal.obj.selected_emails, 'names':this.modal.obj.selected_names }).subscribe((data) => {
        console.log(data);
        this.closeModal();
      });
    }
  }

  SelectEmail(em, name) {
    if(this.modal.obj.selected_emails.filter(email => email == em).length > 0) {
      for(var ei = 0; ei < this.modal.obj.selected_emails.length; ei++) {
        if(this.modal.obj.selected_emails[ei] == em) {
          this.modal.obj.selected_emails.splice(ei, 1);
          this.modal.obj.selected_names.splice(ei, 1);
        }
      }
    } else {
      this.modal.obj.selected_emails.push(em);
      this.modal.obj.selected_names.push(name);
    }
  }

  SwitchAllEmails() {
    if(this.modal.obj.selected_emails.length != this.modal.obj.accounts.length) {
      this.modal.obj.selected_emails = [];
      this.modal.obj.selected_names = [];
      for(var ai = 0; ai < this.modal.obj.accounts.length; ai++) {
        this.modal.obj.selected_emails.push(this.modal.obj.accounts[ai].email);
        this.modal.obj.selected_names.push(this.modal.obj.accounts[ai].username);
      }
    } else {
      this.modal.obj.selected_emails = [];
      this.modal.obj.selected_names = [];
    }
  }

  returnShadowCopy(obj) { return JSON.parse(JSON.stringify(obj)); }
  ReturnAreAllProductsFilled() {
    if(this.modal.obj.available_products.filter(prod => prod.label == '').length > 0) { return false; } else { return true; }
  }
  ReturnFromSecondaryTable(opos) {
    return this.modal.obj.second_table.filter(row => row[2] == opos)[0][1];
  }
  ReturnSplit(str, split_by, idx) {
    return str.split(split_by)[idx];
  }

  ReturnProperProductLabel(prod) {
    return this.modal.obj.available_products.filter(product => product.type == prod.match(/\d+/)[0])[0]['label'];
  }

  ReturnProperProductLabelProtocols(prod) {
    for(var pi = 0; pi < this.modal.obj.available_products.length; pi++) {
      if(prod.product.indexOf(this.modal.obj.available_products[pi].description) > -1) {
        return this.modal.obj.available_products[pi].label;
      }
    }
    return '';
  }

  ReturnSplitDate(date){
    if(date.split(" ").length > 1) {
      return date.split(" ")[0];
    } else {
      return date;
    }
  }

  ReturnWarrantyProducts(warranty) {
    let arr = [];
    for(let pi = 0; pi < warranty.products.length; pi++) {
      for(let pip = 0; pip < warranty.products[pi].product_count; pip++) {
        arr.push({ 'product_label':this.ReturnProperProductLabel(warranty.products[pi].product), 'BTU':warranty.products[pi].product.match(/\d+/)[0], 'serial_internal':warranty.products[pi]['internal_serials'][pip], 'serial_external':warranty.products[pi]['external_serials'][pip] });
      }
    }
    return arr;
  }

  returnProductCountLetters(count) {
    if(count == 1) { return "(един брой)" }
    else if(count == 2) { return "(два броя)" }
    else if(count == 3) { return "(три броя)" }
    else if(count == 4) { return "(четери броя)" }
    else if(count == 5) { return "(пет броя)" }
    else { return ""; }
  }


  returnPadZero(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }

  ReturnProductDescriptionById(i) {
    return this.modal.obj.available_products[i].description;
  }

  ReturnProductLabelById(i) {
    return this.modal.obj.available_products[i].label;
  }

  ReturnOnlyContract(contract){
    return contract.split("/")[0];
  }

  ReturnPageHeight(len) {
    if(len > 4) { return '200vh';  }
    else { return 'calc(200vh - 10px)'; }
  }

  ReturnPageMinHeight(len) {
    if(len > 4) { return '200vh';  }
    else { return '100vh'; }
  }

  ReturnFilteredAccounts() {
    if(this.modal.obj.account_search.length == 0) {
      return this.modal.obj.accounts;
    } else {
      return this.modal.obj.accounts.filter(acc => acc.email.toLowerCase().indexOf(this.modal.obj.account_search.toLowerCase()) > -1);
    }
  }

  ReturnIsEmailSelected(em) {
    if(this.modal.obj.selected_emails.filter(email => email == em).length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
