<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-sale'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете тази продажба от системата ?</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="deleteSale(modal.obj.uid, modal.obj.date_of_sale)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'return-product'">
        <span class="alert-text">Сигурни ли сте, че искате да върнете продукт <strong>"{{ modal.obj.serial }}"</strong> от продажба на <strong>"{{modal.obj.name}}"</strong> ?</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="backStorageProduct(modal.obj.uid, modal.obj.serial, modal.obj.sale_id)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'loading-sales'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Зареждане на продажби...</span>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'exporting-sales'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Експортиране на данни...</span>
    </div>

    <!-- <div class="modal-sm-md modal" *ngIf="modal.cntx == 'add-warranty'">
        <div class="modal-ttl">Издаване на гаранционна карта</div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Адрес за монтаж <span style="color:red;" alt="Трябва да бъде въведен адрес на монтаж">*</span></span>
            <input type="text" placeholder="Въведете адрес за монтаж" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <span class="label">Населено място <span style="color:red;" alt="Трябва да бъде въведено населено място на клиент">*</span></span>
            <input type="text" placeholder='Въведете населено място' [(ngModel)]="modal.obj.town" (focus)="modal.obj.selectingTown = true" (focusout)="deselectTown()" />
            <div class="dropdown-list" *ngIf="modal.obj.selectingTown && modal.obj.town.length > 0" style="top:-10px;">
                <span *ngFor="let town of returnFilteredTown()" (click)="selectTown(town)">{{ town.name }}</span>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="position:relative;float:left;">
            <div id="delivery-date-picker" class="date-picker" [ngClass]="{ 'active':purchase_date_picker }" (clickOutside)="purchase_date_picker = false">
                <div class="datepicker-header">
                    <div class="datepicker-left-tool hoverable" (click)="prevPurchaseMonth()"><i class='icon arrow-left'></i></div>
                    <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_purchase_date)) }} {{ returnYear(current_purchase_date) }}</span>
                    <div class="datepicker-right-tool hoverable" (click)="nextPurchaseMonth()"><i class='icon arrow-right'></i></div>
                </div>
                <div class="datepicker-content">
                    <div class="datepicker-title-row">
                        <span>Пн</span>
                        <span>Вт</span>
                        <span>Ср</span>
                        <span>Чт</span>
                        <span>Пт</span>
                        <span>Сб</span>
                        <span>Нд</span>
                    </div>
                    <div class="datepicker-row" id="date_delivery_body">
                        <span *ngFor="let dt of current_purchase_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_purchase_date) && dt.month == returnMonth(modal.obj.date_purchase) - 1 }" (click)="selectPurchaseDate(dt)">{{ dt.date }}</span>
                    </div>
                </div>
            </div>
            <span class="label">Дата на закупуване</span>
            <input type="text" placeholder="Въведете дата на закупуване" readonly value="{{ returnDateFormated(modal.obj.date_purchase) }}" (click)="openPurchasePicker()"  />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="position:relative;float:left;">
            <div id="delivery-date-picker" class="date-picker" [ngClass]="{ 'active':installation_date_picker }" (clickOutside)="installation_date_picker = false">
                <div class="datepicker-header">
                    <div class="datepicker-left-tool hoverable" (click)="prevInstallationMonth()"><i class='icon arrow-left'></i></div>
                    <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_installation_date)) }} {{ returnYear(current_installation_date) }}</span>
                    <div class="datepicker-right-tool hoverable" (click)="nextInstallationMonth()"><i class='icon arrow-right'></i></div>
                </div>
                <div class="datepicker-content">
                    <div class="datepicker-title-row">
                        <span>Пн</span>
                        <span>Вт</span>
                        <span>Ср</span>
                        <span>Чт</span>
                        <span>Пт</span>
                        <span>Сб</span>
                        <span>Нд</span>
                    </div>
                    <div class="datepicker-row" id="date_delivery_body">
                        <span *ngFor="let dt of current_purchase_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_installation_date) && dt.month == returnMonth(modal.obj.date_installation) - 1 }" (click)="selectInstallationDate(dt)">{{ dt.date }}</span>
                    </div>
                </div>
            </div>
            <span class="label">Дата на монтаж</span>
            <input type="text" placeholder="Въведете дата на монтаж"  readonly value="{{ returnDateFormated(modal.obj.date_installation) }}" (click)="openInstallationPicker()"  />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Телефон на клиент <span style="color:red;" alt="Трябва да бъде телефон на клиент">*</span></span>
            <input type="text" placeholder="Въведете телефон на клиент" [(ngModel)]="modal.obj.phone" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Имейл на клиент</span>
            <input type="text" placeholder="Въведете Имейл на клиент" [(ngModel)]="modal.obj.email" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
            <span class="label">Информация за сервиз <span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
            <select [(ngModel)]="modal.obj.service_id" (change)="checkService()">
                <option value="0" disabled>Изберете сервиз</option>
                <option value="-1" >Добавяне на нов сервиз</option>
                <option value="{{ office.uid }}" *ngFor="let office of offices">{{office.address}} / {{office.phone}}</option>
            </select>
        </div>
        <div class="btn-line">
            <div class="btn negative" (click)="closeModal()">Отказ</div>
            <div class="btn positive" [ngClass]="{ 'disabled':modal.obj.address.length == 0 || modal.obj.service_id == '0' || modal.obj.town.length == 0 || modal.obj.phone.length == 0 }" (click)="createWarranty(modal.obj.sale_id, modal.obj.name, modal.obj.address, modal.obj.phone, modal.obj.email, modal.obj.service_id, modal.obj.service_address, modal.obj.service_phone, modal.obj.town, returnDateFormatedForStorage(modal.obj.date_purchase), returnDateFormatedForStorage(modal.obj.date_installation))">Издаване</div>
        </div>
    </div> -->
    <div class="modal-sm" *ngIf="modal.cntx == 'add-office'">
        <div class="modal-ttl">Добавяне на офис</div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Адрес нa офис</span>
            <input type="text" placeholder="Въведете адрес на офис" [(ngModel)]="modal.obj.address" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Телефон на офис</span>
            <input type="text" placeholder="Въведете телефон на офис" [(ngModel)]="modal.obj.phone" />
        </div>
        <div class="btn-line">
            <div class="btn back" (click)="backFromOffice()">Отказ</div>
            <div class="btn positive" [ngClass]="{ 'disabled':modal.obj.address.length == 0 || modal.obj.phone.length == 0 }" (click)="addOffice()">Добавяне</div>
        </div>
    </div>
    <div class="modal-sm" *ngIf="modal.cntx == 'export-sale'">
        <div class="modal-ttl">Експортиране на продажби</div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <div id="delivery-date-picker" class="date-picker" [ngClass]="{ 'active':modal.obj.start_picker }" (clickOutside)="modal.obj.start_picker = false">
                <div class="datepicker-header">
                    <div class="datepicker-left-tool hoverable" (click)="prevMonth(modal.obj.current_start_date)"><i class='icon arrow-left'></i></div>
                    <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(modal.obj.current_start_date)) }} {{ returnYear(modal.obj.current_start_date) }}</span>
                    <div class="datepicker-right-tool hoverable" (click)="nextMonth(modal.obj.current_start_date)"><i class='icon arrow-right'></i></div>
                </div>
                <div class="datepicker-content">
                    <div class="datepicker-title-row">
                        <span>Пн</span>
                        <span>Вт</span>
                        <span>Ср</span>
                        <span>Чт</span>
                        <span>Пт</span>
                        <span>Сб</span>
                        <span>Нд</span>
                    </div>
                    <div class="datepicker-row" id="date_delivery_body">
                        <span *ngFor="let dt of modal.obj.dates " [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(modal.obj.current_start_date) && dt.month == returnMonth(modal.obj.start_date) - 1 }" (click)="selectStartDate(dt)">{{ dt.date }}</span>
                    </div>
                </div>
            </div>
            <span class="label">Начална дата</span>
            <input type="text" style="width:calc(100% - 25px)" placeholder="Въведете дата на монтаж"  readonly value="{{ returnDateFormated(modal.obj.start_date) }}" (click)="openDateStartPicker()"  />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <div id="delivery-date-picker" class="date-picker" [ngClass]="{ 'active':modal.obj.end_picker }" (clickOutside)="modal.obj.end_picker = false">
                <div class="datepicker-header">
                    <div class="datepicker-left-tool hoverable" (click)="prevMonth(modal.obj.current_end_date)"><i class='icon arrow-left'></i></div>
                    <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(modal.obj.current_end_date)) }} {{ returnYear(modal.obj.current_end_date) }}</span>
                    <div class="datepicker-right-tool hoverable" (click)="nextMonth(modal.obj.current_end_date)"><i class='icon arrow-right'></i></div>
                </div>
                <div class="datepicker-content">
                    <div class="datepicker-title-row">
                        <span>Пн</span>
                        <span>Вт</span>
                        <span>Ср</span>
                        <span>Чт</span>
                        <span>Пт</span>
                        <span>Сб</span>
                        <span>Нд</span>
                    </div>
                    <div class="datepicker-row" id="date_delivery_body">
                        <span *ngFor="let dt of modal.obj.dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(modal.obj.current_end_date) && dt.month == returnMonth(modal.obj.end_date) - 1 }" (click)="selectEndDate(dt)">{{ dt.date }}</span>
                    </div>
                </div>
            </div>
            <span class="label">Крайна дата</span>
            <input type="text" style="width:calc(100% - 25px)" placeholder="Въведете дата на монтаж"  readonly value="{{ returnDateFormated(modal.obj.end_date) }}" (click)="openDateEndPicker()"  />
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
            <span class="label">Типове продажби</span>
            <select [(ngModel)]="modal.obj.type_sales">
                <option value="0">Всички</option>
                <option value="1">Към дилъри</option>
                <option value="2">Към клиенти</option>
            </select>
        </div>
        <div class="btn-line">
            <div class="btn back" (click)="closeModal()">Отказ</div>
            <div class="btn positive" (click)="getExportSales()">Експортиране</div>
        </div>
    </div>
</div>
<div class="content">
    <span class="page-title">
        <span class="name">Продажби</span>
        <!-- <select [(ngModel)]="timeline" (change)="switchedFilter()">
            <option value="0">От началото</option>
            <option value="3">Последните 3 месеца</option>
            <option value="6">Последните 6 месеца</option>
            <option value="12">Последната 1 година</option>
        </select>  -->
        <!-- <select [(ngModel)]="brand" (change)="switchedFilter()">
            <option value="all">Всички марки</option>
            <option *ngFor="let brand of availableBrands" value="{{ brand.name }}" [value]="brand.name">{{ brand.name }}</option>
        </select>  -->
        <div class="label-searched" (click)="clearSearched()" *ngIf="searched">
            <strong class="type" *ngIf="search_type == 'client_name'">Име на клиент:</strong>
            <strong class="type" *ngIf="search_type == 'seller_name'">Име на клиент:</strong>
            <strong class="type" *ngIf="search_type == 'order_number'">Номер на поръчка:</strong>
            <strong class="type" *ngIf="search_type == 'serial_number'">Сериен номер:</strong>
            <strong class="type" *ngIf="search_type == 'model'">Модел:</strong>
            <span>{{ search }}</span>
            <i class="icon delete-label"></i>
        </div>
        <div class="search-title-box" *ngIf="!searched">
            <select [(ngModel)]="search_type">
                <option value="client_name">Име на клиент</option>
                <option value="seller_name" *ngIf="system.returnRole() == 1 || system.returnRole() == 3">Име на продавач</option>
                <option value="order_number">Номер на поръчка</option>
                <option value="serial_number">Сериен номер</option>
                <option value="model">Модел</option>
            </select>
            <input type="text" (keydown.enter)="searchSales()" style="height:30px;width:240px;" [(ngModel)]="search" />
            <div class="round-btn" (click)="searchSales()"><i class="icon search-white"></i></div>
        </div> 
        <span routerLink="/add-sale" class="right-btn" *ngIf="system.returnRole() == 1 || system.returnIsAdmin()">Нова продажба</span>
        <span class="right-btn" style="margin-left:10px;float:left;" (click)="openModal('export-sale', {'start_picker':false, 'start_date':'', 'dates':[], 'current_start_date':'', 'end_picker':false, 'end_date':'', 'current_end_date':'', type_sales:'0' })">Експорт на продажби</span>
        <span class="info-window" *ngIf="search_type == 'model'">
            <i class="icon warning"></i>
            <span>Търсенето по "модел" с частично въведен модел, може да отнеме драстично повече време</span>
        </span>
        <!-- <span class="right-btn import" style="margin-left:10px;float:left;">Импорт на продажби</span> -->
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-1 col-lg-1 text-center">#</div>
            <div class="col-xl-2 col-lg-1 text-center text-overflow" [ngClass]="{ 'col-xl-2':system.returnName() == 'Склад Пловдив', 'col-xl-1':system.returnName() != 'Склад Пловдив' }">Дата на продажба</div>
            <div class="col-xl-1 col-lg-1 text-center">Номер на поръчка</div>
            <div class="col-xl-2 col-lg-1 text-center">Продадено от</div>
            <div class="col-xl-3 col-lg-1 text-center">Продадено на</div>
            <div class="col-xl-1 col-lg-1 text-center" *ngIf="system.returnName() != 'Склад Пловдив'">Фактура</div>
            <div class="col-xl-1 col-lg-1 text-center">Aртикули</div>
            <div class="col-xl-1 col-lg-1 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row" *ngIf="sales.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма добавени продажби</div>
        </div>
        <div class="table-wrapper-row selectable" *ngFor="let sale of sales; let i = index">
            <div class="col-xl-1 col-lg-1 text-center"><div (click)="switchSaleExpanded(sale)" [ngClass]="{ 'expanded':sale.expanded }" class="extendable"><i class="icon arrow-down"></i></div>{{ i + 1 }}</div>
            <div class="col-xl-2 col-lg-1 text-center text-overflow" [ngClass]="{ 'col-xl-2':system.returnName() == 'Склад Пловдив', 'col-xl-1':system.returnName() != 'Склад Пловдив' }">{{ sale.date_of_sale }}</div>
            <div class="col-xl-1 col-lg-1 text-center">{{ sale.order_number }}</div>
            <div class="col-xl-2 col-lg-1 text-center">{{ sale.coming_from }}</div>
            <div class="col-xl-3 col-lg-1 text-center">{{ sale.going_to }} 
                <!-- <span class="sale-hint transfer" *ngIf="sale.sold_to_type == '1'">Прехвърляне</span>
                <span class="sale-hint" *ngIf="sale.sold_to_type != '1'">Продажба</span> -->
            </div>
            <div class="col-xl-1 col-lg-1 text-center" *ngIf="system.returnName() != 'Склад Пловдив'">
                <span *ngIf="sale.invoice == 1">Да</span>
                <span *ngIf="sale.invoice == 0">Не</span>
            </div>
            <div class="col-xl-1 col-lg-1 text-center">{{ sale.product_count }} <i class="icon warning unfinished" *ngIf="sale.product_count == 0" title="Незавършена продажба"></i></div>
            <div class="col-xl-2 col-lg-2 text-center action-no-wrap">
                <div class="action-btn" routerLink="/edit-sale/{{ sale.uid }}"><i class="icon edit"></i><span class="tooltip">Промяна</span></div>
                <div class="action-btn" (click)="prePrintWarranty(sale)" *ngIf="sale.warranty_id != '0' && sale.sold_to_type == '3' && sale.warranty_id != ''"><i class="icon view-warranty"></i> <span class="tooltip">Преглед</span></div>
                <!-- <div class="action-btn positive" (click)="openModal('add-warranty', { 'sale_id':sale.uid, 'name':sale.going_to, address:'', 'email':'', 'phone':'', 'service_id':'0', 'town':'', selectingTown:false, date_purchase:'', date_installation:'' })" *ngIf="sale.warranty_id == '0' && sale.sold_to_type == '3' || sale.warranty_id == '' && sale.sold_to_type == '3'"><i class="icon add-warranty"></i> <span class="tooltip">Гаранция</span></div> -->
                <div class="action-btn positive" routerLink="/add-warranty/{{sale.uid}}" *ngIf="sale.warranty_id == '0' && sale.sold_to_type == '3' && sale.product_count > 0 || sale.warranty_id == '' && sale.sold_to_type == '3'  && sale.product_count > 0"><i class="icon add-warranty"></i> <span class="tooltip">Гаранция</span></div>
                <div class="action-btn negative" (click)="promptDelete(sale.uid, sale.date_of_sale)"><i class="icon remove-white"></i> <span class="tooltip">Изтриване</span></div>
                <div class="action-btn" *ngIf="system.returnIsAdmin() || system.returnRole() == 3 " (click)="exportSale(sale)"><i class="icon sheet"></i><span class="tooltip">Експорт</span></div>
            </div>         
            <div class="expandable" [ngClass]="{ 'expanded':sale.expanded }">
                <div class="table-wrapper-row table-wrapper-small-header ">
                    <div class="col-xl-1 col-lg-1 text-center" style="color: #fbfbfb;">-</div>
                    <!-- <div class="col-xl-3 col-lg-1 text-center">Марка</div> -->
                    <div class="col-xl-3 col-lg-3 text-center">Модел (Вътрешно тяло)</div>
                    <div class="col-xl-3 col-lg-3 text-center">Модел (Външно тяло)</div>
                    <div class="col-xl-3 col-lg-3 text-center">Сериен №(Вътрешно тяло)</div>
                    <div class="col-xl-2 col-lg-2 text-center">Сериен №(Външно тяло)</div>
                </div>
                <div class="table-wrapper-row smaller selectable" *ngFor="let item of sale.items" [ngClass]="{ 'highlight-found':searched && search_type == 'serial_number' && item.serial_internal == search || searched && search_type == 'serial_number' && item.serial_external == search }">
                    <div class="col-xl-1 col-lg-1 text-center line-elem"><div class="elem-holder"></div></div>
                    <!-- <div class="col-xl-3 col-lg-1 text-center">{{ item.brand }}</div> -->
                    <div class="col-xl-3 col-lg-3 text-center">{{ item.model_internal }}</div>
                    <div class="col-xl-3 col-lg-3 text-center">{{ item.model_external }}</div>
                    <div class="col-xl-3 col-lg-3 text-center">{{ item.serial_internal }}
                        <div class="small-action-btn" style="left:0px;" *ngIf="system.returnRole() == 1 && item.serial_internal != '' || system.returnIsAdmin() && item.serial_internal != ''" (click)="openModal('return-product', { 'serial':item.serial_internal, 'sale_id':sale.uid, 'name':sale.going_to, 'uid':item.uid_internal })"><i class="icon return"></i></div>
                        <div class="small-action-btn" style="left:20px;" (click)="CopyText(item.serial_internal)"><i class="icon copy"></i><span class="tooltip">Копиране на сериен номер</span></div>
                    </div>
                    <div class="col-xl-2 col-lg-2 text-center">{{ item.serial_external }}
                        <div class="small-action-btn" *ngIf="system.returnRole() == 1 && item.serial_external != '' || system.returnIsAdmin() && item.serial_external != ''" (click)="openModal('return-product', { 'serial':item.serial_external, 'sale_id':sale.uid, 'name':sale.going_to, 'uid':item.uid_external })"><i class="icon return"></i></div>
                        <div class="small-action-btn" style="right:-7px;" (click)="CopyText(item.serial_external)"><i class="icon copy"></i><span class="tooltip">Копиране на сериен номер</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 2" (click)="switchPage(1)">1</span>
            <span class="non-hover" *ngIf="currentPage > 3">...</span>
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>

            <!-- <span (click)="switchPage(i + 1)" *ngFor="let page of total_pages_array;let i= index" [ngClass]="{ 'active':i + 1 == currentPage }">{{ i + 1 }}</span> -->
        </div>
    </div>
</div>