<div class="content">
    <span class="page-title">
        Нов документ 
        <span (click)="AddDocument()" class="right-btn">Запис</span>
        <span routerLink="/documentation" class="right-btn negative">Отказ</span>
    </span>
    <div class="table-wrapper">
        <div class="category-title">Основна информация</div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Наименование</span>
            <input type="text" placeholder="Въведете наименование" [(ngModel)]="addDocument.name" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row">
            <span class="label">Марка</span>
            <select [(ngModel)]="addDocument.brand" >
                <option [value]="brand.uid" *ngFor="let brand of availableBrands">{{ brand.name }}</option>
            </select>
        </div>
        <div class="row">
            <div class="select-file" (click)="openFileManagement($event)" *ngIf="file.name == ''">
                <i class="icon add-file"></i>
                <span>Изберете файл</span>
            </div>
            <div class="select-file" (click)="openFileManagement($event)" *ngIf="file.name != ''">
                <i class="icon {{returnFileType(file)}}"></i>
                <span>{{ file.name }}</span>
            </div>
        </div>
        <input type="file" id="document-upload" style="display:none;" accept="application/pdf, .doc,.docx,.xls,.xlsx,.png,.jpeg,.jpg" (change)="onFileChange($event)" />
        <!-- <div class="select-file">
          
        </div> -->
    </div>
</div>
