<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>

    <div class="alert" *ngIf="modal.cntx == 'adding-delivery'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Добавяне на доставка...</span>
    </div>


    <div class="warning-alert" *ngIf="modal.cntx == 'duplicate-serial'">
        <div class="illustration duplicate-warning"></div>
        <span class="warning-alert-ttl">Дупликиран артикул</span>
        <span class="warning-alert-description">
            Сериения номер "<strong>{{modal.obj.serial}}</strong>", който се опитвате да въведете вече съществува в тази доставка
        </span>
        <div class="btn-confirm" (click)="closeModal()">Напред</div>
    </div>
</div>


<div class="content no-subheader">
    <span class="page-title">Нова доставка 
        <span (click)="AddDelivery(1)" [ngClass]="{ 'disabled':addDelivery.name.length == 0 }" class="right-btn">Добавяне и изход</span>
        <span (click)="AddDelivery(2)" [ngClass]="{ 'disabled':addDelivery.name.length == 0 }" style="margin-right:10px;" class="right-btn">Добавяне</span>
        <span routerLink="/deliveries" class="right-btn negative">Отказ</span>
    </span>
    <div class="tab-holder">
        <div class="tab-headers">
            <div class="tab-header" (click)="switchTab('general')" [ngClass]="{ 'active':tab == 'general' }">Основна информация</div>
            <div class="tab-header" (click)="switchTab('products')"  [ngClass]="{ 'active':tab == 'products' }">Артикули <span class="count-badge">{{ returnTotalProducts() }}</span></div>
        </div>
        <div class="tab-content">
            <div class="tab" *ngIf="tab == 'general'">
                <div class="category-title">Основна информация</div>
                <div class="col-xl-6 col-lg-6 input-row">
                    <span class="label">Наименование <span style="color:red;" alt="Трябва да бъде въведено име">*</span></span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="addDelivery.name" />
                </div>
                <div class="col-xl-2 col-lg-2 input-row">
                    <span class="label">Номер на контейнер</span>
                    <input type="text" placeholder="Въведете номер"  [(ngModel)]="addDelivery.uid" />
                </div>
                <div class="col-xl-2 col-lg-2 input-row">
                    <span class="label">Номер на документ</span>
                    <input type="text" placeholder="Въведете номер на документ"  [(ngModel)]="addDelivery.document_number" />
                </div>
                <div class="col-xl-2 col-lg-2 input-row">
                    <div id="delivery-date-picker" class="date-picker bot"  (clickOutside)="closePicker($event)" [ngClass]="{ 'active':delivery_date_picker }">
                        <div class="datepicker-header">
                            <div class="datepicker-left-tool hoverable" (click)="prevMonth()"><i class='icon arrow-left'></i></div>
                            <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_delivery_date)) }} {{ returnYear(current_delivery_date) }}</span>
                            <div class="datepicker-right-tool hoverable" (click)="nextMonth()"><i class='icon arrow-right'></i></div>
                        </div>
                        <div class="datepicker-content">
                            <div class="datepicker-title-row">
                                <span>Пн</span>
                                <span>Вт</span>
                                <span>Ср</span>
                                <span>Чт</span>
                                <span>Пт</span>
                                <span>Сб</span>
                                <span>Нд</span>
                            </div>
                            <div class="datepicker-row" id="date_delivery_body">
                                <span *ngFor="let dt of current_delivery_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_delivery_date) && dt.month == returnMonth(addDelivery.date_add) - 1 }" (click)="selectDate(dt)">{{ dt.date }}</span>
                            </div>
                        </div>
                    </div>
                    <span class="label">Дата на доставка</span>
                    <input type="text" placeholder="Въведете дата на доствака" readonly value="{{ this.returnDateFormated(addDelivery.date_add) }}" />
                    <div class="after-action" (click)="openPicker()"><i class="icon calendar"></i></div>
                </div>
                <div class="col-xl-12 col-lg-12 input-row">
                    <span class="label">Коментар</span>
                    <textarea  [(ngModel)]="addDelivery.comment"></textarea>
                </div>
            </div>
            <div class="tab" *ngIf="tab == 'products'">
                <div class="category-title" style="margin-bottom:0px;">Артикули 
                    <span class="small-hdr-btn" (click)="promptDuplicate()" [ngClass]="{ 'disabled':!returnAnySelected() }">Дубликиране</span>
                    <span class="small-hdr-btn" style="margin-right:10px;" (click)="createSplitter()" *ngIf="addDelivery.items.length > 0">Създаване на разделител</span>
                    <span class="small-hdr-btn" style="margin-right:10px;" (click)="clearFields()" *ngIf="holdStartIdx != holdEndIdx && holdEndIdx != 0 ">Нулиране на избрани колони</span>
                    <span class="small-hdr-btn" style="margin-right:10px;" (click)="deleteRows()" *ngIf="holdStartIdx != holdEndIdx && holdEndIdx != 0 ">Изтриване на избрани редове</span>
                </div>
                <div class="header-row">
                    <div class="col-xl-3 input-row" style="margin-left:66px;"><span class="hint">Марка</span></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Модел (Вътрешно тяло)</span> <i class="icon icon-before-label indoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Модел (Външно тяло)</span> <i class="icon icon-before-label outdoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Сериен номер (Вътрешно тяло)</span> <i class="icon icon-before-label indoor-unit"></i></div>
                    <div class="col-xl-2 input-row"><span class="hint" style="margin-left:50px;">Сериен номер (Външно тяло)</span> <i class="icon icon-before-label outdoor-unit"></i></div>
                </div>
                <virtual-scroller class="inner-table product-catalog" id="product-table" #scroll [items]="addDelivery.items">
                    <div class="inner-row" [ngClass]="{ 'splitter':item.splitter }" *ngFor="let item of scroll.viewPortItems;let i = index;trackBy: trackByFn">
                        <label class='container in-row' *ngIf="!item.splitter" id='container-row0' [ngClass]="{ 'disabled':returnAnySelected() && !item.selected }"><input type='checkbox' id='selected{{i}}' [(ngModel)]="item.selected" /> <span class='checkmark' ></span> </label>
                        <div class="col-xl-3 input-row small" *ngIf="!item.splitter" [ngClass]="{ 'disabled':holdStarted && holdField != 'model_external' }"> 
                            <select [(ngModel)]="item.brand" (change)="checkForNewRow(item.uuid, '')"><option value="{{brand.name}}" *ngFor="let brand of availableBrands">{{ brand.name }}</option></select>
                        </div>
                        <div class="col-xl-2 input-row small" *ngIf="!item.splitter" [ngClass]="{ 'disabled':holdStarted && holdField != 'model_internal' }">
                            <span style="float: left; width: 35px; margin-left: 20px; height: 30px; line-height: 30px; font-size: 16px;">{{ item.num }}</span>
                            <input type="text" [ngClass]="{ 'highlight':i >= holdStartIdx && i <= holdEndIdx && holdEndIdx != 0 && holdField == 'model_internal'  }" style="width: calc(100% - 71px); margin-left: 0px;" [(ngModel)]="item.model_internal" (keyup)="checkForNewRow(item.uuid, 'model_internal')" (click)="selectModel(i, 'model_internal', item)" (focusout)="deselectItemInternal(i)" placeholder="Модел (Вътрешно тяло)" (keyup.shift)="releaseHold($event, i, 'model_internal')" (keydown.shift)="startHold($event, i, 'model_internal')" />
                            <div class="dropdown-list smaller" *ngIf="item.selectingModelInternal && item.model_internal.length > 1">
                                <span *ngFor="let model of modelInternalSuggestion" (click)="selectInternalModel(i, model)">{{ model.name }}</span>
                            </div>
                        </div>
                        <div class="col-xl-2 input-row small" *ngIf="!item.splitter" [ngClass]="{ 'disabled':holdStarted && holdField != 'model_external' }"> 
                            <input type="text" [ngClass]="{ 'highlight':i >= holdStartIdx && i <= holdEndIdx && holdEndIdx != 0 && holdField == 'model_external'  }" [(ngModel)]="item.model_external" (keyup)="checkForNewRow(item.uuid, 'model_external')" (click)="selectModel(i, 'model_external', item)" (focusout)="deselectItemExternal(i)" placeholder="Модел (Външно тяло)" (keyup.shift)="releaseHold($event, i, 'model_external')" (keydown.shift)="startHold($event, i, 'model_external')" /> 
                            <div class="dropdown-list smaller" *ngIf="item.selectingModelExternal && item.model_external.length > 1">
                                <span *ngFor="let model of modelExternalSuggestion" (click)="selectExternalModel(i, model)">{{ model.name }}</span>
                            </div>
                        </div>
                        <div class="col-xl-2 input-row small" *ngIf="!item.splitter" [ngClass]="{ 'disabled':holdStarted && holdField != 'serial_internal' }"> <input type="text" [ngClass]="{ 'highlight':i >= holdStartIdx && i <= holdEndIdx && holdEndIdx != 0 && holdField == 'serial_internal'  }" (click)="checkMarking(i, 'serial_internal')" id="serial_internal{{item.num}}" [(ngModel)]="item.serial_internal" (keyup)="checkForNewRow(item.uuid,'')" placeholder="Сериен № (Вътрешно тяло)" (keyup.enter)="onKey($event, 'serial_internal', item.num)" (keyup.Tab)="onKey($event, 'serial_internal', item.num)" (keyup.shift)="releaseHold($event, i, 'serial_internal')" (keydown.shift)="startHold($event, i, 'serial_internal')"> </div>
                        <div class="col-xl-2 input-row small" *ngIf="!item.splitter" [ngClass]="{ 'disabled':holdStarted && holdField != 'serial_external' }"> <input type="text" [ngClass]="{ 'highlight':i >= holdStartIdx && i <= holdEndIdx && holdEndIdx != 0 && holdField == 'serial_external'  }" (click)="checkMarking(i, 'serial_external')" id="serial_external{{item.num}}"[(ngModel)]="item.serial_external" (keyup)="checkForNewRow(item.uuid, '')" placeholder="Сериен № (Външно тяло)" (keyup.enter)="onKey($event, 'serial_external', item.num)" (keyup.Tab)="onKey($event, 'serial_external', item.num)" (keyup.shift)="releaseHold($event, i, 'serial_external')" (keydown.shift)="startHold($event, i, 'serial_external')"> </div>
                        <div style='float:left;' *ngIf="!item.splitter"> <div class='action-remove' *ngIf="addDelivery.items.length > 1" (click)="removeItem(i, item.uuid)"><i class='icon cross'></i></div> </div>
                        
                        <span class="split-label" *ngIf="item.splitter">
                            <div style='float:right;margin-right: 75px;'> <div class='action-remove' *ngIf="addDelivery.items.length > 1" (click)="removeItem(i, item.uuid)"><i class='icon cross'></i></div> </div>
                        </span>
                    </div>
                </virtual-scroller>
            </div>
        </div>
    </div>

</div>
