import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalSystemService } from '../local-system.service';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss']
})
export class SearchProductComponent implements OnInit {
  searching:any = false;
  searchable:any = "";
  searched:any = false;

  currentPage:any = 1;
  events:any = [];

  constructor(private http:HttpClient, private system:LocalSystemService, private router:Router) {
    if(this.system.returnRole() > 1 && this.system.returnRole() < 3 && !this.system.returnIsAdmin()) { this.router.navigate(['/warranties']); }
  }

  ngOnInit(): void {
  }
  
  Search() {
    this.events = [];
    this.currentPage = 1;
    this.searching = true;
    this.http.post("api/service.php?action=get_product_events&page=" + this.currentPage, { 'serial':this.searchable }).subscribe((data) => {
      this.events = data['events'];
      this.searching = false;
      this.searched = true;
    });
  }

  CheckSerial() {
    this.searchable = this.returnTranslate(this.searchable);
  }

  returnDateFormat(dtime) {
    let dt = new Date(dtime);
    return this.returnPadZero(dt.getDate(), 2) + "." + this.returnPadZero(dt.getMonth() + 1, 2) + "." + dt.getFullYear() + " / " + this.returnPadZero(dt.getHours(), 2) + ":" + this.returnPadZero(dt.getMinutes(), 2) + ":" + this.returnPadZero(dt.getSeconds(), 2);
  }

  returnPadZero(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }

  returnTranslate = function(text) {
    text = text
        .replace(/\u040D/g, 'X')
        .replace(/\u0401/g, 'YO')
        .replace(/\u0419/g, 'J')
        .replace(/\u0426/g, 'C')
        .replace(/\u0423/g, 'U')
        .replace(/\u041A/g, 'K')
        .replace(/\u0415/g, 'E')
        .replace(/\u041D/g, 'N')
        .replace(/\u0413/g, 'G')
        .replace(/\u0428/g, 'W')
        .replace(/\u0429/g, 'SCH')
        .replace(/\u0417/g, 'Z')
        .replace(/\u0425/g, 'H')
        .replace(/\u042A/g, '')
        .replace(/\u0451/g, 'yo')
        .replace(/\u0439/g, 'j')
        .replace(/\u0446/g, 'c')
        .replace(/\u0443/g, 'u')
        .replace(/\u043A/g, 'k')
        .replace(/\u0435/g, 'e')
        .replace(/\u043D/g, 'n')
        .replace(/\u0433/g, 'g')
        .replace(/\u0448/g, 'w')
        .replace(/\u0449/g, 'sch')
        .replace(/\u0437/g, 'z')
        .replace(/\u0445/g, 'h')
        .replace(/\u044A/g, "X")
        .replace(/\u0424/g, 'F')
        .replace(/\u042B/g, 'I')
        .replace(/\u0412/g, 'W')
        .replace(/\u0410/g, 'a')
        .replace(/\u041F/g, 'P')
        .replace(/\u0420/g, 'R')
        .replace(/\u041E/g, 'O')
        .replace(/\u041B/g, 'L')
        .replace(/\u0414/g, 'D')
        .replace(/\u0416/g, 'V')
        .replace(/\u042D/g, 'E')
        .replace(/\u0444/g, 'f')
        .replace(/\u044B/g, 'i')
        .replace(/\u0432/g, 'w')
        .replace(/\u0430/g, 'a')
        .replace(/\u043F/g, 'p')
        .replace(/\u0440/g, 'r')
        .replace(/\u043E/g, 'o')
        .replace(/\u043B/g, 'l')
        .replace(/\u0434/g, 'd')
        .replace(/\u0436/g, 'v')
        .replace(/\u044D/g, 'e')
        .replace(/\u042F/g, 'Q')
        .replace(/\u0427/g, 'CH')
        .replace(/\u0421/g, 'S')
        .replace(/\u041C/g, 'M')
        .replace(/\u0418/g, 'I')
        .replace(/\u0422/g, 'T')
        .replace(/\u042C/g, "x")
        .replace(/\u0411/g, 'B')
        .replace(/\u042E/g, 'YU')
        .replace(/\u044F/g, 'q')
        .replace(/\u0447/g, 'ch')
        .replace(/\u0441/g, 's')
        .replace(/\u043C/g, 'm')
        .replace(/\u0438/g, 'i')
        .replace(/\u0442/g, 't')
        .replace(/\u044C/g, "x")
        .replace(/\u0431/g, 'b')
        .replace(/\u044E/g, 'yu');

    return text;
  };

}
