<div class="content no-subheader">
    <span class="page-title">
        <span class="name">Помощ</span>
        <span *ngIf="!adding && system.returnIsAdmin() && !editing" (click)="adding = true" class="right-btn">Добавяне</span>
    </span>

    <div class="help-card" [ngClass]="{ 'expand':help.expanded, 'hide':editHelp.uid == help.uid || system.returnRole() == 1 && help.seen_storehouse == 0 || system.returnRole() == 2 && help.seen_dealer == 0 }" *ngFor="let help of helps">
        <span class="ttl" (click)="help.expanded = !help.expanded">
            <span style="font-weight:600;">{{ help.ttl }}</span>
            <i [ngClass]="{ 'reverse':help.expanded }" class="icon expand expand-icon"></i>
            <i class="icon remove opacity-hover" *ngIf="system.returnIsAdmin()" style="margin-right:0px;" (click)="RemoveHelp(help.uid)"></i>
            <i class="icon edit-blue opacity-hover" *ngIf="system.returnIsAdmin()" style="margin-right:0px;" (click)="loadEditHelp(help)"></i>
        </span>
        <div class="cnt" [innerHTML]="help.layout"></div>
    </div>

    <div class="add-card" *ngIf="adding">
        <div class="add-card-title">
            <input type="text" [(ngModel)]="addHelp.ttl" placeholder="Въведете наименование" />
        </div>
        <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="addHelp.layout" [config]="editorConfig"></angular-editor>
        <div class="checkbox-area">
            <div class="check" (click)="switchSeenDealer(addHelp)" [ngClass]="{ 'checked':addHelp.seen_dealer == 1 }"></div>
            <span>За дилъри</span>
        </div>
        <div class="checkbox-area">
            <div class="check" (click)="switchSeenStorehouse(addHelp)" [ngClass]="{ 'checked':addHelp.seen_storehouse == 1 }"></div>
            <span>За складове</span>
        </div>
        <div class="btn" (click)="AddHelp()">Добавяне</div>
        <div class="btn back" (click)="cancelAddHelp()">Отказ</div>
    </div>

    <div class="add-card" *ngIf="editing">
        <div class="add-card-title">
            <input type="text" [(ngModel)]="editHelp.ttl" placeholder="Въведете наименование" />
        </div>
        <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="editHelp.layout" [config]="editorConfig"></angular-editor>

        <div class="checkbox-area">
            <div class="check" (click)="switchSeenDealer(editHelp)" [ngClass]="{ 'checked':editHelp.seen_dealer == 1 }"></div>
            <span>За дилъри</span>
        </div>
        <div class="checkbox-area">
            <div class="check" (click)="switchSeenStorehouse(editHelp)" [ngClass]="{ 'checked':editHelp.seen_storehouse == 1 }"></div>
            <span>За складове</span>
        </div>
        <div class="btn" (click)="EditHelp()">Запис</div>
        <div class="btn back" (click)="cancelEditHelp()">Отказ</div>
    </div>
</div>
